import React from 'react'
import './MainLayout.scss'
import { FiChevronDown } from 'react-icons/fi'
import { RxHamburgerMenu } from 'react-icons/rx'
import { useGlobalContext } from '../../Context/Context'

export const MainLayout = ({ children }: any) => {
  const { divRef, sideBar, setSideBar } = useGlobalContext()
  return (
    <div
      className='mainLayout'
      ref={divRef}
      onClick={() => {
        if (!sideBar) {
          setSideBar(!sideBar)
        }
      }}
    >
      <div className='cover'>
        <div className='bodyx'>{children}</div>
      </div>
    </div>
  )
}
