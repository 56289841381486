import { FaMinus, FaPlus } from 'react-icons/fa'

function Directors({ directoss, setDirectoss, emptyFields, disabled }: any) {
  const handleAddressChange = (event: any, index: any) => {
    let data: any = [...directoss]
    data[index][event.target.name] = event.target.value
    setDirectoss(data)
  }
  const addAddressFields = () => {
    let object = {
      fullName: '',
      NIN: '',
    }
    setDirectoss([...directoss, object])
  }
  const removeAddressFields = (index: any) => {
    let data = [...directoss]
    data.splice(index, 1)
    setDirectoss(data)
  }
  return (
    <div className='form-body'>
      <div className='tag'>
        <p>Directors Info</p>
      </div>
      {directoss.map((form: any, index: any) => (
        <div className='seti' key={index + 1}>
          <div className='card'>
            <p>Director's Fullname</p>
            <input
              type='text'
              id=''
              name='fullName'
              disabled={disabled}
              onChange={(event) => handleAddressChange(event, index)}
              defaultValue={form.fullName}
              className={
                emptyFields.includes(
                  'Fill at least one Director Information completely'
                ) && directoss[index].fullName === ''
                  ? 'active'
                  : ''
              }
            />
          </div>
          <div className='card'>
            <p>Director's NIN</p>
            <input
              type='tel'
              id=''
              name='NIN'
              disabled={disabled}
              onChange={(event) => handleAddressChange(event, index)}
              defaultValue={form.NIN}
              className={
                emptyFields.includes(
                  'Fill at least one Director Information completely'
                ) && directoss[index].NIN === ''
                  ? 'active'
                  : ''
              }
            />
            {!disabled && (
              <div className='anothes'>
                <div className='remove'>
                  <FaMinus onClick={() => removeAddressFields(index)} />
                  <p onClick={() => removeAddressFields(index)}>Remove</p>
                </div>
                <div className='addx'>
                  <FaPlus onClick={addAddressFields} />
                  <p onClick={addAddressFields}>Add another</p>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
      {directoss.length === 0 && (
        <>
          {!disabled && (
            <div className='seti'>
              <div className='card' style={{ width: '100%' }}>
                <div className='anothes'>
                  <div className='remove'></div>
                  <div className='addx'>
                    <FaPlus onClick={addAddressFields} />
                    <p onClick={addAddressFields}>Add another</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default Directors
