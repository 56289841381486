import React, { useState, useEffect } from 'react'
import { FaTimes } from 'react-icons/fa'
import Loader from '../../../../../Assests/loader.gif'
import axios from 'axios'
import { useGlobalContext } from '../../../../../Context/Context'
import { axiosCalls } from '../../../../../Api/_axios'

function AddMembers({ setShowAddMembers, notify, showAddMembers }: any) {
  const authToken = sessionStorage.getItem('accessToken')
  const { businessData } = useGlobalContext()
  const [isLoading, setIsLoading] = useState(false)
  const [FirstName, setFirstName] = useState<any>('')
  const [LastName, setLastName] = useState<any>('')
  const [Email, setEmail] = useState<any>('')
  const [Department, setDepartment] = useState<any>('')
  const [Role, setRole] = useState<any>('')
  const [loading, setLoading] = useState(true)
  const [roleLoading, setRoleLoading] = useState(true)
  const [departmemtData, setDepartmemtData] = useState<any>([])
  const [rolesData, setRolesData] = useState<any>([])
  const [Disabled, setDisabled] = useState(false)

  const handleSubmit = (e: any) => {
    e.preventDefault()
    setIsLoading(true)
    const data = {
      email: Email.toLocaleLowerCase(),
      firstName: FirstName,
      lastName: LastName,
      department: Department,
      role: Role,
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/business/${businessData.businessID._id}/member/add`,
        data,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((response) => {
        notify('success', response.data.message)
        setIsLoading(false)
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      })
      .catch((error) => {
        notify('error', error.response.data.errors.join(','))
        setIsLoading(false)
      })
  }
  const handleEdit = (e: any) => {
    e.preventDefault()
    setIsLoading(true)
    const data = {
      department: Department,
      role: Role,
    }

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/business/${businessData.businessID._id}/member/${showAddMembers._id}/update`,
        data,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((response) => {
        notify('success', response.data.message)
        setIsLoading(false)
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      })
      .catch((error) => {
        notify('error', error.response.data.errors.join(','))
        setIsLoading(false)
      })
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axiosCalls(
          `department/${businessData.businessID._id}/fetch?limit=10`,
          'GET',
          null,
          authToken
        )
        setLoading(false)
        setDepartmemtData(result.data.docs)
      } catch (error: any) {
        notify('error', error.message)
      }
    }
    fetchData()
  }, [])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axiosCalls(
          `role/${businessData.businessID._id}/fetch?limit=10`,
          'GET',
          null,
          authToken
        )
        setRoleLoading(false)
        setRolesData(result.data.docs)
      } catch (error: any) {
        notify('error', error.message)
      }
    }
    fetchData()
  }, [])
  useEffect(() => {
    if (showAddMembers !== true) {
      setFirstName(showAddMembers.firstName)
      setLastName(showAddMembers.lastName)
      setEmail(showAddMembers.email)
      setRole(showAddMembers.role)
      setDisabled(true)
      if (showAddMembers.department) {
        setDepartment(showAddMembers.department._id)
      }
    }
  }, [])

  return (
    <div className='modal-body'>
      <div className='modal'>
        <div className='close'>
          <FaTimes onClick={() => setShowAddMembers(false)} />
        </div>
        <div className='tag'>
          <p>
            {showAddMembers !== true
              ? 'Edit Collaborators'
              : 'Add Collaborators'}
          </p>
        </div>
        <div className='form'>
          <form onSubmit={showAddMembers !== true ? handleEdit : handleSubmit}>
            <div className='card'>
              <p>First Name</p>
              <input
                type='text'
                required
                value={FirstName}
                onChange={(e) => setFirstName(e.target.value)}
                disabled={Disabled}
              />
            </div>
            <div className='card'>
              <p>Last Name</p>
              <input
                type='text'
                required
                value={LastName}
                onChange={(e) => setLastName(e.target.value)}
                disabled={Disabled}
              />
            </div>
            <div className='card'>
              <p>Email Adress</p>
              <input
                type='email'
                required
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={Disabled}
              />
            </div>
            <div className='card'>
              <p>Department</p>
              <select
                name=''
                value={Department}
                onChange={(e) => setDepartment(e.target.value)}
              >
                <option value=''>Select</option>
                {departmemtData.map((item: any) => (
                  <option value={item._id} key={item._id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='card'>
              <p>Role</p>
              <select
                name=''
                value={Role}
                onChange={(e) => setRole(e.target.value)}
              >
                <option value=''>Select</option>
                {rolesData.map((item: any) => (
                  <option value={item.name} key={item._id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>

            {isLoading ? (
              <div className='buttons'>
                <img src={Loader} alt='Loader' />
              </div>
            ) : (
              <div className='buttons'>
                <button type='submit'>
                  {showAddMembers !== true ? 'Edit Member' : 'Invite Member'}
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddMembers
