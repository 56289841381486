import React, { useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import Loader from '../../../../../Assests/loader2.gif'
import axios from 'axios'
import { useGlobalContext } from '../../../../../Context/Context'
import Button from '../../../../../Components/Elements/Button/Button'

function DeactivateUser({ deactivateUser, setDeactivateUser, notify }: any) {
  const [requestLoading, setRequestLoading] = useState(false)
  const { businessData } = useGlobalContext()
  const authToken = sessionStorage.getItem('accessToken')

  const userDeactivation = () => {
    setRequestLoading(true)

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/business/${businessData.businessID._id}/member/${deactivateUser._id}/${deactivateUser.action}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((response) => {
        notify('success', response.data.message)
        setRequestLoading(false)
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      })
      .catch((error) => {
        notify('error', error.response.data.errors.join(','))
        setRequestLoading(false)
      })
  }
  return (
    <div className='modal-body'>
      <div className='modal'>
        <div className='close'>
          <FaTimes onClick={() => setDeactivateUser(null)} />
        </div>
        <h1>
          Are you sure you want to {''}
          {deactivateUser.action} {deactivateUser.firstName}?
        </h1>
        {requestLoading ? (
          <div className='flex'>
            <img src={Loader} />
          </div>
        ) : (
          <div className='flex'>
            <Button
              content='Cancel'
              outlined
              onClick={() => setDeactivateUser(null)}
            />
            <Button
              content={`${deactivateUser.action} user`}
              danger={deactivateUser.action === 'deactivate'}
              onClick={() => userDeactivation()}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default DeactivateUser
