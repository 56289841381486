import { useState, useEffect, useRef } from 'react'
import { FaBars, FaCheck, FaEdit, FaTimes } from 'react-icons/fa'
import { MdOutlineDelete } from 'react-icons/md'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { TbEdit } from 'react-icons/tb'
import { axiosCalls } from '../../../../../Api/_axios'
import Loader from '../../../../../Assests/loader.gif'
import CheckPermissions from '../../../../../Components/Unauthorized/CheckPermissions'
import Pagination from '../../../../../Components/Pagination/Pagination'

function Department({
  setShowSidebar,
  showSidebar,
  notify,
  businessData,
  setShowAddMembers,
  setshowRoles,
  setDeactivateUser,
}: any) {
  const token = sessionStorage.getItem('accessToken')
  const [loading, setLoading] = useState(true)
  const [roleLoading, setRoleLoading] = useState(true)
  const [memberLoading, setMemberLoading] = useState(true)
  const [departmemtData, setDepartmemtData] = useState<any>({ totalDocs: 0 })
  const [rolesData, setRolesData] = useState<any>({ totalDocs: 0 })
  const [membersData, setMembersData] = useState<any>({
    totalDocs: 0,
    docs: [],
  })
  const [editDepartment, setEditDepartment] = useState<any>(null)
  const [editDeptName, setEditDeptName] = useState('')
  const [ShowNewDepartment, setShowNewDepartment] = useState(false)
  const [newDepartment, setNewDepartment] = useState('')
  const [newDepartmentLoading, setNewDepartmentLoading] = useState(false)
  const [dropdownVisibility, setDropdownVisibility] = useState<any>({})
  const dropdownRefs = useRef<any>({})

  const handleEditDepartment = async () => {
    const data = {
      name: editDeptName,
      id: editDepartment,
    }
    try {
      const result = await axiosCalls(
        `department/${businessData.businessID._id}/update`,
        'PUT',
        data,
        token
      )
      if (result.success) {
        notify('success', result.message)
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      } else {
        notify('error', result.message)
      }
    } catch (error) {
      setNewDepartmentLoading(false)
    }
  }
  const handleCreatetDepartment = async () => {
    setNewDepartmentLoading(true)
    const data = {
      name: newDepartment,
    }
    try {
      const result = await axiosCalls(
        `department/${businessData.businessID._id}/create`,
        'POST',
        data,
        token
      )
      setNewDepartmentLoading(false)
      if (result.success) {
        notify('success', result.message)
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      } else {
        notify('error', result.message)
      }
    } catch (error) {
      setNewDepartmentLoading(false)
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  const handleDropdownToggle = (divId: any) => {
    setDropdownVisibility((prevState: any) => ({
      ...prevState,
      [divId]: !prevState[divId],
    }))
  }
  const handleClickOutside = (event: any) => {
    // Object.keys(dropdownRefs.current).forEach((divId) => {
    //   if (
    //     dropdownRefs.current[divId] &&
    //     !dropdownRefs.current[divId].contains(event.target)
    //   ) {
    //     setDropdownVisibility((prevState: any) => ({
    //       ...prevState,
    //       [divId]: false,
    //     }))
    //   }
    // })
  }

  // This is for the Dept
  const fetchDept = async (page: number) => {
    try {
      const result = await axiosCalls(
        `department/${businessData.businessID._id}/fetch?limit=10&page=${page}`,
        'GET',
        null,
        token
      )
      setLoading(false)
      setDepartmemtData(result.data)
    } catch (error: any) {
      notify('error', error.message)
    }
  }
  useEffect(() => {
    fetchDept(1)
  }, [])
  const [deptsCurrentPage, setDeptsCurrentPage] = useState(1)
  const deptsPaginationClick = (num: any) => {
    setLoading(true)
    fetchDept(num)
  }

  //This is for the roles
  const fetchRoles = async (page: number) => {
    try {
      const result = await axiosCalls(
        `role/${businessData.businessID._id}/fetch?limit=10&page=${page}`,
        'GET',
        null,
        token
      )
      setRoleLoading(false)
      setRolesData(result.data)
    } catch (error: any) {
      notify('error', error.message)
    }
  }
  useEffect(() => {
    fetchRoles(1)
  }, [])
  const [rolesCurrentPage, setRolesCurrentPage] = useState(1)
  const rolesPaginationClick = (num: any) => {
    setRoleLoading(true)
    fetchRoles(num)
  }

  //This is for the Business Members
  const fetchMembers = async (page: number) => {
    try {
      const result = await axiosCalls(
        `business/${businessData.businessID._id}/member/fetch?limit=10&page=${page}`,
        'GET',
        null,
        token
      )
      setMemberLoading(false)
      if (result.data) {
        setMembersData(result.data)
      } else {
        setMembersData({ totalDocs: 0, docs: [] })
      }
    } catch (error: any) {
      notify('error', error.message)
    }
  }
  useEffect(() => {
    fetchMembers(1)
  }, [])
  const [memberCurrentPage, setMemberCurrentPage] = useState(1)
  const memberPaginationClick = (num: any) => {
    setMemberLoading(true)
    fetchMembers(num)
  }

  return (
    <div className='department'>
      <CheckPermissions
        requiredPermissions={[
          'create_business_department',
          'update_business_department',
        ]}
      >
        <div className='comp-one'>
          <div className='rowss'>
            <FaBars onClick={() => setShowSidebar(!showSidebar)} />
          </div>
          <div className='depart-top'>
            <div className='one'>
              <h2>Departments</h2>
            </div>
            <div className='two'>
              <p>Total :</p>
              <div className='carde'>
                <h5>{departmemtData.totalDocs}</h5>
              </div>
            </div>
          </div>
          <div className='depts-list'>
            {loading ? (
              <div className='loader'>
                <img src={Loader} alt='' />
              </div>
            ) : (
              <>
                {departmemtData.docs.map((item: any) => (
                  <div className='row' key={item._id}>
                    <div className='one'>
                      <input
                        type='text'
                        defaultValue={item.name}
                        name=''
                        id=''
                        disabled={editDepartment !== item._id}
                        onChange={(e) => setEditDeptName(e.target.value)}
                      />
                    </div>
                    <div className='two'>
                      {editDepartment === item._id ? (
                        <>
                          <FaCheck onClick={handleEditDepartment} />
                          <FaTimes
                            onClick={() => {
                              setEditDepartment(null)
                              setEditDeptName('')
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <TbEdit
                            onClick={() => {
                              setEditDepartment(item._id)
                              setEditDeptName(item.name)
                            }}
                          />
                          {/* <MdOutlineDelete /> */}
                        </>
                      )}
                    </div>
                  </div>
                ))}
                <Pagination
                  currentPage={deptsCurrentPage}
                  totalCount={departmemtData.totalPages}
                  pageSize={10}
                  onPageChange={(page: any) => {
                    setDeptsCurrentPage(page)
                    deptsPaginationClick(page)
                  }}
                />
              </>
            )}
            <div className='new' style={{ marginTop: '20px' }}>
              {ShowNewDepartment && (
                <div className='row'>
                  <div className='one'>
                    <input
                      type='text'
                      name=''
                      id=''
                      value={newDepartment}
                      onChange={(e) => setNewDepartment(e.target.value)}
                    />
                  </div>
                  <div className='two'>
                    {newDepartmentLoading ? (
                      <img src={Loader} alt='' />
                    ) : (
                      <button onClick={handleCreatetDepartment}>Create</button>
                    )}
                  </div>
                </div>
              )}
              <div className='add'>
                <button
                  onClick={() => setShowNewDepartment(!ShowNewDepartment)}
                >
                  Add New Department
                </button>
              </div>
            </div>
          </div>
        </div>
      </CheckPermissions>
      <CheckPermissions
        requiredPermissions={['create_business_role', 'update_business_role']}
      >
        <div className='comp-one'>
          <div className='depart-top'>
            <div className='one'>
              <h2>Roles</h2>
            </div>
            <div className='two'>
              <p>Total :</p>
              <div className='carde'>
                <h5>{rolesData.totalDocs}</h5>
              </div>
            </div>
          </div>
          {roleLoading ? (
            <div className='loader'>
              <img src={Loader} alt='' />
            </div>
          ) : (
            <>
              <div className='tablex'>
                <table>
                  <thead>
                    <tr>
                      <th>Role Name</th>
                      <th>Permissions</th>
                      <th className='mini'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {rolesData.docs.map((item: any, i: number) => (
                      <tr key={i + 1}>
                        <td>{item.name}</td>
                        <td>
                          {item.permissions
                            .map((permission: string) =>
                              permission.replace(/_/g, ' ')
                            )
                            .join(', ')}{' '}
                        </td>
                        <td className='mini'>
                          {!item.permissions.includes('*') && (
                            <FaEdit onClick={() => setshowRoles(item)} />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className='mobile'>
                {rolesData.docs.map((item: any, i: number) => (
                  <div className='nodyx' key={item._id}>
                    <div className='row'>
                      <div className='left'>
                        <h2>S/N</h2>
                      </div>
                      <div className='right'>
                        <p>{1 + i}</p>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='left'>
                        <h2>Role Name</h2>
                      </div>
                      <div className='right'>
                        <div className='ctd'>
                          <p>{item.name}</p>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='left'>
                        <h2>Permissions</h2>
                      </div>
                      <div className='right'>
                        <p>
                          {item.permissions
                            .map((permission: string) =>
                              permission.replace(/_/g, ' ')
                            )
                            .join(', ')}
                        </p>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='left'>
                        <h2></h2>
                      </div>
                      <div className='right'>
                        {!item.permissions.includes('*') && (
                          <FaEdit onClick={() => setshowRoles(item)} />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <Pagination
                currentPage={rolesCurrentPage}
                totalCount={rolesData.totalPages}
                pageSize={10}
                onPageChange={(page: any) => {
                  setRolesCurrentPage(page)
                  rolesPaginationClick(page)
                }}
              />
            </>
          )}
          <div className='add-new'>
            <button onClick={() => setshowRoles(true)}>Add new Role</button>
          </div>
        </div>
      </CheckPermissions>
      <CheckPermissions
        requiredPermissions={['view_business_member', 'update_business_member']}
      >
        <div className='comp-one'>
          <div className='depart-top'>
            <div className='one'>
              <h2>Add Employees</h2>
            </div>
            <div className='two'>
              <p>Total :</p>
              <div className='carde'>
                <h5>{membersData.totalDocs}</h5>
              </div>
            </div>
          </div>
          {memberLoading ? (
            <div className='loader'>
              <img src={Loader} alt='' />
            </div>
          ) : (
            <>
              <div className='tablex'>
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Roles </th>
                      <th>Department</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {membersData.docs.map((item: any) => (
                      <tr
                        key={item._id}
                        className={item.blocked ? 'blocked' : ''}
                      >
                        <td>
                          {item.firstName} {item.lastName}
                        </td>
                        <td>{item.email}</td>
                        <td>{item.role}</td>
                        <td>
                          <p>
                            {item.department ? item.department.name : 'All'}
                          </p>
                        </td>
                        <td>
                          <p>{item.blocked ? 'Deactivated' : 'Active'}</p>
                        </td>
                        <td
                          ref={(ref) => (dropdownRefs.current[item._id] = ref)}
                        >
                          <div className='dropd'>
                            {item.role !== 'superadmin' && (
                              <BsThreeDotsVertical
                                onClick={() => handleDropdownToggle(item._id)}
                              />
                            )}
                          </div>
                          {dropdownVisibility[item._id] && (
                            <div className='drop'>
                              <ul>
                                <li onClick={() => setShowAddMembers(item)}>
                                  Edit
                                </li>
                                <li
                                  onClick={() => {
                                    if (item.blocked) {
                                      setDeactivateUser({
                                        action: 'activate',
                                        ...item,
                                      })
                                    } else {
                                      setDeactivateUser({
                                        action: 'deactivate',
                                        ...item,
                                      })
                                    }
                                  }}
                                >
                                  {item.blocked
                                    ? 'Activate user'
                                    : 'Deactivate user'}
                                </li>
                              </ul>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className='mobile'>
                {membersData.docs.map((item: any) => (
                  <div
                    className='nodyx'
                    // onClick={() => selectProduct(i + 1)}
                  >
                    <div className='row'>
                      <div className='left'></div>
                      <div
                        className='right'
                        ref={(ref) => (dropdownRefs.current[item._id] = ref)}
                      >
                        <div className='dropd'>
                          {item.role !== 'superadmin' && (
                            <BsThreeDotsVertical
                              onClick={() => handleDropdownToggle(item._id)}
                            />
                          )}
                        </div>
                        {dropdownVisibility[item._id] && (
                          <div className='drop'>
                            <ul>
                              <li onClick={() => setShowAddMembers(item)}>
                                View/Edit
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='left'>
                        <h2>Name</h2>
                      </div>
                      <div className='right'>
                        <div className='ctd'>
                          <p>
                            {' '}
                            {item.firstName} {item.lastName}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='left'>
                        <h2>Email</h2>
                      </div>
                      <div className='right'>
                        <div className='ctd'>
                          <p>{item.email}</p>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='left'>
                        <h2>Roles</h2>
                      </div>
                      <div className='right'>
                        <div className='ctd'>
                          <p>{item.role}</p>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='left'>
                        <h2>Department</h2>
                      </div>
                      <div className='right'>
                        <div className='ctd'>
                          <p>
                            {item.department ? item.department.name : 'All'}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='left'>
                        <h2>Status</h2>
                      </div>
                      <div className='right'>
                        <div className='ctd'>
                          <p>{item.blocked ? 'Deactivated' : 'Active'}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <Pagination
                currentPage={memberCurrentPage}
                totalCount={membersData.totalPages}
                pageSize={10}
                onPageChange={(page: any) => {
                  setMemberCurrentPage(page)
                  memberPaginationClick(page)
                }}
              />
            </>
          )}
          <div className='add-new'>
            <button onClick={() => setShowAddMembers(true)}>
              Add new collaborators
            </button>
          </div>
        </div>
      </CheckPermissions>
    </div>
  )
}

export default Department
