import { useState } from 'react'
import { FaBars } from 'react-icons/fa'
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import Loader from '../../../../../Assests/loader.gif'
import { useGlobalContext } from '../../../../../Context/Context'
import Button from '../../../../../Components/Elements/Button/Button'

type FormData = {
  password: string
  confirmPassword: string
}
function Profile({ setShowSidebar, showSidebar, notify }: any) {
  const userPermissions: any = JSON.parse(
    sessionStorage.getItem('userPermissions') || '[]'
  )
  const [showPassword, setShowPassword] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false)
  const token = sessionStorage.getItem('accessToken')
  const [formLoading, setFormLoading] = useState(false)
  const { businessData } = useGlobalContext()
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<FormData>()
  const onSubmit = async (data: FormData) => {
    setFormLoading(true)
    const payLoad = {
      oldPassword: data.password,
      newPassword: data.confirmPassword,
    }
    try {
      const baseUrl = process.env.REACT_APP_API_URL // Get the base URL from the environment variable
      const response = await axios.post(
        `${baseUrl}/auth/password/reset`,
        payLoad,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      notify('success', response.data.message)
      setFormLoading(false)
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    } catch (error: any) {
      setFormLoading(false)
      notify('error', error.response.data.errors.join(','))
    }
  }
  const validatePassword = (value: string) => {
    const isValid = /^[a-zA-Z0-9-!@#$%^&*()_+\-=\[\]{}|;':",.<>\/?]+$/.test(
      value
    )
    return (
      isValid ||
      'Password should be alphanumeric and may contain special characters'
    )
  }
  return (
    <div className='department'>
      <div className='comp-one'>
        <div className='rowss'>
          <FaBars onClick={() => setShowSidebar(!showSidebar)} />
        </div>
        <div className='new-depart-top'>
          <div className='one'>
            <h2>Profile</h2>
          </div>
        </div>
        <div className='info-page'>
          <div className='card'>
            <h4>First Name</h4>
            <p>{businessData.firstName}</p>
          </div>
          <div className='card'>
            <h4>Last Name</h4>
            <p>{businessData.lastName}</p>
          </div>
          <div className='card'>
            <h4>Email</h4>
            <p>{businessData.email}</p>
          </div>
          <div className='card'>
            <h4>Role</h4>
            <p>{businessData.role}</p>
          </div>
          <div className='card long'>
            <h4>Permissions</h4>
            <p>
              {userPermissions
                .map((permission: string) => permission.replace(/_/g, ' '))
                .join(', ')}
            </p>
          </div>
        </div>
        <div className='new-depart-top'>
          <div className='one'>
            <h2>Security</h2>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className='password'>
          <div className='row'>
            <div className='cards'>
              <p> Old Password</p>
              <div className='input'>
                <input
                  type={showPassword ? 'text' : 'password'}
                  {...register('password', {
                    required: true,
                    minLength: 6,
                    validate: validatePassword,
                  })}
                  id=''
                />
                {showPassword ? (
                  <AiOutlineEye onClick={() => setShowPassword(false)} />
                ) : (
                  <AiOutlineEyeInvisible
                    onClick={() => setShowPassword(true)}
                  />
                )}
              </div>
              {errors.password && errors.password.type === 'required' && (
                <span>This field is required</span>
              )}
              {errors.password && errors.password.type === 'minLength' && (
                <span>Password must be at least 6 characters</span>
              )}
              {errors.password && errors.password.type === 'validate' && (
                <span>{errors.password.message}</span>
              )}
            </div>
            <div className='cards'>
              <p> New Password</p>
              <div className='input'>
                <input
                  type={showPassword2 ? 'text' : 'password'}
                  onPaste={(e) => e.preventDefault()}
                  {...register('confirmPassword', {
                    required: true,
                    minLength: 6,
                    validate: validatePassword,
                  })}
                  id=''
                />
                {showPassword2 ? (
                  <AiOutlineEye onClick={() => setShowPassword2(false)} />
                ) : (
                  <AiOutlineEyeInvisible
                    onClick={() => setShowPassword2(true)}
                  />
                )}
              </div>
              {errors.confirmPassword &&
                errors.confirmPassword.type === 'required' && (
                  <span>This field is required</span>
                )}
              {errors.confirmPassword &&
                errors.confirmPassword.type === 'validate' && (
                  <span>{errors.confirmPassword.message}</span>
                )}
              {errors.confirmPassword &&
                errors.confirmPassword.type === 'minLength' && (
                  <span>Password must be at least 6 characters</span>
                )}
            </div>
          </div>
          <div className='card'>
            {formLoading ? (
              <div className='loader'>
                <img src={Loader} alt='' />
              </div>
            ) : (
              <Button light content='Change Password' />
            )}
          </div>
        </form>
      </div>
    </div>
  )
}

export default Profile
