interface ImageWithFallbackProps {
  src: string
  alt: string
}

const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({ src, alt }) => {
  const handleImageError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    const imgElement = event.target as HTMLImageElement
    imgElement.src =
      'https://worldfishcenter.org/sites/default/files/inline-images/placeholder-image.png'
  }

  return <img src={src} onError={handleImageError} alt={alt} />
}

export default ImageWithFallback
