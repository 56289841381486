import React, { useState, useEffect, useRef } from 'react'
import TopBar from '../../../../Components/TopBar/TopBar'
import { FaChevronRight, FaSearch, FaCaretDown } from 'react-icons/fa'
import { BsThreeDotsVertical } from 'react-icons/bs'
import './Invoices.scss'
import { useGlobalContext } from '../../../../Context/Context'
import Loader from '../../../../Assests/loader.gif'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'
import Unauthorized from '../../../../Components/Unauthorized/Unauthorized'
import CheckPermissions from '../../../../Components/Unauthorized/CheckPermissions'
import Pagination from '../../../../Components/Pagination/Pagination'
import { loadavg } from 'os'

function Invoices({ notify }: any) {
  const authToken = sessionStorage.getItem('accessToken')
  const { businessData } = useGlobalContext()
  const [loading, setLoading] = useState(true)
  const [showDropdown, setShowDropdown] = useState<any>('false')
  const [selectedFilter, setSelectedFilter] = useState('Receivables')
  const [filteredData, setFilteredData] = useState<any>([])
  const [invoiceList, setInvoiceList] = useState<any>({})
  const [dropdownVisibility, setDropdownVisibility] = useState<any>({})
  const dropdownRefs = useRef<any>({})
  const tabRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const [allowed, setAllowed] = useState(false)
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (tabRef.current && !tabRef.current.contains(event.target as Node)) {
        setShowDropdown('false')
      }
    }

    if (!showDropdown) {
      document.addEventListener('mousedown', handleOutsideClick)
    } else {
      document.removeEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [showDropdown])
  const handleDropdownToggle = (divId: any) => {
    setDropdownVisibility((prevState: any) => ({
      ...prevState,
      [divId]: !prevState[divId],
    }))
  }
  const handleClickOutside = (event: any) => {
    Object.keys(dropdownRefs.current).forEach((divId) => {
      if (
        dropdownRefs.current[divId] &&
        !dropdownRefs.current[divId].contains(event.target)
      ) {
        setDropdownVisibility((prevState: any) => ({
          ...prevState,
          [divId]: false,
        }))
      }
    })
  }
  const fetchReceivablesList = async (page: any) => {
    setLoading(true)
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/invoice/${businessData.businessID._id}/fetch/supplier?limit=10&page=${page}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      if (res.status === 200) {
        setLoading(false)
        setInvoiceList(res.data.data)
        setAllowed(true)
      }
    } catch (err: any) {
      if (err.response.status === 401) {
        setLoading(false)
        setAllowed(false)
      }
    }
  }
  const fetchPayablesList = async (page: any) => {
    setLoading(true)
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/invoice/${businessData.businessID._id}/fetch/buyer?limit=10&page=${page}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      if (res.status === 200) {
        setLoading(false)
        setInvoiceList(res.data.data)
        setAllowed(true)
      }
    } catch (err: any) {
      if (err.response.status === 401) {
        setLoading(false)
        setAllowed(false)
      }
    }
  }
  //Allow invoice tab page stay in one place
  const location: any = useLocation()
  const params = new URLSearchParams(location.search)
  const myString = params.get('search')
  useEffect(() => {
    if (myString) {
      if (myString === 'receivables') {
        fetchReceivablesList(1)
        selectFilter('Receivables')
      } else {
        fetchPayablesList(1)
        selectFilter('Payables')
      }
    } else {
      fetchReceivablesList(1)
    }
  }, [])
  const selectFilter = (e: string) => {
    setSelectedFilter(e)
    setShowDropdown('false')
    if (e === 'Receivables') {
      fetchReceivablesList(1)
      window.history.pushState(
        null,
        '',
        `/user/invoices?search=${encodeURIComponent('receivables')}`
      )
    } else {
      fetchPayablesList(1)
      window.history.pushState(
        null,
        '',
        `/user/invoices?search=${encodeURIComponent('payables')}`
      )
    }
  }
  const shortenName = (name: string) => {
    if (!loading) {
      if (name.length > 10) {
        return `${name.slice(0, 10)}...`
      } else {
        return name
      }
    }
  }
  const dateConverter = (date: string) => {
    return new Date(date).toDateString()
  }
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value

    const filteredData = invoiceList.docs.filter((item: any) => {
      const SearchMacth = item.number
        .toLowerCase()
        .includes(searchTerm.toLowerCase())

      if (selectedFilter === 'Payables') {
        const bussinesName = item.businessID.name
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
        return bussinesName || SearchMacth
      } else {
        const sellersName = item.sellerName
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
        return sellersName || SearchMacth
      }
    })
    setFilteredData(filteredData)
  }
  function getDateStatus(targetDate: string) {
    const today = new Date()
    const target = new Date(targetDate)
    const timeDiff = target.getTime() - today.getTime()
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24))

    if (daysDiff > 0) {
      return `${daysDiff} days left`
    } else if (daysDiff < 0) {
      return `${Math.abs(daysDiff)} days overdue`
    } else {
      return 'Due today'
    }
  }
  //pagination
  const [currentPage, setCurrentPage] = useState(1)
  const paginationClick = (num: any) => {
    setLoading(true)
    if (myString) {
      if (myString === 'receivables') {
        fetchReceivablesList(num)
      } else {
        fetchPayablesList(num)
      }
    } else {
      fetchReceivablesList(num)
    }
  }

  return (
    <div className='invoices'>
      <TopBar />
      <div className='head'>
        <div className='left'>
          <p>Paytton</p>
          <FaChevronRight />
          <h4>Invoice</h4>
        </div>
        <div className='right'>
          <CheckPermissions requiredPermissions={['create_invoice', '*']}>
            <button onClick={() => navigate('/user/create-invoice')}>
              Create Invoice
            </button>
          </CheckPermissions>
        </div>
      </div>
      <CheckPermissions requiredPermissions={['view_invoice']}>
        <div className='invoice-head'>
          <div className='left'>
            <div className='dropdown' ref={tabRef}>
              <div
                className='drop-head'
                onClick={() => setShowDropdown(!showDropdown)}
              >
                <p>{selectedFilter}</p>
                <FaCaretDown />
              </div>
              <ul className={!showDropdown ? 'active' : ''}>
                <li
                  className={selectedFilter === 'Receivables' ? 'active' : ''}
                  onClick={() => selectFilter('Receivables')}
                >
                  Receivables
                </li>
                <li
                  className={selectedFilter === 'Payables' ? 'active' : ''}
                  onClick={() => selectFilter('Payables')}
                >
                  Payables
                </li>
              </ul>
            </div>
          </div>
          <div className='right'>
            <div className='search'>
              <FaSearch />
              <input type='text' onChange={(e) => handleSearch(e)} />
            </div>
          </div>
        </div>
      </CheckPermissions>

      {loading ? (
        <div className='loader'>
          <img src={Loader} alt='' />
        </div>
      ) : (
        <>
          {allowed ? (
            <>
              <div className='tablex'>
                <table>
                  <thead>
                    <tr>
                      <th>Invoice No.</th>
                      <th>Vendor</th>
                      <th>Amount</th>
                      <th>Invoice date</th>
                      <th>Invoice due date</th>
                      <th>Invoice Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.length >= 1 ? (
                      <>
                        {filteredData.map((item: any, i: number) => (
                          <tr key={i + 1}>
                            <td
                              onClick={() =>
                                navigate(`/user/invoice-details/${item._id}`)
                              }
                            >
                              {item.number}
                            </td>
                            <td
                              onClick={() => {
                                if (selectedFilter === 'Receivables') {
                                  navigate(`/user/vendor/${item.sellerID._id}`)
                                } else {
                                  navigate(
                                    `/user/vendor/${item.businessID._id}`
                                  )
                                }
                              }}
                            >
                              {selectedFilter === 'Payables' ? (
                                <p>{shortenName(item.businessID.name)}</p>
                              ) : (
                                <p>{shortenName(item.sellerName)}</p>
                              )}
                            </td>
                            <td>
                              {item.currency}
                              {item.amount.toLocaleString()}
                            </td>
                            <td>
                              <p>{dateConverter(item.date)}</p>
                            </td>
                            <td>
                              <p>{getDateStatus(item.requiredEnd)}</p>
                            </td>
                            <td>
                              <button>
                                {item.status === 'awaiting_internal_approval'
                                  ? 'Awaiting Internal Approval'
                                  : item.status === 'approved'
                                  ? 'Approved Internally'
                                  : item.status === 'declined_internally'
                                  ? 'Declined Internally'
                                  : item.status === 'awaiting_customer_approval'
                                  ? selectedFilter === 'Payables'
                                    ? 'Awaiting your approval'
                                    : 'Sent to Customer'
                                  : item.status === 'customer_declined'
                                  ? 'Declined by Customer'
                                  : item.status === 'approved_for_financing'
                                  ? 'Approved by Customer'
                                  : item.status === 'outstanding'
                                  ? 'Outstanding'
                                  : 'Fully Paid'}{' '}
                              </button>
                            </td>
                            <td
                              ref={(ref) =>
                                (dropdownRefs.current[item._id] = ref)
                              }
                            >
                              <div className='dropd'>
                                <BsThreeDotsVertical
                                  onClick={() => handleDropdownToggle(item._id)}
                                />
                              </div>
                              {dropdownVisibility[item._id] && (
                                <div className='drop'>
                                  <ul>
                                    {/* <li>Download</li> */}
                                    <li
                                      onClick={() =>
                                        navigate(
                                          `/user/invoice-details/${item._id}`
                                        )
                                      }
                                    >
                                      {selectedFilter === 'Payables'
                                        ? 'View'
                                        : 'View/Edit'}
                                    </li>
                                    {/* <li>Share invoice</li>
                                <li>Print</li> */}
                                  </ul>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <>
                        {invoiceList.docs.map((item: any, i: number) => (
                          <tr key={i + 1}>
                            <td
                              onClick={() =>
                                navigate(`/user/invoice-details/${item._id}`)
                              }
                            >
                              {item.number}
                            </td>
                            <td
                              onClick={() => {
                                if (selectedFilter === 'Receivables') {
                                  navigate(`/user/vendor/${item.sellerID._id}`)
                                } else {
                                  navigate(
                                    `/user/vendor/${item.businessID._id}`
                                  )
                                }
                              }}
                            >
                              {selectedFilter === 'Payables' ? (
                                <p>{shortenName(item.businessID.name)}</p>
                              ) : (
                                <p>{shortenName(item.sellerName)}</p>
                              )}
                            </td>
                            <td>
                              {item.currency}
                              {item.amount.toLocaleString()}
                            </td>
                            <td>
                              <p>{dateConverter(item.date)}</p>
                            </td>
                            <td>
                              <p>{getDateStatus(item.requiredEnd)}</p>
                            </td>
                            <td>
                              <button>
                                {item.status === 'awaiting_internal_approval'
                                  ? 'Awaiting Internal Approval'
                                  : item.status === 'approved'
                                  ? 'Approved Internally'
                                  : item.status === 'declined_internally'
                                  ? 'Declined Internally'
                                  : item.status === 'awaiting_customer_approval'
                                  ? selectedFilter === 'Payables'
                                    ? 'Awaiting your approval'
                                    : 'Sent to Customer'
                                  : item.status === 'customer_declined'
                                  ? selectedFilter === 'Payables'
                                    ? 'Declined'
                                    : 'Declined by Customer'
                                  : item.status === 'approved_for_financing'
                                  ? 'Approved by Customer'
                                  : item.status === 'outstanding'
                                  ? 'Outstanding'
                                  : 'Fully Paid'}{' '}
                              </button>
                            </td>
                            <td
                              ref={(ref) =>
                                (dropdownRefs.current[item._id] = ref)
                              }
                            >
                              <div className='dropd'>
                                <BsThreeDotsVertical
                                  onClick={() => handleDropdownToggle(item._id)}
                                />
                              </div>
                              {dropdownVisibility[item._id] && (
                                <div className='drop'>
                                  <ul>
                                    {/* <li>Download</li> */}
                                    <li
                                      onClick={() =>
                                        navigate(
                                          `/user/invoice-details/${item._id}`
                                        )
                                      }
                                    >
                                      {selectedFilter === 'Payables'
                                        ? 'View'
                                        : 'View/Edit'}
                                    </li>
                                    {/* <li>Share invoice</li>
                                <li>Print</li> */}
                                  </ul>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div className='mobile'>
                {filteredData.length >= 1 ? (
                  <>
                    {filteredData.map((item: any, i: number) => (
                      <div
                        className='nodyx'
                        // onClick={() => selectProduct(i + 1)}
                        key={i + 1}
                      >
                        <div className='row'>
                          <div className='left'></div>
                          <div className='right'>
                            <div className='dropd'>
                              <BsThreeDotsVertical
                                onClick={() => handleDropdownToggle(i + 1)}
                              />
                            </div>
                            {dropdownVisibility[i + 1] && (
                              <div className='drop'>
                                <ul>
                                  {/* <li>Download</li> */}
                                  <li
                                    onClick={() =>
                                      navigate(
                                        `/user/invoice-details/${item._id}`
                                      )
                                    }
                                  >
                                    {selectedFilter === 'Payables'
                                      ? 'View'
                                      : 'View/Edit'}
                                  </li>
                                  {/* <li> Make recurring</li>
                                  <li>Share invoice</li>
                                  <li>Print</li> */}
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className='row'>
                          <div className='left'>
                            <h2>Invoice No</h2>
                          </div>
                          <div className='right'>
                            <div
                              className='ctd'
                              onClick={() =>
                                navigate(`/user/invoice-details/${item._id}`)
                              }
                            >
                              <p>{item.number}</p>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='left'>
                            <h2>Vendors</h2>
                          </div>
                          <div className='right'>
                            <div
                              className='ctd'
                              onClick={() => {
                                if (selectedFilter === 'Receivables') {
                                  navigate(`/user/vendor/${item.sellerID._id}`)
                                } else {
                                  navigate(
                                    `/user/vendor/${item.businessID._id}`
                                  )
                                }
                              }}
                            >
                              {selectedFilter === 'Payables' ? (
                                <p>{shortenName(item.businessID.name)}</p>
                              ) : (
                                <p>{shortenName(item.sellerName)}</p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='left'>
                            <h2>Amount</h2>
                          </div>
                          <div className='right'>
                            <div className='ctd'>
                              <p>
                                {item.currency}
                                {item.amount.toLocaleString()}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='left'>
                            <h2>Invoice date</h2>
                          </div>
                          <div className='right'>
                            <div className='ctd'>
                              <p>{dateConverter(item.date)}</p>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='left'>
                            <h2>Invoice due date</h2>
                          </div>
                          <div className='right'>
                            <div className='ctd'>
                              <p>{getDateStatus(item.requiredEnd)}</p>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='left'>
                            <h2>Invoice Status</h2>
                          </div>
                          <div className='right'>
                            <div className='ctd'>
                              <button>
                                {' '}
                                {item.status === 'awaiting_internal_approval'
                                  ? 'Awaiting Internal Approval'
                                  : item.status === 'approved'
                                  ? 'Approved Internally'
                                  : item.status === 'declined_internally'
                                  ? 'Declined Internally'
                                  : item.status === 'awaiting_customer_approval'
                                  ? selectedFilter === 'Payables'
                                    ? 'Awaiting your approval'
                                    : 'Sent to Customer'
                                  : item.status === 'customer_declined'
                                  ? 'Declined by Customer'
                                  : item.status === 'approved_for_financing'
                                  ? 'Approved by Customer'
                                  : item.status === 'outstanding'
                                  ? 'Outstanding'
                                  : 'Fully Paid'}{' '}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {invoiceList.docs.map((item: any, i: number) => (
                      <div
                        className='nodyx'
                        // onClick={() => selectProduct(i + 1)}
                        key={i + 1}
                      >
                        <div className='row'>
                          <div className='left'></div>
                          <div className='right'>
                            <div className='dropd'>
                              <BsThreeDotsVertical
                                onClick={() => handleDropdownToggle(i + 1)}
                              />
                            </div>
                            {dropdownVisibility[i + 1] && (
                              <div className='drop'>
                                <ul>
                                  {/* <li>Download</li> */}
                                  <li
                                    onClick={() =>
                                      navigate(
                                        `/user/invoice-details/${item._id}`
                                      )
                                    }
                                  >
                                    {selectedFilter === 'Payables'
                                      ? 'View'
                                      : 'View/Edit'}
                                  </li>
                                  {/* <li> Make recurring</li>
                                  <li>Share invoice</li>
                                  <li>Print</li> */}
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className='row'>
                          <div className='left'>
                            <h2>Invoice No</h2>
                          </div>
                          <div className='right'>
                            <div
                              className='ctd'
                              onClick={() =>
                                navigate(`/user/invoice-details/${item._id}`)
                              }
                            >
                              <p>{item.number}</p>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='left'>
                            <h2>Vendors</h2>
                          </div>
                          <div className='right'>
                            <div
                              className='ctd'
                              onClick={() => {
                                if (selectedFilter === 'Receivables') {
                                  navigate(`/user/vendor/${item.sellerID._id}`)
                                } else {
                                  navigate(
                                    `/user/vendor/${item.businessID._id}`
                                  )
                                }
                              }}
                            >
                              {selectedFilter === 'Payables' ? (
                                <p>{shortenName(item.businessID.name)}</p>
                              ) : (
                                <p>{shortenName(item.sellerName)}</p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='left'>
                            <h2>Amount</h2>
                          </div>
                          <div className='right'>
                            <div className='ctd'>
                              <p>
                                {item.currency}
                                {item.amount.toLocaleString()}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='left'>
                            <h2>Invoice date</h2>
                          </div>
                          <div className='right'>
                            <div className='ctd'>
                              <p>{dateConverter(item.date)}</p>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='left'>
                            <h2>Invoice due date</h2>
                          </div>
                          <div className='right'>
                            <div className='ctd'>
                              <p>{getDateStatus(item.requiredEnd)}</p>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='left'>
                            <h2>Invoice Status</h2>
                          </div>
                          <div className='right'>
                            <div className='ctd'>
                              <button>
                                {' '}
                                {item.status === 'awaiting_internal_approval'
                                  ? 'Awaiting Internal Approval'
                                  : item.status === 'approved'
                                  ? 'Approved Internally'
                                  : item.status === 'declined_internally'
                                  ? 'Declined Internally'
                                  : item.status === 'awaiting_customer_approval'
                                  ? selectedFilter === 'Payables'
                                    ? 'Awaiting your approval'
                                    : 'Sent to Customer'
                                  : item.status === 'customer_declined'
                                  ? 'Declined by Customer'
                                  : item.status === 'approved_for_financing'
                                  ? 'Approved by Customer'
                                  : item.status === 'outstanding'
                                  ? 'Outstanding'
                                  : 'Fully Paid'}{' '}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
                <></>
              </div>
              <Pagination
                currentPage={currentPage}
                totalCount={invoiceList.totalPages}
                pageSize={10}
                onPageChange={(page: any) => {
                  setCurrentPage(page)
                  paginationClick(page)
                }}
              />
            </>
          ) : (
            <Unauthorized />
          )}
        </>
      )}
    </div>
  )
}

export default Invoices
