import { useState, useEffect } from 'react'
import { FaBars, FaFile } from 'react-icons/fa'
import Loader from '../../../../../Assests/loader.gif'
import Docs from '../../../../Onboarding/components/Docs'
import Directors from '../../../../Onboarding/components/Directors'
import Reps from '../../../../Onboarding/components/Reps'
import Button from '../../../../../Components/Elements/Button/Button'

function Kyb({ setShowSidebar, showSidebar, notify, businessData }: any) {
  const token = sessionStorage.getItem('accessToken')
  const [formLoading, setFormLoading] = useState(false)

  //This is the part for the Documents
  const [businessDoc, setBusinessDoc] = useState<File | null>(null)
  const [utilityBillDoc, setUtilityBillDoc] = useState<File | null>(null)
  const [identificationDoc, setIdentificationDoc] = useState<File | null>(null)
  const [licensesDoc, setLicensesDoc] = useState<File | null>(null)
  const [showEditKYBDoc, setShowEditKYBDoc] = useState(false)
  const [businessDocName, setBusinessDocName] = useState<string>(
    'No file selected yet'
  )
  const [utilityBillDocName, setUtilityBillDocName] = useState<string>(
    'No file selected yet'
  )
  const [identificationDocName, setIdentificationDocName] = useState<string>(
    'No file selected yet'
  )
  const [licensesDocName, setLicensesDocName] = useState<string>(
    'No file selected yet'
  )
  const [emptyFields, setEmptyFields] = useState<any>([])

  const handleSubmit = async () => {
    setFormLoading(true)
    const formData = new FormData()
    let files = []

    if (businessDoc !== null) {
      files.push(businessDoc)
    }

    if (utilityBillDoc !== null) {
      files.push(utilityBillDoc)
    }

    if (identificationDoc !== null) {
      files.push(identificationDoc)
    }

    if (licensesDoc !== null) {
      files.push(licensesDoc)
    }
    files.forEach((file) => {
      formData.append('kybDocument', file)
    })

    fetch(
      `${process.env.REACT_APP_API_URL}/business/${businessData.businessID._id}/update`,
      {
        method: 'put',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
        redirect: 'follow',
      }
    )
      .then((res) => {
        if (res.status === 201) {
          notify(
            'success',
            'Business update request submitted successfully and is under review.'
          )
          setFormLoading(false)
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        } else if (res.status === 403) {
          res.json().then((data) => {})
          // setTimeout(() => {
          //   navigate('/')
          // }, 3000)
        } else {
          res.json().then((data) => {
            notify('error', data.errors.join(','))
            setFormLoading(false)
          })
        }
      })
      .catch((error) => {
        notify('error', 'An error occured please try again')
        setFormLoading(false)
      })
  }
  const showName = (name: string) => {
    switch (name) {
      case 'BusinessDoc':
        return 'Business registration document'
      case 'LicensesDoc':
        return 'Lincences document'
      case 'UtilityBillDoc':
        return ' Latest Utility bill'
      case 'IdentificationDoc':
        return 'Identification documents'
      default:
        break
    }
  }
  const cancelChanges = () => {
    setBusinessDoc(null)
    setUtilityBillDoc(null)
    setIdentificationDoc(null)
    setLicensesDoc(null)
    setBusinessDocName('No file selected yet')
    setUtilityBillDocName('No file selected yet')
    setIdentificationDocName('No file selected yet')
    setLicensesDocName('No file selected yet')
    setShowEditKYBDoc(false)
  }
  useEffect(() => {
    setDirectoss(businessData.businessID.directorsDetails)
    setReps(businessData.businessID.repsDetails)
  }, [])

  //This is for the directors part
  const [directorsFormLoading, setDirectorsFormLoading] = useState(false)

  const [directoss, setDirectoss] = useState<any>([
    {
      fullName: '',
      NIN: '',
    },
  ])
  const handleDirectorSubmit = async () => {
    setDirectorsFormLoading(true)
    const formData = new FormData()
    for (let i = 0; i < directoss.length; i++) {
      const addressObj = directoss[i]
      formData.append('directorsDetails', JSON.stringify(addressObj))
    }
    fetch(
      `${process.env.REACT_APP_API_URL}/business/${businessData.businessID._id}/update`,
      {
        method: 'put',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
        redirect: 'follow',
      }
    )
      .then((res) => {
        if (res.status === 201) {
          notify(
            'success',
            'Business update request submitted successfully and is under review.'
          )
          setDirectorsFormLoading(false)
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        } else if (res.status === 403) {
          // setTimeout(() => {
          //   navigate('/')
          // }, 3000)
        } else {
          res.json().then((data) => {
            notify('error', data.errors.join(','))
            setDirectorsFormLoading(false)
          })
        }
      })
      .catch((error) => {
        notify('error', 'An error occured please try again')
        setDirectorsFormLoading(false)
      })
  }
  //This is for the Reps part
  const [repsFormLoading, setRepsFormLoading] = useState(false)

  const [reps, setReps] = useState<any>([
    {
      firstName: '',
      lastName: '',
      email: '',
      position: '',
    },
  ])
  const handleRepsSubmit = async () => {
    setRepsFormLoading(true)
    const formData = new FormData()
    for (let i = 0; i < reps.length; i++) {
      const addressObj = reps[i]
      formData.append('repsDetails', JSON.stringify(addressObj))
    }
    fetch(
      `${process.env.REACT_APP_API_URL}/business/${businessData.businessID._id}/update`,
      {
        method: 'put',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
        redirect: 'follow',
      }
    )
      .then((res) => {
        if (res.status === 201) {
          notify(
            'success',
            'Business update request submitted successfully and is under review.'
          )
          setRepsFormLoading(false)
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        } else if (res.status === 403) {
          // setTimeout(() => {
          //   navigate('/')
          // }, 3000)
        } else {
          res.json().then((data) => {
            notify('error', data.errors.join(','))
            setRepsFormLoading(false)
          })
        }
      })
      .catch((error) => {
        notify('error', 'An error occured please try again')
        setRepsFormLoading(false)
      })
  }
  const [notEditDirector, setNotEditDirector] = useState(true)
  const [notEditRep, setNotEditRep] = useState(true)
  return (
    <div className='department'>
      <div className='comp-one'>
        <div className='rowss'>
          <FaBars onClick={() => setShowSidebar(!showSidebar)} />
        </div>
        <div className='depart-top'>
          <div className='one'>
            <h2>Kyb Documents</h2>
            <p>
              Update KYB Document. Note that you will be required to upload all
              4 documents again if you need to change at least 1 document, and
              any changes made will only take effect after it has been verified.
            </p>
          </div>
          <div className='two'></div>
        </div>

        {!showEditKYBDoc ? (
          <div className='depts-list'>
            {businessData.businessID.kybDocument.map((item: any) => (
              <div className='flow' key={item._id}>
                <a
                  href={item.documentURL}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='openFile'
                >
                  <FaFile />
                  <p>{showName(item.name)}</p>
                </a>
              </div>
            ))}
            <div className='new docs'>
              <div className='add'>
                <button onClick={() => setShowEditKYBDoc(true)}>
                  Update Info
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className='docss'>
            <Docs
              businessDoc={businessDoc}
              licensesDoc={licensesDoc}
              utilityBillDoc={utilityBillDoc}
              identificationDoc={identificationDoc}
              setBusinessDoc={setBusinessDoc}
              setUtilityBillDoc={setUtilityBillDoc}
              setIdentificationDoc={setIdentificationDoc}
              setLicensesDoc={setLicensesDoc}
              businessDocName={businessDocName}
              utilityBillDocName={utilityBillDocName}
              setBusinessDocName={setBusinessDocName}
              setLicensesDocName={setLicensesDocName}
              identificationDocName={identificationDocName}
              licensesDocName={licensesDocName}
              setUtilityBillDocName={setUtilityBillDocName}
              setIdentificationDocName={setIdentificationDocName}
              emptyFields={emptyFields}
            />
            <div className='button' style={{ textAlign: 'center' }}>
              {formLoading ? (
                <div className='loader'>
                  <img src={Loader} alt='' />
                </div>
              ) : (
                <>
                  <button onClick={cancelChanges}>Cancel</button>
                  <button onClick={handleSubmit}>Submit</button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
      <div className='comp-one'>
        {notEditDirector ? (
          <>
            <div className='new-depart-top'>
              <div className='one'>
                <h2>Director’s Info</h2>
              </div>
              <Button
                content='Edit'
                light
                onClick={() => {
                  setNotEditDirector(false)
                }}
              />
            </div>
            <div className='info-page'>
              {directoss.map((form: any, index: any) => (
                <>
                  <div className='card'>
                    <h4>Company Name</h4>
                    <p>{form.fullName}</p>
                  </div>
                  <div className='card'>
                    <h4>Director's NIN</h4>
                    <p>{form.NIN}</p>
                  </div>
                </>
              ))}
            </div>
          </>
        ) : (
          <div className='docss'>
            <Directors
              directoss={directoss}
              setDirectoss={setDirectoss}
              emptyFields={emptyFields}
              disabled={notEditDirector}
            />
            <div className='button' style={{ textAlign: 'center' }}>
              {directorsFormLoading ? (
                <div className='loader'>
                  <img src={Loader} alt='' />
                </div>
              ) : (
                <>
                  <button onClick={() => setNotEditDirector(true)}>
                    Cancel
                  </button>
                  <button onClick={handleDirectorSubmit}>Submit</button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
      <div className='comp-one'>
        {notEditRep ? (
          <>
            {' '}
            <div className='new-depart-top'>
              <div className='one'>
                <h2>Rep's Info</h2>
              </div>
              <Button
                content='Edit'
                light
                onClick={() => {
                  setNotEditRep(false)
                }}
              />
            </div>
            <div className='info-page'>
              {reps.map((form: any, index: any) => (
                <>
                  <div className='card'>
                    <h4>Rep First Name</h4>
                    <p>{form.firstName}</p>
                  </div>
                  <div className='card'>
                    <h4>Rep Last Name</h4>
                    <p>{form.lastName}</p>
                  </div>
                  <div className='card'>
                    <h4>Rep Email</h4>
                    <p>{form.email}</p>
                  </div>
                  <div className='card'>
                    <h4>Rep Position</h4>
                    <p>{form.position}</p>
                  </div>
                </>
              ))}
            </div>
          </>
        ) : (
          <div className='docss'>
            <Reps
              reps={reps}
              setReps={setReps}
              emptyFields={emptyFields}
              disabled={notEditRep}
            />
            <div className='button' style={{ textAlign: 'center' }}>
              {repsFormLoading ? (
                <div className='loader'>
                  <img src={Loader} alt='' />
                </div>
              ) : (
                <>
                  <button onClick={() => setNotEditRep(true)}>Cancel</button>
                  <button onClick={handleRepsSubmit}>Submit</button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Kyb
