import React, { useState, useEffect } from 'react'
import { FaTimes, FaTimesCircle } from 'react-icons/fa'
import { BsUpload } from 'react-icons/bs'
import Loader from '../../../../../Assests/loader.gif'
import axios from 'axios'
import { useGlobalContext } from '../../../../../Context/Context'

function EditVendor({ setEditVendor, notify, vendorData }: any) {
  const authToken = sessionStorage.getItem('accessToken')
  const { businessData } = useGlobalContext()
  const [vendorName, setVendorName] = useState('')
  const [contactName, setContactName] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [contactPhone, setContactPhone] = useState('')
  const [goodsSupplied, setGoodsSupplied] = useState('')
  const [termsOfTrade, setTermsOfTrade] = useState('')
  const [selectedFile, setSelectedFile] = useState<any>([])
  const [requestLoading, setRequestLoading] = useState(false)
  const [newTermsOfTrade, setNewTermsOfTrade] = useState('')

  const handleSubmit = (e: any) => {
    e.preventDefault()
    let terms = termsOfTrade
    if (termsOfTrade === 'others') {
      terms = newTermsOfTrade
    }
    const formData = new FormData()
    formData.append('contactName', contactName)
    formData.append('contactEmail', contactEmail)
    formData.append('contactPhone', contactPhone)
    formData.append('goodsSupplied', goodsSupplied)
    formData.append('termsOfTrade', terms)
    selectedFile.forEach((file: any) => {
      formData.append('contract', file)
    })
    const formDataValues: { [key: string]: FormDataEntryValue } = {}

    Array.from(formData.entries()).forEach(([key, value]) => {
      formDataValues[key] = value
    })

    const data = {
      contactName: contactName,
      contactEmail: contactEmail.toLocaleLowerCase(),
      contactPhone: contactPhone,
      goodsSupplied: goodsSupplied,
      termsOfTrade: terms,
    }
    let submitData = null
    if (selectedFile.length > 0) {
      submitData = formData
    } else {
      submitData = data
    }

    setRequestLoading(true)

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/business/${businessData.businessID._id}/connection/update/${vendorData._id}`,
        submitData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((response) => {
        notify('success', 'Vendor Updated successfully')
        setRequestLoading(false)
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      })
      .catch((error) => {
        setRequestLoading(false)
      })
  }
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files) {
      const newFiles: File[] = Array.from(files)
      setSelectedFile((prevSelectedFiles: any) => [
        ...prevSelectedFiles,
        ...newFiles,
      ])
    }
  }
  const handleFileDelete = (fileName: string) => {
    setSelectedFile((prevSelectedFiles: any) =>
      prevSelectedFiles.filter((file: any) => file.name !== fileName)
    )
  }
  useEffect(() => {
    setContactName(vendorData.contactName)
    setContactEmail(vendorData.contactEmail)
    setContactPhone(vendorData.contactPhone)
    setGoodsSupplied(vendorData.goodsSupplied)
    setTermsOfTrade(vendorData.termsOfTrade)
  }, [vendorData])

  return (
    <div className='modal-body'>
      <div className='modal'>
        <div className='close'>
          <FaTimes onClick={() => setEditVendor(false)} />
        </div>
        <div className='tag'>
          <p>Edit Vendor</p>
        </div>
        <div className='form'>
          <form onSubmit={handleSubmit}>
            <div className='card'>
              <p>Contact Name</p>
              <input
                type='text'
                value={contactName}
                onChange={(e) => setContactName(e.target.value)}
                required
              />
            </div>
            <div className='card'>
              <p>Contact Email</p>
              <input
                type='email'
                value={contactEmail}
                onChange={(e) => setContactEmail(e.target.value)}
                required
              />
            </div>
            <div className='card'>
              <p>Contact Phone</p>
              <input
                type='tel'
                value={contactPhone}
                onChange={(e) => setContactPhone(e.target.value)}
              />
            </div>
            <div className='card'>
              <p>Goods Supplied</p>
              <input
                type='text'
                value={goodsSupplied}
                onChange={(e) => setGoodsSupplied(e.target.value)}
                required
              />
            </div>
            <div className='card'>
              <p>Terms of Trade</p>
              <select
                value={termsOfTrade}
                onChange={(e) => setTermsOfTrade(e.target.value)}
                required
              >
                <option value=''>-Select-</option>
                <option value='30 days'>30 days</option>
                <option value='60 days'>60 days</option>
                <option value='90 days'>90 days</option>
                <option value='others'>Others</option>
              </select>
            </div>
            {termsOfTrade === 'others' && (
              <div className='card'>
                <p>Type terms of trade</p>
                <input
                  type='text'
                  onChange={(e) => setNewTermsOfTrade(e.target.value)}
                />
              </div>
            )}
            <div className='cardX'>
              <div className='lef'>
                <h4>Contract Document</h4>
              </div>
              <div className='rig'>
                <input
                  type='file'
                  name='contract'
                  id='biz-reg'
                  accept='.pdf, .jpg, .jpeg, .png'
                  onChange={handleFileChange}
                  multiple
                />
                <label htmlFor='biz-reg'>
                  <div className='butz'>
                    <BsUpload />
                    <p>Upload</p>
                  </div>
                </label>
                {selectedFile && (
                  <>
                    {selectedFile.map((file: any, index: number) => (
                      <div className='flow' key={index + 1}>
                        <p className='sel' key={file.name}>
                          {file.name}
                        </p>
                        <FaTimesCircle
                          onClick={() => handleFileDelete(file.name)}
                        />
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
            {requestLoading ? (
              <div className='buttons'>
                <img src={Loader} alt='Loader' />
              </div>
            ) : (
              <div className='buttons'>
                <button type='submit'>Done</button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default EditVendor
