import React, { useState, useEffect } from 'react'
import './Products.scss'
import TopBar from '../../../../Components/TopBar/TopBar'
import { FaChevronRight, FaSearch } from 'react-icons/fa'
import { useGlobalContext } from '../../../../Context/Context'
import AddProduct from './Component/AddProduct'
import Loader from '../../../../Assests/loader.gif'
import axios from 'axios'
import EditVendor from '../Vendor/Component/EditVendor'
import EditProduct from './Component/EditProduct'
import CheckPermissions from '../../../../Components/Unauthorized/CheckPermissions'
import Pagination from '../../../../Components/Pagination/Pagination'

function Products({ notify }: any) {
  const authToken = sessionStorage.getItem('accessToken')
  const { businessData } = useGlobalContext()
  const [addProduct, setAddProduct] = useState(false)
  const [loading, setLoading] = useState(true)
  const [filteredData, setFilteredData] = useState<any>([])
  const [productList, setProductList] = useState<any>([])
  const [editProduct, setEditProduct] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState({})

  const fetchProducts = async (page: any) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/product/${businessData.businessID._id}/fetch?limit=10&page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      if (res.status === 200) {
        setProductList(res.data.data)
        setLoading(false)
      }
    } catch (error) {}
  }
  useEffect(() => {
    fetchProducts(1)
  }, [])

  const selectProduct = (id: string) => {
    const findProduct = productList.docs.find((item: any) => item._id === id)
    setSelectedProduct(findProduct)
    setEditProduct(true)
  }
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value

    const filteredData = productList.docs.filter((item: any) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    setFilteredData(filteredData)
  }
  const [currentPage, setCurrentPage] = useState(1)
  const paginationClick = (num: any) => {
    setLoading(true)
    fetchProducts(num)
  }
  return (
    <div className='products'>
      {addProduct && (
        <AddProduct notify={notify} setAddProduct={setAddProduct} />
      )}
      {editProduct && (
        <EditProduct
          notify={notify}
          setEditProduct={setEditProduct}
          selectedProduct={selectedProduct}
        />
      )}
      <TopBar />
      <div className='head'>
        <div className='left'>
          <p>Paytton</p>
          <FaChevronRight />
          <h4>Products</h4>
        </div>
        <div className='right'></div>
      </div>
      <div className='product-head'>
        <div className='left'>
          <CheckPermissions requiredPermissions={['create_invoice']}>
            <button
              className={
                businessData.businessID.status !== 'active' ? 'opac' : ''
              }
              onClick={() => {
                if (businessData.businessID.status === 'active') {
                  setAddProduct(true)
                } else {
                  notify('info', 'Business is yet to be verified')
                }
              }}
            >
              Add new product
            </button>
          </CheckPermissions>
        </div>
        <div className='right'>
          <div className='search'>
            <FaSearch />
            <input
              type='text'
              onChange={(e) => handleSearch(e)}
              placeholder='search name, Id, or product'
            />
          </div>
        </div>
      </div>
      {loading ? (
        <div className='loader'>
          <img src={Loader} alt='' />
        </div>
      ) : (
        <>
          <div className='tablex'>
            <table>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Product Name</th>
                  <th>Product Currency</th>
                  <th>Product Price</th>
                  <th>Product description</th>
                  <th>Date added</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.length >= 1 ? (
                  <>
                    {filteredData.map((item: any, i: number) => (
                      <tr
                        onClick={() => selectProduct(item._id)}
                        key={item._id}
                      >
                        <td>{1 + i}</td>
                        <td>
                          <p>{item.name}</p>
                        </td>
                        <td>
                          <p>{item.currency}</p>
                        </td>
                        <td> {item.price.toLocaleString()}</td>
                        <td>
                          <p>{item.description}</p>
                        </td>
                        <td>
                          <p>{new Date(item.updatedAt).toDateString()}</p>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <>
                    {productList.docs.map((item: any, i: number) => (
                      <tr
                        onClick={() => selectProduct(item._id)}
                        key={item._id}
                      >
                        <td>{1 + i}</td>
                        <td>
                          <p>{item.name}</p>
                        </td>
                        <td>
                          <p>{item.currency}</p>
                        </td>
                        <td> {item.price.toLocaleString()}</td>
                        <td>
                          <p>{item.description}</p>
                        </td>
                        <td>
                          <p>{new Date(item.updatedAt).toDateString()}</p>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
          <div className='mobile'>
            {filteredData.length >= 1 ? (
              <>
                {filteredData.map((item: any, i: number) => (
                  <div
                    className='nodyx'
                    onClick={() => selectProduct(item._id)}
                    key={item._id}
                  >
                    <div className='row'>
                      <div className='left'>
                        <h2>S/N</h2>
                      </div>
                      <div className='right'>
                        <p>{1 + i}</p>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='left'>
                        <h2>Product name</h2>
                      </div>
                      <div className='right'>
                        <div className='ctd'>
                          <p>{item.name}</p>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='left'>
                        <h2>Product price</h2>
                      </div>
                      <div className='right'>
                        <p> {item.price.toLocaleString()}</p>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='left'>
                        <h2>Product description</h2>
                      </div>
                      <div className='right'>
                        <p>{item.description}</p>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='left'>
                        <h2>Date added</h2>
                      </div>
                      <div className='right'>
                        <p>{new Date(item.updatedAt).toDateString()}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>
                {productList.docs.map((item: any, i: number) => (
                  <div
                    className='nodyx'
                    onClick={() => selectProduct(item._id)}
                    key={item._id}
                  >
                    <div className='row'>
                      <div className='left'>
                        <h2>S/N</h2>
                      </div>
                      <div className='right'>
                        <p>{1 + i}</p>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='left'>
                        <h2>Product name</h2>
                      </div>
                      <div className='right'>
                        <div className='ctd'>
                          <p>{item.name}</p>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='left'>
                        <h2>Product price</h2>
                      </div>
                      <div className='right'>
                        <p> {item.price.toLocaleString()}</p>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='left'>
                        <h2>Product description</h2>
                      </div>
                      <div className='right'>
                        <p>{item.description}</p>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='left'>
                        <h2>Date added</h2>
                      </div>
                      <div className='right'>
                        <p>{new Date(item.updatedAt).toDateString()}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
            <></>
          </div>
          <Pagination
            currentPage={currentPage}
            totalCount={productList.totalPages}
            pageSize={10}
            onPageChange={(page: any) => {
              setCurrentPage(page)
              paginationClick(page)
            }}
          />
        </>
      )}
    </div>
  )
}

export default Products
