import React from 'react'

function CheckPermissions({ requiredPermissions, children }: any) {
  const permissionList: string[] = JSON.parse(
    sessionStorage.getItem('userPermissions') || '[]'
  )

  const arePermissionsAllowed = [...requiredPermissions, '*'].some(
    (permission: any) => permissionList.includes(permission)
  )

  if (arePermissionsAllowed) {
    return <>{children}</> // Render the children if permissions are allowed
  } else {
    return null // Optionally render an alternative UI if permissions are not allowed
  }
}

export default CheckPermissions
