import React from 'react'
import Navbar from './Navbar'
import { ReactComponent as Enter } from '../../../Assests/Enter.svg'
import Zoom from 'react-reveal/Zoom'
import { useNavigate } from 'react-router-dom'

function Hero({ setShowEarlyAccess }: any) {
  const navigate = useNavigate()
  return (
    <div className='hero'>
      <div className='cover'>
        <Navbar label='hero' setShowEarlyAccess={setShowEarlyAccess} />
        <div className='hero-rest'>
          <Zoom duration={2000}>
            <div className='rest-cover'>
              <h1>The collaboration tool for procurement and finance teams</h1>
              <p className='des'>
                Manage vendors, invoicing and payments in one place
              </p>
              <div className='button'>
                <button onClick={() => setShowEarlyAccess(true)}>
                  <p>Get early access</p> <Enter />
                </button>
              </div>
            </div>
          </Zoom>
        </div>
      </div>
    </div>
  )
}

export default Hero
