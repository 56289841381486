import Image from '../../../Assests/About.png'
import Fade from 'react-reveal/Fade'

function About() {
  return (
    <div className='about' id='about'>
      <div className='cover'>
        <Fade left duration={2000}>
          <div className='left'>
            <h2>About Paytton</h2>
            <p>
              Paytton enhances collaboration and work efficiency for procurement
              and finance professionals. Streamline your source-to-pay process
              and revolutionize the way your teams work together.
            </p>
            <p>With Paytton, you can:</p>
            <ul>
              <li>Onboard new vendors in half the time</li>
              <li>Eliminate duplicate payments</li>
              <li>
                Automate invoice matching, saving you hours of manual work
              </li>
              <li>
                Track your cash flow and manage your budget more effectively
              </li>
              <li>
                Receive prompt alerts for late payments, so you can take action
                to collect
              </li>
            </ul>
            <a href='#features'>
              <button>Find out more</button>
            </a>
          </div>
        </Fade>
        <Fade right duration={2000}>
          <div className='right'>
            <img src={Image} alt='' />
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default About
