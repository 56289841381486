import React from 'react'
import './TopBar.scss'
import { FiChevronDown } from 'react-icons/fi'
import { RxHamburgerMenu } from 'react-icons/rx'
import { useGlobalContext } from '../../Context/Context'
import ImageWithFallback from '../ImageDisplay/ImageDisplay'
function TopBar() {
  const { toggleSidebar } = useGlobalContext()
  const UserDet = sessionStorage.getItem('user')
  const { businessData } = useGlobalContext()
  return (
    <div className='topbar'>
      <div className='left'>
        <div className='bars'>
          <RxHamburgerMenu onClick={toggleSidebar} />
        </div>
        <h2>
          Hello {''}
          {UserDet ? businessData.businessID.name : 'Paytton Admin'}
        </h2>
      </div>
      <div className='right'>
        <div className='img'>
          {UserDet ? (
            <ImageWithFallback
              src={businessData.businessID.profileImageURL}
              alt='Image Description'
            />
          ) : (
            ''
          )}
        </div>
        <div className='name'>
          <p>
            {UserDet
              ? `${JSON.parse(UserDet).firstName} ${
                  JSON.parse(UserDet).lastName
                }`
              : 'Super Admin'}{' '}
          </p>
        </div>
        <div className='icon'>
          <FiChevronDown />
        </div>
      </div>
    </div>
  )
}

export default TopBar
