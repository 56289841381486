import React from 'react'
import './Unauthorized.scss'
import Image from '../../Assests/Nopermisssion.png'

function Unauthorized() {
  return (
    <div className='unauthroized'>
      <img src={Image} alt='' />
    </div>
  )
}

export default Unauthorized
