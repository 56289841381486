import React, { useState } from 'react'
import './Auth.scss'
import Loader from '../../Assests/loader.gif'
import Logo from '../../Assests/2.png'
import Image from '../../Assests/Group 3465459.png'
import { ReactComponent as Enter } from '../../Assests/Enter.svg'
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import Navbar from '../Landing/components/Navbar'

type FormData = {
  email: string
  password: string
  confirmPassword: string
  firstName: string
  lastName: string
}
interface Props {
  notify: (type: string, message: string) => void
}
function AdminLogin({ notify }: Props) {
  const [showPassword, setShowPassword] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>()
  const onSubmit = async (data: FormData) => {
    setFormLoading(true)
    const payLoad = {
      email: data.email,
      password: data.password,
    }
    try {
      const baseUrl = process.env.REACT_APP_API_URL // Get the base URL from the environment variable
      const response = await axios.post(`${baseUrl}/sysadmin/login`, payLoad)
      sessionStorage.setItem('admin', JSON.stringify(response.data.data.admin))
      sessionStorage.setItem(
        'adminToken',
        response.data.data.tokens.accessToken
      )
      sessionStorage.setItem(
        'adminRefreshn',
        response.data.data.tokens.refreshToken
      )
      notify('success', 'Admin Login Successful')
      if (sessionStorage.getItem('accessToken')) {
        sessionStorage.removeItem('user')
        sessionStorage.removeItem('accessToken')
        sessionStorage.removeItem('refreshToken')
      }
      setTimeout(() => {
        window.location.replace('/admin/home')
      }, 1000)

      setFormLoading(false)
    } catch (error: any) {
      setFormLoading(false)
      notify('error', error.response.data.errors.join(','))
    }
  }
  const validatePassword = (value: string) => {
    const isValid = /^[a-zA-Z0-9-!@#$%^&*()_+\-=\[\]{}|;':",.<>\/?]+$/.test(
      value
    )
    return (
      isValid ||
      'Password should be alphanumeric and may contain special characters'
    )
  }

  return (
    <div className='auth'>
      <Navbar label={'auth'} />
      <div className='register'>
        <div className='right'>
          <img src={Image} alt='' />
        </div>
        <div className='left'>
          <div className='left-body'>
            <h2>Welcome Back Admin</h2>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='card'>
              <p>Email</p>
              <input
                type='email'
                id=''
                {...register('email', { required: true })}
              />
              {errors.email && <span>This field is required</span>}
            </div>
            <div className='cards'>
              <p> Password</p>
              <div className='input'>
                <input
                  type={showPassword ? 'text' : 'password'}
                  {...register('password', {
                    required: true,
                    minLength: 6,
                    validate: validatePassword,
                  })}
                  id=''
                />
                {showPassword ? (
                  <AiOutlineEye onClick={() => setShowPassword(false)} />
                ) : (
                  <AiOutlineEyeInvisible
                    onClick={() => setShowPassword(true)}
                  />
                )}
              </div>
              {errors.password && errors.password.type === 'required' && (
                <span>This field is required</span>
              )}
              {errors.password && errors.password.type === 'minLength' && (
                <span>Password must be at least 6 characters</span>
              )}
              {errors.password && errors.password.type === 'validate' && (
                <span>{errors.password.message}</span>
              )}
            </div>

            <div className='card'>
              {formLoading ? (
                <div className='loader'>
                  <img src={Loader} alt='' />
                </div>
              ) : (
                <button type='submit'>
                  <p>Login</p>
                  <Enter />
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AdminLogin
