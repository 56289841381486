import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.scss'
import Create from './Pages/Onboarding/Create'
import Email from './Pages/Onboarding/Email'
import Final from './Pages/Onboarding/Final'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Verificatuion from './Pages/Onboarding/Verificatuion'
import Login from './Pages/Onboarding/Login'
import Dashboard from './Pages/User/Dashboard'
import ForgetPass from './Pages/Onboarding/ForgetPass'
import RestPass from './Pages/Onboarding/ResetPass'
import AdminLogin from './Pages/Onboarding/AdminLogin'
import Admin from './Pages/Admin/Admin'
import AdminRoute from './ProtectedRoute/AdminRoute'
import UserRoute from './ProtectedRoute/UserRoute'
import Landing from './Pages/Landing/Landing'
import EarlyAccess from './Pages/Onboarding/EarlyAccess'

function App() {
  const notify = (type: string, message: string) => {
    if (type === 'info') {
      toast.info(message)
    }
    if (type === 'success') {
      toast.success(message)
    }
    if (type === 'warn') {
      toast.warn(message)
    }
    if (type === 'error') {
      toast.error(message)
    }
  }
  return (
    <BrowserRouter>
      <ToastContainer
        position='top-center'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes>
        <Route path='/' element={<Landing notify={notify} />} />
        <Route path='/login' element={<Login notify={notify} />} />
        <Route path='/create-account' element={<Create notify={notify} />} />
        <Route path='/early-access' element={<EarlyAccess notify={notify} />} />
        <Route
          path='/forgot-password'
          element={<ForgetPass notify={notify} />}
        />
        <Route
          path='/reset-password/:email/:token'
          element={<RestPass notify={notify} />}
        />
        <Route path='/email-confirmation' element={<Email />} />
        <Route
          path='/activation/:email/:token'
          element={<Verificatuion notify={notify} />}
        />
        {/* User route */}
        <Route element={<UserRoute notify={notify} />}>
          <Route
            path='/create-business/:stage'
            element={<Final notify={notify} />}
          />
          <Route path='/user/:page' element={<Dashboard notify={notify} />} />
          <Route
            path='/user/:page/:id'
            element={<Dashboard notify={notify} />}
          />
        </Route>
        {/* Admin Routes */}
        <Route path='/admin/login' element={<AdminLogin notify={notify} />} />
        <Route element={<AdminRoute notify={notify} />}>
          <Route path='/admin/:page' element={<Admin notify={notify} />} />
          <Route path='/admin/:page/:id' element={<Admin notify={notify} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
