import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const baseUrl = process.env.REACT_APP_API_URL

export const axiosCalls = async (
  path: string,
  method: string,
  data: any = null,
  token: any
) => {
  try {
    const response = await axios({
      method,
      url: `${baseUrl}/${path}`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    if (response) {
      return response.data
    }
  } catch (error: any) {
    if (error.message === 'timeout of 100ms exceeded') {
      toast.error('The network is slow. Please try again later.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      return { er: 'slowNetwork' }
    }
    const errorMessage = error.response ? error.response.data : error.message

    return { er: errorMessage }
  }
}
