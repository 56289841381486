import { useState, useEffect } from 'react'
import ContactComp from '../../../../Onboarding/components/ContactComp'
import Address from '../../../../Onboarding/components/Address'
import { useGlobalContext } from '../../../../../Context/Context'
import Loader from '../../../../../Assests/loader.gif'
import { FaCheckCircle, FaMinus, FaPlus, FaBars } from 'react-icons/fa'
import { axiosCalls } from '../../../../../Api/_axios'
import ImageWithFallback from '../../../../../Components/ImageDisplay/ImageDisplay'
import Button from '../../../../../Components/Elements/Button/Button'
function General({ notify, setShowSidebar, showSidebar }: any) {
  const token = sessionStorage.getItem('accessToken')
  const [notEdit, setNotEdit] = useState(true)
  const { businessData } = useGlobalContext()
  const [formState, setFormState] = useState({
    type: '',
    email: '',
    name: '',
    phone: '',
    industryType: '',
    companySize: '',
    rcNumber: '',
  })
  const [formLoading, setFormLoading] = useState(false)
  const [addressFields, setaddressFields] = useState<any>([
    {
      address: '',
      country: '',
      state: '',
      postalCode: '',
      addressType: '',
    },
  ])

  // For the Submit
  const handleSubmit = async () => {
    setFormLoading(true)
    const formData = new FormData()
    formData.append('type', 'buyer_supplier')
    formData.append('name', formState.name)
    formData.append('email', formState.email)
    formData.append('phone', formState.phone)
    formData.append('industryType', formState.industryType)
    formData.append('companySize', formState.companySize)
    formData.append('rcNumber', formState.rcNumber)
    for (let i = 0; i < addressFields.length; i++) {
      const addressObj = addressFields[i]
      formData.append('addresses', JSON.stringify(addressObj))
    }
    for (let i = 0; i < bankInfo.length; i++) {
      const addressObj = bankInfo[i]
      Object.keys(addressObj).forEach((key) => {
        if (key === '') {
          delete addressObj[key]
        }
      })
      formData.append('bankInfo', JSON.stringify(addressObj))
    }
    fetch(
      `${process.env.REACT_APP_API_URL}/business/${businessData.businessID._id}/update`,
      {
        method: 'put',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
        redirect: 'follow',
      }
    )
      .then((res) => {
        if (res.status === 201) {
          notify(
            'success',
            'Business update request submitted successfully and is under review.'
          )
          setFormLoading(false)
          setTimeout(() => {
            window.location.replace('/user/settings/general')
          }, 1000)
        } else if (res.status === 403) {
          res.json().then((data) => {})
          // setTimeout(() => {
          //   navigate('/')
          // }, 3000)
        } else {
          res.json().then((data) => {
            notify('error', data.errors.join(','))
            setFormLoading(false)
          })
        }
      })
      .catch((error) => {
        notify('error', 'An error occured please try again')
        setFormLoading(false)
      })
  }

  // For Fetching Banks
  const [bankInfo, setBankInfo] = useState<any>([
    {
      bankName: '',
      accountName: '',
      accountNumber: '',
      bankCode: '',
    },
  ])
  const [newBankCode, setNewBankCode] = useState('')
  const [loading, setLoading] = useState(true)
  const [bankList, setBankList] = useState<any>([])
  const [errorBank, setErrorBank] = useState<any>(null)
  const [bankError, setbankError] = useState('')
  const [bankNameLoading, setBankNameLoading] = useState<any>(false)

  const handleBankChange = (event: any, index: any) => {
    let data: any = [...bankInfo]
    data[index][event.target.name] = event.target.value
    setBankInfo(data)
  }
  const addBankFields = () => {
    let object = {
      bankName: '',
      accountName: '',
      accountNumber: '',
      bankCode: '',
    }
    setBankInfo([...bankInfo, object])
  }
  const removeBankFields = (index: any) => {
    let data = [...bankInfo]
    data.splice(index, 1)
    setBankInfo(data)
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axiosCalls(
          `business/banks/fetch`,
          'GET',
          null,
          token
        )
        setLoading(false)
        setBankList(result.data)
      } catch (error) {}
    }
    fetchData()
  }, [])

  const fetchBankname = async (id: string, index: any) => {
    setBankNameLoading(true)
    let BankeeCode = ''
    const thebankCode = bankInfo.find((item: any, i: any) => i === index)
    if (thebankCode.bankCode.length > 2) {
      BankeeCode = thebankCode.bankCode
    } else {
      BankeeCode = newBankCode
    }
    const findBankName = bankList.find((item: any) => item.code === BankeeCode)

    setBankInfo((prevData: any) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        bankName: findBankName.name,
      }
      return newData
    })
    const data = {
      accountNumber: id,
      bankCode: BankeeCode,
    }
    try {
      const result = await axiosCalls(
        `/business/banks/account-name`,
        'POST',
        data,
        token
      )
      if (result.success) {
        setErrorBank(false)
        setBankInfo((prevData: any) => {
          const newData = [...prevData]
          newData[index] = {
            ...newData[index],
            accountNumber: id,
            bankCode: BankeeCode,
            accountName: result.data.acccountName,
          }
          return newData
        })
      } else {
        setbankError(result.er.errors.join(','))
        setErrorBank(index)
        setBankNameLoading(false)
      }
    } catch (error: any) {}
  }
  useEffect(() => {
    setFormState((prevState: any) => ({
      ...prevState,
      name: businessData.businessID.name,
      email: businessData.businessID.email,
      phone: businessData.businessID.phone,
      industryType: businessData.businessID.industryType,
      companySize: businessData.businessID.companySize,
      rcNumber: businessData.businessID.rcNumber,
    }))
    const modifiedAddresses = businessData.businessID.addresses.map(
      (address: any) => {
        const { _id, ...rest } = address
        return rest
      }
    )
    setaddressFields(modifiedAddresses)
    const modifiedBankInfo = businessData.businessID.bankInfo.map(
      (address: any) => {
        const { _id, ...rest } = address
        return rest
      }
    )
    setBankInfo(modifiedBankInfo)
  }, [])

  const cancelChange = () => {
    setNotEdit(true)
    setFormState((prevState: any) => ({
      ...prevState,
      name: businessData.businessID.name,
      email: businessData.businessID.email,
      phone: businessData.businessID.phone,
      industryType: businessData.businessID.industryType,
      companySize: businessData.businessID.companySize,
      rcNumber: businessData.businessID.rcNumber,
    }))
    const modifiedAddresses = businessData.businessID.addresses.map(
      (address: any) => {
        const { _id, ...rest } = address
        return rest
      }
    )
    setaddressFields(modifiedAddresses)
    const modifiedBankInfo = businessData.businessID.bankInfo.map(
      (address: any) => {
        const { _id, ...rest } = address
        return rest
      }
    )
    setBankInfo(modifiedBankInfo)
  }

  return (
    <div className='general'>
      {notEdit ? (
        <div className='comp-one'>
          <div className='rowss'>
            <FaBars onClick={() => setShowSidebar(!showSidebar)} />
          </div>
          <div className='new-depart-top'>
            <div className='one'>
              <h2>Company Info</h2>
            </div>
            <Button
              content='Edit'
              light
              onClick={() => {
                setNotEdit(false)
              }}
            />
          </div>
          <div className='info-page'>
            <div className='card'>
              <h4>Company Name</h4>
              <p>{formState.name}</p>
            </div>
            <div className='card'>
              <h4>Company Email</h4>
              <p>{formState.email}</p>
            </div>
            <div className='card'>
              <h4>Telephone</h4>
              <p>{formState.phone}</p>
            </div>
            <div className='card'>
              <h4>RC Number</h4>
              <p>{formState.rcNumber}</p>
            </div>
            <div className='card long'>
              <h4>Industry Type</h4>
              <p>{formState.industryType}</p>
            </div>
            <div className='card long'>
              <h4>Company Size</h4>
              <p>{formState.companySize}</p>
            </div>
          </div>
          <div className='new-depart-top'>
            <div className='one'>
              <h2>Company Bank Details</h2>
            </div>
          </div>
          <div className='info-page'>
            {bankInfo.map((form: any, index: any) => (
              <>
                <div className='card'>
                  <h4>Bank Name</h4>
                  <p>{form.bankName}</p>
                </div>
                <div className='card'>
                  <h4>Account Number</h4>
                  <p>{form.accountNumber}</p>
                </div>
                <div className='card'>
                  <h4>Account Name</h4>
                  <p>{form.accountName}</p>
                </div>
              </>
            ))}
          </div>
          <div className='new-depart-top'>
            <div className='one'>
              <h2>Company Address</h2>
            </div>
          </div>
          <div className='info-page'>
            {addressFields.map((form: any, index: any) => (
              <>
                <div className='card'>
                  <h4>Company Address</h4>
                  <p>{form.address}</p>
                </div>
                <div className='card'>
                  <h4>Country</h4>
                  <p className='cap'>{form.country}</p>
                </div>
                <div className='card'>
                  <h4>State</h4>
                  <p>{form.state}</p>
                </div>
                <div className='card'>
                  <h4>Postal Code</h4>
                  <p>{form.postalCode}</p>
                </div>
                <div className='card'>
                  <h4>Address Type</h4>
                  <p>{form.addressType}</p>
                </div>
              </>
            ))}
          </div>
        </div>
      ) : (
        <>
          <div className='rowss'>
            <FaBars onClick={() => setShowSidebar(!showSidebar)} />
          </div>
          <ContactComp
            setFormState={setFormState}
            formState={formState}
            emptyFields={[]}
            disabled={notEdit}
          />
          <Address
            addressFields={addressFields}
            setaddressFields={setaddressFields}
            emptyFields={[]}
            disabled={notEdit}
          />
          <div className='form-body seti'>
            <div className='tag'>
              <p>Bank Account details</p>
            </div>
            {bankInfo.map((form: any, index: any) => (
              <>
                <>
                  <div className='card'>
                    <p>Bank Name</p>
                    <select
                      disabled={notEdit}
                      name=''
                      onChange={(e) => {
                        handleBankChange(e, index)
                        setNewBankCode(e.target.value)
                        setBankNameLoading(false)
                        setErrorBank(null)
                      }}
                      defaultValue={form.bankCode}
                    >
                      {form.bankCode.length > 0 ? (
                        <option value={form.bankName}>{form.bankName}</option>
                      ) : (
                        <option value=''>-Select-</option>
                      )}
                      {bankList.map((item: any) => (
                        <option value={item.code} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='card'>
                    <p>Account Number</p>
                    <input
                      type='tel'
                      name=''
                      onChange={(e) => {
                        handleBankChange(e, index)
                        if (e.target.value.length === 10) {
                          fetchBankname(e.target.value, index)
                        }
                      }}
                      disabled={bankNameLoading || notEdit}
                      defaultValue={form.accountNumber}
                    />
                    {errorBank === index ? (
                      <h6 style={{ color: 'red' }} className='error'>
                        {bankError}
                      </h6>
                    ) : (
                      errorBank === false &&
                      form.accountNumber.length === 10 && (
                        <FaCheckCircle
                          className='check'
                          style={{ color: 'green' }}
                        />
                      )
                    )}
                  </div>
                  <div className='card'>
                    <p>Account Name</p>
                    <input
                      type='text'
                      name=''
                      disabled
                      value={form.accountName}
                    />
                  </div>
                </>
                {!notEdit && (
                  <div className='anothes'>
                    <div className='remove'>
                      <FaMinus onClick={() => removeBankFields(index)} />
                      <p onClick={() => removeBankFields(index)}>Remove</p>
                    </div>
                    <div className='addx'>
                      <FaPlus onClick={addBankFields} />
                      <p onClick={addBankFields}>Add another</p>
                    </div>
                  </div>
                )}
              </>
            ))}
            {bankInfo.length === 0 && (
              <>
                {!notEdit && (
                  <div className='anothes'>
                    <div className='remove'></div>
                    <div className='addx'>
                      <FaPlus onClick={addBankFields} />
                      <p onClick={addBankFields}>Add another</p>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div className='buttons'>
            {formLoading ? (
              <div>
                <img src={Loader} alt='' style={{ width: '100px' }} />
              </div>
            ) : (
              <>
                <button onClick={() => cancelChange()}>
                  <p>Cancel changes</p>
                </button>
                <button
                  onClick={() => {
                    handleSubmit()
                  }}
                >
                  <p>Save Changes</p>
                </button>
              </>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default General
