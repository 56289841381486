import React, { useState } from 'react'
import './Auth.scss'
import Loader from '../../Assests/loader.gif'
import Logo from '../../Assests/2.png'
import Image from '../../Assests/Group 3465459.png'
import { ReactComponent as Enter } from '../../Assests/Enter.svg'
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import Navbar from '../Landing/components/Navbar'

type FormData = {
  email: string
  password: string
  confirmPassword: string
  firstName: string
  lastName: string
}
interface Props {
  notify: (type: string, message: string) => void
}
function Create({ notify }: Props) {
  const [showPassword, setShowPassword] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false)
  const navigate = useNavigate()
  const [formLoading, setFormLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<FormData>()
  const onSubmit = async (data: FormData) => {
    setFormLoading(true)
    const payLoad = {
      email: data.email.toLocaleLowerCase(),
      password: data.password,
      firstName: data.firstName,
      lastName: data.lastName,
    }
    try {
      const baseUrl = process.env.REACT_APP_API_URL // Get the base URL from the environment variable
      const response = await axios.post(`${baseUrl}/auth/user/create`, payLoad)
      notify('success', response.data.message)
      setFormLoading(false)
      setTimeout(() => {
        navigate(
          `/email-confirmation?search=${encodeURIComponent('create-account')}`,
          {
            state: { stage: 'User' },
          }
        )
      }, 1000)
    } catch (error: any) {
      setFormLoading(false)
      notify('error', error.response.data.errors.join(','))
    }
  }
  const validatePassword = (value: string) => {
    const isValid = /^[a-zA-Z0-9-!@#$%^&*()_+\-=\[\]{}|;':",.<>\/?]+$/.test(
      value
    )
    return (
      isValid ||
      'Password should be alphanumeric and may contain special characters'
    )
  }

  return (
    <div className='auth'>
      <Navbar label={'auth'} />
      <div className='register'>
        <div className='right'>
          <img src={Image} alt='' />
        </div>
        <div className='left dif'>
          <div className='left-body'>
            <h2>Create account</h2>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='card'>
              <p>First Name</p>
              <input
                type='text'
                {...register('firstName', { required: true })}
                id=''
              />
              {errors.firstName && <span>This field is required</span>}
            </div>
            <div className='card'>
              <p>Last Name</p>
              <input
                type='text'
                {...register('lastName', { required: true })}
                id=''
              />
              {errors.lastName && <span>This field is required</span>}
            </div>
            <div className='card'>
              <p>Email</p>
              <input
                type='email'
                id=''
                {...register('email', { required: true })}
              />
              {errors.email && <span>This field is required</span>}
            </div>
            <div className='cards'>
              <p> Password</p>
              <div className='input'>
                <input
                  type={showPassword ? 'text' : 'password'}
                  {...register('password', {
                    required: true,
                    minLength: 6,
                    validate: validatePassword,
                  })}
                  id=''
                />
                {showPassword ? (
                  <AiOutlineEye onClick={() => setShowPassword(false)} />
                ) : (
                  <AiOutlineEyeInvisible
                    onClick={() => setShowPassword(true)}
                  />
                )}
              </div>
              {errors.password && errors.password.type === 'required' && (
                <span>This field is required</span>
              )}
              {errors.password && errors.password.type === 'minLength' && (
                <span>Password must be at least 6 characters</span>
              )}
              {errors.password && errors.password.type === 'validate' && (
                <span>{errors.password.message}</span>
              )}
            </div>
            <div className='cards'>
              <p> Confirm Password</p>
              <div className='input'>
                <input
                  type={showPassword2 ? 'text' : 'password'}
                  onPaste={(e) => e.preventDefault()}
                  {...register('confirmPassword', {
                    required: true,
                    validate: (value) =>
                      value === getValues('password') ||
                      'Passwords do not match',
                  })}
                  id=''
                />
                {showPassword2 ? (
                  <AiOutlineEye onClick={() => setShowPassword2(false)} />
                ) : (
                  <AiOutlineEyeInvisible
                    onClick={() => setShowPassword2(true)}
                  />
                )}
              </div>
              {errors.confirmPassword &&
                errors.confirmPassword.type === 'required' && (
                  <span>This field is required</span>
                )}
              {errors.confirmPassword &&
                errors.confirmPassword.type === 'validate' && (
                  <span>{errors.confirmPassword.message}</span>
                )}
            </div>
            <div className='card'>
              {formLoading ? (
                <div className='loader'>
                  <img src={Loader} alt='' />
                </div>
              ) : (
                <button type='submit'>
                  <p>Create Account</p>
                  <Enter />
                </button>
              )}
            </div>
          </form>
          <div className='left-tag' onClick={() => navigate('/login')}>
            <p>Login if you have an account already</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Create
