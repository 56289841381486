import React, { useState, useEffect } from 'react'
import { FaTimes } from 'react-icons/fa'
import Loader from '../../../../../Assests/loader.gif'
import axios from 'axios'
import { useGlobalContext } from '../../../../../Context/Context'

function EditProduct({ setEditProduct, notify, selectedProduct }: any) {
  const authToken = sessionStorage.getItem('accessToken')
  const { businessData } = useGlobalContext()
  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [price, setPrice] = useState('')
  const [currency, setCurrency] = useState('')

  const handleSubmit = (e: any) => {
    e.preventDefault()
    setIsLoading(true)
    const data = {
      name,
      description,
      price,
      currency,
    }
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/product/${businessData.businessID._id}/update/${selectedProduct._id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((response) => {
        notify('success', response.data.message)
        setIsLoading(false)
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      })
      .catch((error) => {
        notify('error', error.message)
        setIsLoading(false)
      })
  }
  const deleteProduct = () => {
    setIsLoading(true)
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/product/${businessData.businessID._id}/delete/${selectedProduct._id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((response) => {
        notify('success', response.data.message)
        setIsLoading(false)
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      })
      .catch((error) => {
        notify('error', error.message)
        setIsLoading(false)
      })
  }
  useEffect(() => {
    setName(selectedProduct.name)
    setCurrency(selectedProduct.currency)
    setPrice(selectedProduct.price)
    setDescription(selectedProduct.description)
  }, [selectedProduct])
  const handleWheel = (event: any) => {
    event.target.blur()
  }
  return (
    <div className='modal-body'>
      <div className='modal'>
        <div className='close'>
          <FaTimes onClick={() => setEditProduct(false)} />
        </div>
        <div className='tag'>
          <p>Edit Product</p>
        </div>
        <div className='form'>
          <form onSubmit={handleSubmit}>
            <div className='card'>
              <p>Product name</p>
              <input
                type='text'
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className='card'>
              <p>Product description</p>
              <input
                type='text'
                required
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className='card'>
              <p>Product Currency</p>
              <select
                name=''
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
              >
                <option value=''>Select</option>
                <option value='NGN'>NGN</option>
                <option value='USD'>USD</option>
              </select>
            </div>
            <div className='card'>
              <p>Product price</p>
              <input
                type='number'
                step='0.01'
                onWheel={handleWheel}
                min={0}
                required
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>

            {isLoading ? (
              <div className='buttons'>
                <img src={Loader} alt='Loader' />
              </div>
            ) : (
              <div className='buttons'>
                <button type='submit'>Edit product</button>
                <button
                  onClick={(e) => {
                    e.preventDefault()
                    deleteProduct()
                  }}
                >
                  Delete Product
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default EditProduct
