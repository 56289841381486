import React, { useState, useEffect, useRef } from 'react'
import './CreateInvoice.scss'
import TopBar from '../../../../Components/TopBar/TopBar'
import {
  FaCaretDown,
  FaCheck,
  FaChevronRight,
  FaPlus,
  FaTimes,
} from 'react-icons/fa'
import { TbShieldCheck } from 'react-icons/tb'
import { RiDeleteBin6Line } from 'react-icons/ri'
import Select from 'react-select'
import AddVendor from '../Vendors/Components/AddVendor'
import AddProduct from '../Products/Component/AddProduct'
import axios from 'axios'
import { useGlobalContext } from '../../../../Context/Context'
import Loader from '../../../../Assests/loader.gif'
import Button from '../../../../Components/Elements/Button/Button'
import StateData from '../../../../Data/StateData'

function CreateInvoice({ notify }: any) {
  const { businessData, handleScrollToTop } = useGlobalContext()
  const authToken = sessionStorage.getItem('accessToken')
  const [isLoading, setIsLoading] = useState(false)
  const [vendorList, setVendorList] = useState([])
  const [productList, setProductList] = useState<any>([])
  const [addVendor, setAddVendor] = useState(false)
  const [addProduct, setAddProduct] = useState(false)
  const [vendorsLoading, setVendorsLoading] = useState(true)
  const [productsLoading, setProductsLoading] = useState(true)
  const [invoicePage, setInvoicePage] = useState('create')
  //form details
  const [address, setAddress] = useState('')
  const [state, setState] = useState('')
  const [country, setCountry] = useState('')
  const [invoiceDate, setInvoiceDate] = useState(
    new Date().toISOString().slice(0, 10)
  )
  const [dueDate, setDueDate] = useState('')
  const [invoiceCurrency, setInvoiceCurrency] = useState('NGN')
  const [showCurrency, setShowCurrency] = useState(false)
  const [discountType, setDiscountType] = useState('value')
  const [taxType, setTaxType] = useState('value')
  const [discount, setDiscount] = useState(0)
  const [tax, setTax] = useState(0)
  const [shortNote, setShortNote] = useState('')
  const [selectedProduct, setSelectedProduct] = useState<any>(null)
  const [selectedProductList, setselectedProductList] = useState<any>([])
  const [finaleAmount, setFinaleAmount] = useState<any>(0)
  const [selectedVedor, setSelectedVedor] = useState<any>([])
  const [number, setNumber] = useState('')
  const tabRef = useRef<HTMLDivElement>(null)
  const [notRegistered, setNotRegistered] = useState(false)

  //Fetching Vendors
  const fetchVendorsList = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/business/${businessData.businessID._id}/connection/fetch?limit=1000`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      if (res.status === 200) {
        const transformedOptions = res.data.data.docs
          .filter((item: any) => item.type !== 'supplier')
          .filter((item: any) => item.vendorID !== null)
          .map((option: any) => ({
            label: option.vendorName,
            value: option._id,
            ...option,
          }))
        setVendorList(transformedOptions)
        setVendorsLoading(false)
      }
    } catch (error) {}
  }
  const fetchProductList = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/product/${businessData.businessID._id}/fetch?limit=1000`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      if (res.status === 200) {
        const transformedOptions = res.data.data.docs.map((option: any) => ({
          label: option.name,
          value: option._id,
          ...option,
        }))
        setProductList(transformedOptions)
        setProductsLoading(false)
      }
    } catch (error) {}
  }
  useEffect(() => {
    fetchVendorsList()
    fetchProductList()
    if (businessData.businessID.lastInvoiceNumber.length > 1) {
      setNumber(`Invoice#0000${businessData.businessID.lastInvoiceNumber + 1}`)
    } else if (businessData.businessID.lastInvoiceNumber === 0) {
      setNumber(`Invoice#000001`)
    } else {
      setNumber(`Invoice#00000${businessData.businessID.lastInvoiceNumber + 1}`)
    }
  }, [])
  const prefillVendor = (e: any) => {
    setSelectedVedor(e)
    if (e.active) {
      setAddress(e.vendorID.addresses[0].address)
      setState(e.vendorID.addresses[0].state)
      setCountry(e.vendorID.addresses[0].country)
      setNotRegistered(false)
    } else {
      setAddress('')
      setState('')
      setCountry('')
      setNotRegistered(true)
    }
  }
  const handleAddProduct = (value: any) => {
    if (value) {
      const existingProduct = selectedProductList.find(
        (product: any) => product.product.value === value.value
      )
      if (existingProduct) {
        if (!existingProduct.quantity) {
          existingProduct.quantity = 1
        } else {
          existingProduct.quantity++
        }
        setselectedProductList((prevList: any) => [...prevList])
      } else {
        const newProduct = {
          product: value,
          quantity: 1,
        }
        setselectedProductList((prevList: any) => [...prevList, newProduct])
      }
    }
  }
  const handleRemoveProduct = (productToRemove: any) => {
    setselectedProductList((prevList: any) =>
      prevList.filter(
        (product: any) => product.product.value !== productToRemove.value
      )
    )
  }
  const calculateAmount = (price: number, quantity: number) => {
    return price * quantity
  }
  const editProductQuantity = (productID: string, newQuantity: number) => {
    setselectedProductList((prevProducts: any) => {
      return prevProducts.map((item: any) => {
        if (item.product._id === productID) {
          return { ...item, quantity: newQuantity }
        }
        return item
      })
    })
  }
  const getTotalAmount = () => {
    let total = 0
    selectedProductList.forEach((product: any) => {
      total += calculateAmount(product.product.price, product.quantity)
    })
    return total
  }
  useEffect(() => {
    let disam = 0
    let taxam = 0
    if (discountType === 'percentage') {
      disam = (discount / 100) * getTotalAmount()
    } else {
      disam = discount
    }
    if (taxType === 'percentage') {
      taxam = (tax / 100) * getTotalAmount()
    } else {
      taxam = tax
    }
    const amountandDiscount = getTotalAmount() - disam
    setFinaleAmount(amountandDiscount + taxam)
  }, [getTotalAmount])

  const handleSubmit = (status: boolean) => {
    const transformedOptions = selectedProductList.map((option: any) => ({
      product: option.product._id,
      quantity: option.quantity,
    }))
    const url = `${process.env.REACT_APP_API_URL}/invoice/${businessData.businessID._id}/create?draft=${status}`

    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }

    if (Object.keys(selectedVedor).length === 0) {
      notify('error', 'Please select a vendor.')
    } else if (address.length < 5) {
      notify('error', 'Please provide a valid address.')
    } else if (country.length < 5) {
      notify('error', 'Please provide a valid country.')
    } else if (state.length < 5) {
      notify('error', 'Please provide a valid state.')
    } else if (invoiceDate.length < 5) {
      notify('error', 'Please provide a valid invoice date.')
    } else if (dueDate.length < 5) {
      notify('error', 'Please provide a valid due date.')
    } else if (transformedOptions.length < 1) {
      notify('error', 'Please provide at least 1 product.')
    } else if (invoiceCurrency.length < 2) {
      notify('error', 'Please provide a valid invoice currency.')
    } else {
      setIsLoading(true)
      const data = {
        number: number,
        sellerID: selectedVedor.vendorID._id,
        address: address,
        country: country,
        state: state,
        date: invoiceDate,
        requiredEnd: dueDate,
        products: transformedOptions,
        note: shortNote,
        bankName: businessData.businessID.bankInfo[0].bankName,
        bankAccountName: businessData.businessID.bankInfo[0].accountName,
        bankAccountNumber: businessData.businessID.bankInfo[0].accountNumber,
        currency: invoiceCurrency,
        tax: {
          value: tax,
          type: taxType,
        },
        discount: {
          value: discount,
          type: discountType,
        },
      }
      axios
        .post(url, data, config)
        .then((response) => {
          setIsLoading(false)
          notify('success', response.data.message)
          setTimeout(() => {
            window.location.replace('/user/invoices')
          }, 3000)
        })
        .catch((error) => {
          setIsLoading(false)
          notify('error', error.response.data.errors.join(','))
          // Handle the error as needed
        })
    }
  }
  const handlePreview = () => {
    const transformedOptions = selectedProductList.map((option: any) => ({
      product: option.product._id,
      quantity: option.quantity,
    }))

    if (Object.keys(selectedVedor).length === 0) {
      notify('error', 'Please select a vendor.')
    } else if (address.length < 5) {
      notify('error', 'Please provide a valid address.')
    } else if (country.length < 5) {
      notify('error', 'Please provide a valid country.')
    } else if (state.length < 5) {
      notify('error', 'Please provide a valid state.')
    } else if (invoiceDate.length < 5) {
      notify('error', 'Please provide a valid invoice date.')
    } else if (dueDate.length < 5) {
      notify('error', 'Please provide a valid due date.')
    } else if (transformedOptions.length < 1) {
      notify('error', 'Please provide at least 1 product.')
    } else if (invoiceCurrency.length < 2) {
      notify('error', 'Please provide a valid invoice currency.')
    } else {
      setInvoicePage('preview')
      handleScrollToTop()
    }
  }
  const handleWheel = (event: any) => {
    event.target.blur()
  }
  const handleDiscountChange = (e: any) => {
    const value = e.target.value
    // Check if the user agent is Firefox or Safari
    const isFirefoxOrSafari = /Firefox|Safari/.test(navigator.userAgent)
    // If it's Firefox or Safari, handle the input differently
    if (isFirefoxOrSafari) {
      // Check if the value is empty or just a decimal point
      if (value === '' || value === '.') {
        setDiscount(value)
      } else {
        // Convert the value to a number and update the state
        setDiscount(parseFloat(value))
      }
    } else {
      // For other browsers, handle the input normally
      setDiscount(parseFloat(value))
    }
  }
  const handleTaxChange = (e: any) => {
    const value = e.target.value
    // Check if the user agent is Firefox or Safari
    const isFirefoxOrSafari = /Firefox|Safari/.test(navigator.userAgent)
    // If it's Firefox or Safari, handle the input differently
    if (isFirefoxOrSafari) {
      // Check if the value is empty or just a decimal point
      if (value === '' || value === '.') {
        setTax(value)
      } else {
        // Convert the value to a number and update the state
        setTax(parseFloat(value))
      }
    } else {
      // For other browsers, handle the input normally
      setTax(parseFloat(value))
    }
  }
  const currencySelect = (currency: string) => {
    setInvoiceCurrency(currency)
    setShowCurrency(false)
  }
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (tabRef.current && !tabRef.current.contains(event.target as Node)) {
        setShowCurrency(false)
      }
    }

    if (showCurrency) {
      document.addEventListener('mousedown', handleOutsideClick)
    } else {
      document.removeEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [showCurrency])

  return (
    <div className='createInvoice'>
      {addVendor && <AddVendor setAddVendor={setAddVendor} notify={notify} />}
      {addProduct && (
        <AddProduct
          notify={notify}
          setAddProduct={setAddProduct}
          page='invoice'
          fetchProductList={fetchProductList}
        />
      )}
      <TopBar />
      <div className='head-wrap'>
        <div className='head'>
          <div className='left'></div>
          {isLoading ? (
            <div className='loaders'>
              <img src={Loader} alt='' />
            </div>
          ) : (
            <div className='right'>
              {invoicePage === 'create' ? (
                <>
                  <Button
                    onClick={() => handlePreview()}
                    content='Preview'
                    outlined
                  />
                </>
              ) : (
                <>
                  <Button
                    onClick={() => setInvoicePage('create')}
                    outlined
                    content='Edit'
                  />
                  <Button
                    onClick={() => handleSubmit(false)}
                    content='Send for approval'
                  />
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {invoicePage === 'create' ? (
        <div className='create'>
          <div className='wrap'>
            <div className='wrap-head'>
              <div className='wrap-left'>
                <div className='card'>
                  <p>Select Vendor</p>
                  <Select
                    className='basic-single'
                    classNamePrefix='select'
                    isLoading={vendorsLoading}
                    isClearable
                    isSearchable={true}
                    name='color'
                    options={vendorList}
                    onChange={(value: any) => {
                      prefillVendor(value)
                    }}
                    value={selectedVedor}
                  />
                  {/* <div className='butt' onClick={() => setAddVendor(true)}>
                    <FaPlus className='add' />
                    <p>Add Vendor</p>
                  </div> */}
                </div>
                {notRegistered && (
                  <>
                    <div className='cardX'>
                      <p>Vendor Address</p>
                      <input
                        type='text'
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                    <div className='cardX'>
                      <p>Vendor State</p>
                      <select
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                      >
                        <option value=''>-Select-</option>
                        {StateData.map((item) => (
                          <option value={item.state.name} key={item.state.name}>
                            {item.state.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='cardX'>
                      <p>Vendor Country</p>
                      <select
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                      >
                        <option value=''>-Select-</option>
                        <option value='nigeria'>Nigeria</option>
                      </select>
                    </div>
                  </>
                )}
              </div>
              <div className='wrap-right'>
                <div className='card'>
                  <p>Invoice Number</p>
                  <input
                    type='text'
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </div>
                <div className='card'>
                  <p>Invoice Date</p>
                  <input
                    type='date'
                    value={invoiceDate}
                    onChange={(e) => setInvoiceDate(e.target.value)}
                  />
                </div>
                <div className='card'>
                  <p>Due Date</p>
                  <input
                    type='date'
                    value={dueDate}
                    onChange={(e) => setDueDate(e.target.value)}
                  />
                </div>
                <div className='cards'>
                  <p>Discount</p>
                  <div className='flex'>
                    <select
                      value={discountType}
                      onChange={(e) => setDiscountType(e.target.value)}
                    >
                      <option value='percentage'>Percentage</option>
                      <option value='value'>Value</option>
                    </select>
                    <input
                      type='number'
                      step='0.01'
                      value={discount}
                      onWheel={handleWheel}
                      min={0}
                      onChange={handleDiscountChange}
                    />
                  </div>
                </div>
                <div className='cards'>
                  <p>Tax</p>
                  <div className='flex'>
                    <select
                      value={taxType}
                      onChange={(e) => setTaxType(e.target.value)}
                    >
                      <option value='percentage'>Percentage</option>
                      <option value='value'>Value</option>
                    </select>
                    <input
                      type='number'
                      step='0.01'
                      value={tax}
                      onWheel={handleWheel}
                      min={0}
                      onChange={handleTaxChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='bodyX'>
              <div className='products'>
                <table>
                  <thead>
                    <tr>
                      <th className='name'>Product</th>
                      <th>Description</th>
                      <th className='qty'>Unit price</th>
                      <th className='qty'>QTY</th>
                      <th className='qty'>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedProductList.map((item: any, i: number) => (
                      <tr key={i + 1}>
                        <td className='name'>{item.product.name}</td>
                        <td>{item.product.description}</td>
                        <td className='qty'>
                          {item.product.price.toLocaleString()}
                        </td>
                        <td className='qty'>
                          <input
                            type='number'
                            value={item.quantity}
                            onWheel={handleWheel}
                            min={0}
                            onChange={(e) => {
                              editProductQuantity(
                                item.product._id,
                                parseInt(e.target.value)
                              )
                            }}
                          />
                        </td>
                        <td className='qty'>
                          {(
                            item.product.price * item.quantity
                          ).toLocaleString()}
                        </td>
                        <td className='hide'>
                          <RiDeleteBin6Line
                            onClick={() => handleRemoveProduct(item.product)}
                            className='decline'
                          />
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td className='name'>
                        <Select
                          className='basic-single'
                          classNamePrefix='select'
                          isLoading={productsLoading}
                          isClearable
                          isSearchable={true}
                          name='color'
                          options={productList}
                          noOptionsMessage={() =>
                            `Can't find the product? Add a product below.`
                          }
                          onChange={(value: any) => {
                            handleAddProduct(value)
                          }}
                          value={selectedProduct}
                        />
                      </td>
                      <td></td>
                      <td></td>
                      <td className='qty'></td>
                      <td></td>
                      <td className='hide'></td>
                    </tr>
                    <tr>
                      <td className='buttonx name'>
                        <Button
                          content='Add More Product'
                          onClick={() => setAddProduct(true)}
                        />
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className='hide'></td>
                    </tr>
                  </tbody>
                </table>
                <div className='mobile'>
                  {selectedProductList.map((item: any, i: number) => (
                    <div className='card' key={i + 1}>
                      <div className='row'>
                        <div className='one'>
                          <h3>Product</h3>
                        </div>
                        <div className='two'>
                          <p>{item.product.name}</p>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='one'>
                          <h3>Description</h3>
                        </div>
                        <div className='two'>
                          <p>{item.product.description}</p>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='one'>
                          <h3>Unit Price</h3>
                        </div>
                        <div className='two'>
                          <p>{item.product.price.toLocaleString()}</p>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='one'>
                          <h3>Quantity</h3>
                        </div>
                        <div className='two'>
                          <p>
                            <input
                              type='number'
                              value={item.quantity}
                              onWheel={handleWheel}
                              min={0}
                              onChange={(e) =>
                                editProductQuantity(
                                  item.product._id,
                                  parseInt(e.target.value)
                                )
                              }
                            />
                          </p>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='one'>
                          <h3>Amount </h3>
                        </div>
                        <div className='two'>
                          <p>
                            {(
                              item.product.price * item.quantity
                            ).toLocaleString()}
                          </p>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='one'></div>
                        <div className='two'>
                          <RiDeleteBin6Line
                            onClick={() => handleRemoveProduct(item.product)}
                            className='decline'
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className='floex'>
                  <div className='card'>
                    <p>Select Product</p>
                    <Select
                      className='basic-single'
                      classNamePrefix='select'
                      isLoading={productsLoading}
                      isClearable
                      isSearchable={true}
                      name='color'
                      options={productList}
                      noOptionsMessage={() =>
                        `Can't find the product? Add a product below.`
                      }
                      onChange={(value: any) => {
                        handleAddProduct(value)
                      }}
                      value={selectedProduct}
                    />
                    <Button
                      content='Add More Product'
                      onClick={() => setAddProduct(true)}
                    />
                  </div>
                </div>
              </div>
              <div className='totals'>
                <div className='curr'>
                  <div className='curr-wrap' ref={tabRef}>
                    <div className='curr-head'>
                      <p>{invoiceCurrency}</p>
                      <FaCaretDown onClick={() => setShowCurrency(true)} />
                    </div>
                    {showCurrency && (
                      <div className='curr-list'>
                        <ul>
                          <li onClick={() => currencySelect('NGN')}>NGN</li>
                          <li onClick={() => currencySelect('USD')}>USD</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className='table-v' onClick={() => setShowCurrency(false)}>
                  <table>
                    <tbody>
                      <tr>
                        <td className='lead'>SUBTOTAL</td>
                        <td>
                          <b>
                            {invoiceCurrency}{' '}
                            {getTotalAmount().toLocaleString()}
                          </b>
                        </td>
                      </tr>
                      {discount > 0 && (
                        <tr>
                          <td className='lead'>Discount</td>
                          <td>
                            {invoiceCurrency}
                            {discountType === 'percentage'
                              ? (
                                  (discount / 100) *
                                  getTotalAmount()
                                ).toLocaleString()
                              : discount.toLocaleString()}
                          </td>
                        </tr>
                      )}
                      {tax > 0 && (
                        <tr>
                          <td className='lead'>Tax</td>
                          <td>
                            {invoiceCurrency}
                            {taxType === 'percentage'
                              ? (
                                  (tax / 100) *
                                  getTotalAmount()
                                ).toLocaleString()
                              : tax.toLocaleString()}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className='tot'>
                  <p>TOTAL AMOUNT</p>
                  <h2>
                    {invoiceCurrency}{' '}
                    {finaleAmount
                      ? JSON.parse(finaleAmount).toLocaleString()
                      : 0}
                  </h2>
                </div>
              </div>
              <div className='bankDet'>
                <h2>BANK DETAILS </h2>
                <p>
                  Bank Name :
                  <> {businessData.businessID.bankInfo[0].bankName}</>
                </p>
                <p>
                  Account Name :{' '}
                  <> {businessData.businessID.bankInfo[0].accountName}</>
                </p>
                <p>
                  Account Number :{' '}
                  <> {businessData.businessID.bankInfo[0].accountNumber}</>
                </p>
              </div>
              <div className='note'>
                <p>Leave a note </p>
                <textarea
                  rows={4}
                  value={shortNote}
                  onChange={(e) => setShortNote(e.target.value)}
                ></textarea>
              </div>
              <div className='flex-end'>
                {' '}
                {isLoading ? (
                  <div className='loaders'>
                    <img src={Loader} alt='' />
                  </div>
                ) : (
                  <div className='right'>
                    {invoicePage === 'create' ? (
                      <>
                        <Button
                          onClick={() => handlePreview()}
                          content='Preview'
                          outlined
                        />
                      </>
                    ) : (
                      <>
                        <Button
                          onClick={() => setInvoicePage('create')}
                          outlined
                          content='Edit'
                        />
                        <Button
                          onClick={() => handleSubmit(false)}
                          content='Send for approval'
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='preview'>
          <div className='preview-wrap'>
            <div className='vendor-info logo'>
              <div className='left'>
                <img src={businessData.businessID.profileImageURL} alt='' />
              </div>
              <div className='right'>
                <h2>INVOICE</h2>
                <div className='tag'>
                  <h3>{businessData.businessID.name}</h3>
                  <TbShieldCheck />
                </div>
                <p>
                  {businessData.businessID.addresses[0].address},
                  {businessData.businessID.addresses[0].state}{' '}
                </p>
                <p>{businessData.businessID.email}</p>
                <p>{businessData.businessID.phone}</p>
              </div>
            </div>
            <div className='vendor-info'>
              <div className='left'>
                <div className='two'>
                  <h2>INVOICE To :</h2>
                  <div className='tag'>
                    <h2>{selectedVedor.vendorName}</h2>
                    <TbShieldCheck />
                  </div>
                  <p>
                    {address},{state}{' '}
                  </p>
                  <p>{selectedVedor.vendorID.email}</p>
                  <p>{selectedVedor.vendorID.phone}</p>
                </div>
              </div>
              <div className='right'>
                <h2>INVOICE DETAILS</h2>
                <p>Invoice date : {invoiceDate}</p>
                <p>Invoice due date : {dueDate}</p>
              </div>
            </div>
            <div className='vendor-table'>
              <table>
                <thead>
                  <tr>
                    <th className='name'>Product Name</th>
                    <th>Description</th>
                    <th className='prcice'>Unit Price</th>
                    <th>Qty</th>
                    <th className='prcice'>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedProductList.map((item: any, i: number) => (
                    <tr key={i + 1}>
                      <td className='name'>{item.product.name}</td>
                      <td>{item.product.description}</td>
                      <td>{item.product.price.toLocaleString()}</td>
                      <td className='prcice'>{item.quantity}</td>
                      <td className='prcice'>
                        {(item.product.price * item.quantity).toLocaleString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className='mobile'>
                {selectedProductList.map((item: any, i: number) => (
                  <div className='card' key={i + 1}>
                    <div className='row'>
                      <div className='one'>
                        <h3>Product name</h3>
                      </div>
                      <div className='two'>
                        <p>{item.product.name}</p>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='one'>
                        <h3>Unit Price</h3>
                      </div>
                      <div className='two'>
                        <p>{item.product.price.toLocaleString()}</p>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='one'>
                        <h3>Qty</h3>
                      </div>
                      <div className='two'>
                        <p>{item.quantity}</p>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='one'>
                        <h3>Amount </h3>
                      </div>
                      <div className='two'>
                        <p>
                          {(
                            item.product.price * item.quantity
                          ).toLocaleString()}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className='totals'>
                <div className='curr'>
                  <button> {invoiceCurrency}</button>
                </div>
                <div className='table-v'>
                  <table>
                    <tr>
                      <td className='lead'>SUBTOTAL</td>
                      <td>
                        <b>
                          {invoiceCurrency} {getTotalAmount().toLocaleString()}
                        </b>
                      </td>
                    </tr>
                    {discount > 0 && (
                      <tr>
                        <td className='lead'>Discount</td>
                        <td>
                          {invoiceCurrency}
                          {discountType === 'percentage'
                            ? (
                                (discount / 100) *
                                getTotalAmount()
                              ).toLocaleString()
                            : discount.toLocaleString()}
                        </td>
                      </tr>
                    )}
                    {tax > 0 && (
                      <tr>
                        <td className='lead'>Tax</td>
                        <td>
                          {invoiceCurrency}
                          {taxType === 'percentage'
                            ? ((tax / 100) * getTotalAmount()).toLocaleString()
                            : tax.toLocaleString()}
                        </td>
                      </tr>
                    )}
                  </table>
                </div>
                <div className='tot'>
                  <p>TOTAL AMOUNT</p>
                  <h2>
                    {invoiceCurrency}{' '}
                    {JSON.parse(finaleAmount).toLocaleString()}
                  </h2>
                </div>
              </div>
              <div className='bankDet'>
                <h2>Bank details </h2>
                <p>
                  Bank Name :
                  <> {businessData.businessID.bankInfo[0].bankName}</>
                </p>
                <p>
                  Account Name :{' '}
                  <> {businessData.businessID.bankInfo[0].accountName}</>
                </p>
                <p>
                  Account Number :{' '}
                  <> {businessData.businessID.bankInfo[0].accountNumber}</>
                </p>
              </div>
              <div className='note'>
                <p>Leave a note </p>
                <textarea
                  rows={4}
                  value={shortNote}
                  disabled={invoicePage === 'preview'}
                ></textarea>
              </div>
              <div className='flex-end'>
                {isLoading ? (
                  <div className='loaders'>
                    <img src={Loader} alt='' />
                  </div>
                ) : (
                  <div className='right'>
                    {invoicePage === 'create' ? (
                      <>
                        <Button
                          onClick={() => handlePreview()}
                          content='Preview'
                          outlined
                        />
                      </>
                    ) : (
                      <>
                        <Button
                          onClick={() => setInvoicePage('create')}
                          outlined
                          content='Edit'
                        />
                        <Button
                          onClick={() => handleSubmit(false)}
                          content='Send for approval'
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CreateInvoice
