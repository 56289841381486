import { useState, useEffect } from 'react'
import { FaBars, FaCheck, FaTimes } from 'react-icons/fa'
import { TbEdit } from 'react-icons/tb'

function Approval({ setShowSidebar, showSidebar, notify, businessData }: any) {
  const token = sessionStorage.getItem('accessToken')
  const [loading, setLoading] = useState(true)
  const [incoming, setIncoming] = useState<any>(false)
  const [incomingValue, setIncomingValue] = useState(0)
  const [outgoing, setOutgoing] = useState<any>(false)
  const [outgoingValue, setOutgoingValue] = useState(0)

  const handleSubmit = async () => {
    const formData = new FormData()
    if (incoming) {
      formData.append('minIncomingInvoiceApprovalCount', `${incomingValue}`)
    } else {
      formData.append('minOutgoingInvoiceApprovalCount', `${outgoingValue}`)
    }
    fetch(
      `${process.env.REACT_APP_API_URL}/business/${businessData.businessID._id}/update`,
      {
        method: 'put',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
        redirect: 'follow',
      }
    )
      .then((res) => {
        if (res.status === 201) {
          notify('success', 'Approval workflow edited.')
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        } else if (res.status === 403) {
        } else {
          res.json().then((data) => {
            notify('error', data.errors.join(','))
          })
        }
      })
      .catch((error) => {
        notify('error', 'An error occured please try again')
      })
  }
  useEffect(() => {
    setIncomingValue(businessData.businessID.minIncomingInvoiceApprovalCount)
    setOutgoingValue(businessData.businessID.minOutgoingInvoiceApprovalCount)
  }, [])

  return (
    <div className='department'>
      {' '}
      <div className='comp-one'>
        <div className='rowss'>
          <FaBars onClick={() => setShowSidebar(!showSidebar)} />
        </div>
        <div className='depart-top'>
          <div className='one'>
            <h2>Invoice Approval work-flow</h2>
            <p>
              Set the required amount of approval an invoice should get before
              it is approved.
            </p>
          </div>
        </div>
        <div className='depts-list'>
          <>
            <div className='row'>
              <div className='one'>
                <p>Incoming Invoice</p>
                <input
                  type='number'
                  value={incomingValue}
                  name=''
                  id=''
                  disabled={incoming === false}
                  onChange={(e) => setIncomingValue(parseInt(e.target.value))}
                />
              </div>
              <div className='two'>
                {incoming === true ? (
                  <>
                    <FaCheck onClick={handleSubmit} />
                    <FaTimes
                      onClick={() => {
                        setIncoming(false)
                      }}
                    />
                  </>
                ) : (
                  <>
                    <TbEdit
                      onClick={() => {
                        setIncoming(true)
                        setOutgoing(false)
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            <div className='row'>
              <div className='one'>
                <p> Outgoing Invoice</p>
                <input
                  type='number'
                  value={outgoingValue}
                  name=''
                  id=''
                  disabled={outgoing === false}
                  onChange={(e) => setOutgoingValue(parseInt(e.target.value))}
                />
              </div>
              <div className='two'>
                {outgoing === true ? (
                  <>
                    <FaCheck onClick={handleSubmit} />
                    <FaTimes
                      onClick={() => {
                        setOutgoing(false)
                      }}
                    />
                  </>
                ) : (
                  <>
                    <TbEdit
                      onClick={() => {
                        setOutgoing(true)
                        setIncoming(false)
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  )
}

export default Approval
