import React, { useState } from "react";
import Loader from "../../../Assests/loader.gif";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { FaTimes } from "react-icons/fa";
import Button from "../../../Components/Elements/Button/Button";

type FormData = {
  email: string;
  name: string;
  companyName: string;
  companyDescription: string;
  companyWebsite: string;
  numberOfVendors: number;
  teamSize: number;
};

interface Props {
  notify: (type: string, message: string) => void;
  setShowEarlyAccess: any;
}

function EarlyAccess({ notify, setShowEarlyAccess }: Props) {
  const navigate = useNavigate();
  const [formLoading, setFormLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset, // Add reset here
  } = useForm<FormData>();

  const onSubmit = async (data: FormData) => {
    setFormLoading(true);
    const payLoad = {
      email: data.email,
      name: data.name,
      companyName: data.companyName,
      companyDescription: data.companyDescription,
      companyWebsite: data.companyWebsite,
      numberOfVendors: data.numberOfVendors,
      teamSize: data.teamSize,
    };
    try {
      const baseUrl = process.env.REACT_APP_API_URL; // Get the base URL from the environment variable
      const response = await axios.post(
        `${baseUrl}/business/earlyaccess`,
        payLoad
      );
      notify("success", response.data.message);
      setFormLoading(false);
      reset(); // Clear the form inputs
      setTimeout(() => {
        navigate("/");
      }, 1000);
    } catch (error: any) {
      setFormLoading(false);
      notify("error", error.response.data.errors.join(","));
    }
  };

  return (
    <div className="early">
      <div className="modal">
        <div className="close">
          <FaTimes onClick={() => setShowEarlyAccess(false)} />
        </div>
        <div className="heading">
          <h2>Be the first to get access to our solution</h2>
        </div>
        <form className="" onSubmit={handleSubmit(onSubmit)}>
          <div className="card">
            <p>Name</p>
            <input
              type="text"
              {...register("name", { required: true })}
              id=""
              className={errors.name ? "error" : ""}
            />
          </div>
          <div className="card">
            <p>Email</p>
            <input
              type="Enter your work email"
              id=""
              className={errors.email ? "error" : ""}
              {...register("email", { required: true })}
            />
          </div>
          <div className="card">
            <p>Company Name</p>
            <input
              type="text"
              {...register("companyName", { required: true })}
              id=""
              className={errors.companyName ? "error" : ""}
            />
          </div>
          <div className="card">
            <p>Team size</p>
            <input
              type="number"
              {...register("teamSize", { required: true })}
              id=""
              className={errors.teamSize ? "error" : ""}
            />
          </div>
          <div className="card">
            <p>Website</p>
            <input
              type="text"
              {...register("companyWebsite", { required: true })}
              id=""
              className={errors.companyWebsite ? "error" : ""}
            />
          </div>
          <div className="card">
            <p># of vendors</p>
            <input
              type="number"
              {...register("numberOfVendors", { required: true })}
              id=""
              className={errors.numberOfVendors ? "error" : ""}
            />
          </div>
          <div className="card long">
            <p>Company Description</p>
            <textarea
              id=""
              className={errors.companyDescription ? "error" : ""}
              {...register("companyDescription", { required: true })}
              cols={30}
              rows={10}
            ></textarea>
          </div>
          <div className="card long check">
            <input
              type="checkbox"
              name=""
              id=""
              onChange={(event) => {
                setIsChecked(event.target.checked);
              }}
            />
            <p>I accept the terms of use and conditions governing Paytton</p>
          </div>
          <div className="card long">
            {formLoading ? (
              <div className="loader">
                <img src={Loader} alt="" />
              </div>
            ) : (
              <div className="buttons">
                <Button
                  content="Receive Early Access"
                  disabled={!isChecked}
                  expanded
                />
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default EarlyAccess;
