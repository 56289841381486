import { useNavigate } from 'react-router-dom'
import Image1 from '../../../Assests/Collaboration image.png'
import Image2 from '../../../Assests/Savings Image.png'
import Image3 from '../../../Assests/Compliance Image.png'
import Fade from 'react-reveal/Fade'

function Benefits({ setShowEarlyAccess }: any) {
  const navigate = useNavigate()
  const BenefitsList = [
    {
      id: 1,
      image: Image1,
      name: 'Efficiency and Seamless Collaboration',
      desc: `Empower your procurement and finance teams to work in perfect harmony with Paytton's intuitive collaboration features. Say goodbye to silos and embrace unified workflows.`,
    },
    {
      id: 2,
      image: Image2,
      name: 'Savings and Optimized Financial Decisions',
      desc: `Unlock significant cost savings and optimize financial decisions with real-time spending visibility. Make informed choices that impact your bottom line positively.`,
    },
    {
      id: 3,
      image: Image3,
      name: 'Enhanced Compliance and Risk Mitigation',
      desc: `Centralize vendor data and approvals within Paytton, reducing the risk of errors and ensuring compliance with regulations.`,
    },
  ]
  return (
    <div className='benefits' id='benefits'>
      <div className='tag-cover'>
        <Fade top duration={2000}>
          <div className='head-tag'>
            <h1>Supercharge your source-to-pay efficiency</h1>
            <p>
              Get early access and see how Paytton can help you save time,
              money, and improve compliance
            </p>
          </div>
        </Fade>
      </div>
      <div className='cover'>
        <div className='cover-wrap'>
          {BenefitsList.map((item) => (
            <Fade bottom duration={2000}>
              <div className='card' key={item.id}>
                <img src={item.image} alt='' />
                <h2>{item.name}</h2>
                <p>{item.desc}</p>
              </div>
            </Fade>
          ))}
        </div>
      </div>
      <div className='head-tag'>
        <button onClick={() => setShowEarlyAccess(true)}>
          Get early access
        </button>
      </div>
    </div>
  )
}

export default Benefits
