import React, { useState, useEffect } from 'react'
import './InvoiceDetails.scss'
import TopBar from '../../../../Components/TopBar/TopBar'
import { FaChevronLeft, FaFile, FaTimes, FaUpload } from 'react-icons/fa'
import { TbShieldCheck } from 'react-icons/tb'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from '../../../../Assests/loader2.gif'
import Pdf from 'react-to-pdf'
import { useGlobalContext } from '../../../../Context/Context'
import axios from 'axios'
import Loading from '../../UserComp/Loading/Loading'
import { usePaystackPayment } from 'react-paystack'
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'
import ReactPDF from '@react-pdf/renderer'
import { BsUpload } from 'react-icons/bs'
import ImageWithFallback from '../../../../Components/ImageDisplay/ImageDisplay'
import Button from '../../../../Components/Elements/Button/Button'

type Config = {
  reference: string
  email: string
  amount: number
  subaccount: string
  publicKey: any // Index signature for any additional properties
}
function InvoiceDetails({ notify }: any) {
  const navigate = useNavigate()
  const componentRef: any = React.createRef()
  const [requestLoading, setRequestLoading] = useState(false)
  const [showReciept, setShowReciept] = useState(false)
  const { id } = useParams()
  const authToken = sessionStorage.getItem('accessToken')
  const { businessData } = useGlobalContext()
  const [loading, setLoading] = useState(true)
  const [invoiceData, setInvoiceData] = useState<any>({})
  const loggedInUserDetails: any = JSON.parse(
    sessionStorage.getItem('user') || '[]'
  )

  //Check if the person has approved
  const checkUserApproval = (emailList: any) => {
    const foundUser = emailList.find(
      (item: string) => item === loggedInUserDetails.email
    )
    if (foundUser) {
      return false
    } else {
      return true
    }
  }

  //Approval end <--------------------------------------->
  const [showAprroval, setShowAprroval] = useState(false)
  const [aprrovalType, setAprrovalType] = useState('')
  const [subtotal, setSubtotal] = useState(0)
  const [reason, setReason] = useState('')
  const Invoiceapproval = async () => {
    setRequestLoading(true)
    let url = ''
    let data = null
    if (aprrovalType === 'Approve') {
      url = `${process.env.REACT_APP_API_URL}/invoice/${businessData.businessID._id}/approve?id=${id}`
      data = null
    } else {
      url = `${process.env.REACT_APP_API_URL}/invoice/${businessData.businessID._id}/decline?id=${id}`
      data = {
        note: reason,
      }
    }
    try {
      const response = await axios.put(url, data, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      setRequestLoading(false)
      if (aprrovalType === 'Approve') {
        notify('success', 'Invoice Approved successfully')
      } else {
        notify('success', 'Invoice Declined successfully')
      }
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    } catch (error: any) {
      notify('error', error.response.data.errors.join(','))
      setRequestLoading(false)
    }
  }
  const dateConverter = (date: string) => {
    return new Date(date).toDateString()
  }
  //Sending end <--------------------------------------->
  const [showSend, setshowSend] = useState(false)
  const [sendType, setsendType] = useState('send')
  const [email, setEmail] = useState('')
  const [cCEmail, setCCEmail] = useState('')
  const [message, setMessage] = useState('')
  const onSendInvoice = async () => {
    const data = {
      email: email.toLocaleLowerCase(),
      cc: [
        {
          email: cCEmail.toLocaleLowerCase(),
        },
      ],
      message: message,
    }
    let url = ''
    if (sendType === 'send') {
      url = `${process.env.REACT_APP_API_URL}/invoice/${businessData.businessID._id}/send?id=${id}`
    } else {
      url = `${process.env.REACT_APP_API_URL}/invoice/${businessData.businessID._id}/reminder?id=${id}`
    }
    if (email.length < 1) {
      notify('warn', 'Fill the email field')
    } else {
      setRequestLoading(true)
      try {
        const response = await axios.post(url, data, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        setRequestLoading(false)
        if (sendType === 'send') {
          notify('success', 'Invoice Sent successfully')
        } else {
          notify('success', 'Invoice reminder sent successfully')
        }
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      } catch (error: any) {
        notify('error', error.response.data.errors.join(','))
        setRequestLoading(false)
      }
    }
  }
  const handleWheel = (event: any) => {
    event.target.blur()
  }
  //Fetch Invoice=--------------------------
  const [paidList, setPaidList] = useState([])
  const [subAccountCode, setSubAccountCode] = useState('')

  const fetchInvoiceDetail = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/invoice/${businessData.businessID._id}/${id}/fetch`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      if (res.status === 200) {
        setLoading(false)
        setInvoiceData(res.data.data.invoice)
        setEmail(res.data.data.invoice.email)
        setMessage(res.data.data.invoice.note)
        setSubAccountCode(res.data.data.invoice.businessID.subAccountCode)
        const calculateAmount = (price: number, quantity: number) => {
          return price * quantity
        }
        let total = 0
        res.data.data.invoice.products.forEach((product: any) => {
          total += calculateAmount(product.product.price, product.quantity)
        })
        setSubtotal(total)
      }
    } catch (error) {}
  }
  const fetchInvoiceList = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/invoice/${businessData.businessID._id}/payment-proof/fetch?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      if (res.status === 200) {
        setPaidList(res.data.data.docs)
      }
    } catch (error) {}
  }
  useEffect(() => {
    fetchInvoiceDetail()
    fetchInvoiceList()
  }, [])

  //Submiting payment proof <-------------------------------------------->
  const [showPay, setShowPay] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState('')
  const [paymenAmount, setPaymenAmount] = useState('')
  const [paymentReciept, setPaymentReciept] = useState<any>([])
  const [payOnlineDetails, setPayOnlineDetails] = useState<any>({})
  const [paymentTrying, setPaymentTrying] = useState(false)
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files) {
      const newFiles: File[] = Array.from(files)
      setPaymentReciept((prevSelectedFiles: any) => [
        ...prevSelectedFiles,
        ...newFiles,
      ])
    }
  }
  const handleSubmit = (e: any) => {
    e.preventDefault()
    setRequestLoading(true)
    const formData = new FormData()
    formData.append('amount', paymenAmount)
    formData.append('currency', invoiceData.currency)
    formData.append('paymentType', paymentMethod)
    if (paymentMethod === 'offline_payment') {
      formData.append('proof', paymentReciept[0])
    }
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/invoice/${businessData.businessID._id}/pay?id=${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((response) => {
        setRequestLoading(false)
        if (paymentMethod === 'offline_payment') {
          notify(
            'success',
            'Payment proof submitted. Awaiting Supplier confirmation.'
          )
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        } else {
          setPayOnlineDetails(response.data.data)
          setPaymentTrying(true)
        }
      })
      .catch((error) => {
        setRequestLoading(false)
      })
  }

  const onSuccess = (response: any) => {
    onConfirmPayment()
  }

  // you can call this function anything
  const onClose = () => {
    setPayOnlineDetails({})
  }

  useEffect(() => {
    // Check if the payOnlineDetails state has been updated and is not an empty object
    if (Object.keys(payOnlineDetails).length > 0 && paymentTrying) {
      // Call initializePayment with the updated payOnlineDetails
      initializePayment(onSuccess, onClose, payOnlineDetails)
      setPaymentTrying(false)
    }
  }, [payOnlineDetails, onClose])
  const config: Config = {
    reference: payOnlineDetails.reference,
    email: invoiceData.email,
    amount: parseFloat(paymenAmount) * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
    subaccount: payOnlineDetails.subAccountCode,
  }
  const initializePayment: any = usePaystackPayment(config)

  const onConfirmPayment = async () => {
    setRequestLoading(true)
    let url = `${process.env.REACT_APP_API_URL}/invoice/${businessData.businessID._id}/payment-proof/${payOnlineDetails.reference}/confirm`
    setRequestLoading(true)
    try {
      const response = await axios.put(url, null, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      setRequestLoading(false)
      notify('success', 'Payment approved successfully')
      setPayOnlineDetails({})
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    } catch (error: any) {
      notify('error', error.response.data.errors.join(','))
      setRequestLoading(false)
    }
  }

  ///gets ths refrence number

  //// Aprrove payment
  const [showApprove, setShowApprove] = useState(false)
  const [payemntId, setPayemntId] = useState('')
  const onAprrovePayment = async () => {
    let url = `${process.env.REACT_APP_API_URL}/invoice/${businessData.businessID._id}/payment-proof/${payemntId}/verify`
    setRequestLoading(true)
    try {
      const response = await axios.put(url, null, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      setRequestLoading(false)
      notify('success', 'Payment approved successfully')
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    } catch (error: any) {
      notify('error', error.response.data.errors.join(','))
      setRequestLoading(false)
    }
  }

  function getDateStatus(targetDate: string) {
    const today = new Date()
    const target = new Date(targetDate)
    const timeDiff = target.getTime() - today.getTime()
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24))

    if (daysDiff > 0) {
      return `${daysDiff} days left`
    } else if (daysDiff < 0) {
      return `${Math.abs(daysDiff)} days overdue`
    } else {
      return 'Due today'
    }
  }

  // This is for pdf downloader
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4',
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
  })

  const MyDocument = () => (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.section}>
          <Text>Section #1</Text>
        </View>
        <View style={styles.section}>
          <Text>Section #2</Text>
        </View>
      </Page>
    </Document>
  )

  return loading ? (
    <Loading />
  ) : (
    <div className='invoiceDetails'>
      {/* <button
        onClick={() => {
          ReactPDF.render(<MyDocument />, `${__dirname}/example.pdf`)
        }}
      >
        Heyyy
      </button> */}
      {showReciept && (
        <div className='modal-body'>
          <div className='modal'>
            <div className='close'>
              <FaTimes onClick={() => setShowReciept(false)} />
            </div>
            <h1>PAID : Invoice#{invoiceData.number} </h1>

            <div className='texta'>
              <input type='file' id='reciept' />
              <label htmlFor='reciept'>
                <div className='button'>
                  <div className='cats'>
                    <FaUpload /> Upload
                  </div>
                  <p>Payment reciept</p>
                </div>
              </label>
            </div>
            {requestLoading ? (
              <div className='buttons'>
                <img src={Loader} />
              </div>
            ) : (
              <div className='buttons'>
                <button onClick={() => setShowReciept(false)}>Cancel</button>
                <button>Upload Reciept</button>
              </div>
            )}
          </div>
        </div>
      )}
      {showAprroval && (
        <div className='modal-body'>
          <div className='modal'>
            <div className='close'>
              <FaTimes onClick={() => setShowAprroval(false)} />
            </div>
            <h1>Are you sure you want to {aprrovalType} invoice?</h1>
            {aprrovalType !== 'Approve' && (
              <div className='textax'>
                <textarea
                  name=''
                  id=''
                  cols={30}
                  rows={10}
                  onChange={(e) => setReason(e.target.value)}
                ></textarea>
              </div>
            )}
            {requestLoading ? (
              <div className='buttons'>
                <img src={Loader} />
              </div>
            ) : (
              <div className='new-buttons'>
                <Button
                  onClick={() => setShowAprroval(false)}
                  outlined
                  content='Cancel'
                />
                <Button
                  onClick={() => Invoiceapproval()}
                  danger={aprrovalType !== 'Approve'}
                  content={aprrovalType}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {showSend && (
        <div className='modal-body'>
          <div className='modal'>
            <div className='close'>
              <FaTimes onClick={() => setshowSend(false)} />
            </div>
            <h1>Are you sure you want to send invoice?</h1>
            <div className='textax dif'>
              <input
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className='textax dif'>
              <input
                type='email'
                value={cCEmail}
                onChange={(e) => setCCEmail(e.target.value)}
                placeholder='CC:'
              />
            </div>
            <div className='textax dif dow'>
              <input
                type='text'
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder='Message'
              />
            </div>
            {requestLoading ? (
              <div className='buttons'>
                <img src={Loader} />
              </div>
            ) : (
              <div className='new-buttons'>
                <Button
                  onClick={() => setshowSend(false)}
                  outlined
                  content='Cancel'
                />
                <Button onClick={() => onSendInvoice()} content={'Send'} />
              </div>
            )}
          </div>
        </div>
      )}
      {showPay && (
        <div className='modal-body'>
          <form className='modal' onSubmit={handleSubmit}>
            <div className='close'>
              <FaTimes onClick={() => setShowPay(false)} />
            </div>
            <h1>
              {paymentMethod === 'offline_payment'
                ? 'Already paid? Upload proof of payment.'
                : 'How much do you want to pay?.'}
            </h1>
            <div className='proofs'>
              {/* <div className='card'>
                <select
                  name={paymentMethod}
                  id=''
                  onChange={(e) => setPaymentMethod(e.target.value)}
                >
                  <option value='' id=''>
                    -Select-
                  </option>
                  <option value='online_payment'>Pay Online</option>
                  <option value='offline_payment'>Already paid</option>
                  <option value='credit_payment'>Credit Payment</option>
                </select>
              </div> */}
              <div className='card'>
                <input
                  type='number'
                  step='0.01'
                  onWheel={handleWheel}
                  min={0}
                  value={paymenAmount}
                  onChange={(e) => setPaymenAmount(e.target.value)}
                  max={invoiceData.outstanding}
                />
              </div>
              {paymentMethod === 'offline_payment' && (
                <div className='card rig'>
                  <input
                    type='file'
                    name=''
                    id='proof'
                    accept='.pdf, .jpg, .jpeg, .png'
                    onChange={handleFileChange}
                  />
                  <label htmlFor='proof'>
                    <div className='butz'>
                      <BsUpload />
                      <p>Upload</p>
                    </div>
                  </label>
                  <p className='sel'>
                    {paymentReciept.length > 0
                      ? paymentReciept[0].name
                      : 'Upload proof'}
                  </p>
                </div>
              )}
            </div>
            {requestLoading ? (
              <div className='buttons'>
                <img src={Loader} />
              </div>
            ) : (
              <div className='new-buttons'>
                <Button
                  onClick={(e) => {
                    e.preventDefault()
                    setShowPay(false)
                  }}
                  outlined
                  content='Cancel'
                />
                <Button content={'Pay'} />
              </div>
            )}
          </form>
        </div>
      )}
      {showApprove && (
        <div className='modal-body'>
          <div className='modal'>
            <div className='close'>
              <FaTimes onClick={() => setShowApprove(false)} />
            </div>
            <h1>Are you sure you want to Approve payment?</h1>
            {requestLoading ? (
              <div className='buttons'>
                <img src={Loader} />
              </div>
            ) : (
              <div className='new-buttons'>
                <Button
                  onClick={() => setShowApprove(false)}
                  outlined
                  content='Cancel'
                />
                <Button
                  onClick={() => onAprrovePayment()}
                  content={'Approve'}
                />
              </div>
            )}
          </div>
        </div>
      )}

      <TopBar />
      <div className='back' onClick={() => navigate(-1)}>
        <FaChevronLeft />
        <p>Back</p>
      </div>
      <div className='head'>
        <div className='left'></div>
        <div className='right'>
          {invoiceData.status === 'awaiting_internal_approval' ||
          (businessData.businessID._id === invoiceData.sellerID._id &&
            invoiceData.status === 'awaiting_customer_approval') ? (
            <>
              {checkUserApproval(invoiceData.approvers) ? (
                <>
                  <button
                    onClick={() => {
                      setAprrovalType('Approve')
                      setShowAprroval(true)
                    }}
                  >
                    Approve Invoice
                  </button>
                  <button
                    onClick={() => {
                      setAprrovalType('Decline')
                      setShowAprroval(true)
                    }}
                  >
                    Decline Invoice
                  </button>
                </>
              ) : (
                <h2>Awaiting other approvals </h2>
              )}
            </>
          ) : invoiceData.status === 'approved' ? (
            <>
              <button
                onClick={() => {
                  setsendType('send')
                  setshowSend(true)
                }}
              >
                Send Invoice
              </button>
            </>
          ) : invoiceData.status === 'awaiting_customer_approval' ? (
            <>
              <button
                onClick={() => {
                  setsendType('remind')
                  setshowSend(true)
                }}
              >
                Send Invoice reminder
              </button>
            </>
          ) : invoiceData.status === 'declined_internally' ? (
            <button
              onClick={() => {
                navigate(`/user/edit-invoice/${id}`)
              }}
            >
              Edit Invoice
            </button>
          ) : invoiceData.status === 'customer_declined' ? (
            <></>
          ) : businessData.businessID._id === invoiceData.sellerID._id &&
            (invoiceData.status === 'approved_for_financing' ||
              invoiceData.status === 'outstanding') ? (
            <>
              <button
                onClick={() => {
                  setPaymentMethod('offline_payment')
                  setShowPay(true)
                }}
              >
                Mark as Paid
              </button>
              <button
                onClick={() => {
                  setPaymentMethod('online_payment')
                  setShowPay(true)
                }}
              >
                Pay Online
              </button>
            </>
          ) : businessData.businessID._id === invoiceData.businessID._id &&
            (invoiceData.status === 'approved_for_financing' ||
              invoiceData.status === 'outstanding') ? (
            <>
              <button
                onClick={() => {
                  setsendType('remind')
                  setshowSend(true)
                }}
              >
                Send Invoice reminder
              </button>
            </>
          ) : (
            <>
              {' '}
              <button>PAID</button>
            </>
          )}
        </div>
      </div>
      {/* <Pdf targetRef={componentRef} filename='code-example.pdf'>
        {({ toPdf }: any) => <button onClick={toPdf}>Generate Pdf</button>}
      </Pdf> */}
      <div className='refs' ref={componentRef}>
        <div className='invoice-no'>
          <h1>{invoiceData.number}</h1>
        </div>
        {(invoiceData.status === 'declined_internally' ||
          invoiceData.status === 'customer_declined') && (
          <div className='reasons'>
            <h2>Invoice Declined</h2>
            <p>Reason: {invoiceData.declineNote.note}</p>
          </div>
        )}
        <div className='vendor-info logo'>
          <div className='left'>
            <ImageWithFallback
              src={invoiceData.businessID.profileImageURL}
              alt='Image Description'
            />
          </div>
          <div className='right'>
            <div className='tag'>
              <h3>{invoiceData.businessID.name}</h3>
              <TbShieldCheck />
            </div>
            <p>
              {invoiceData.businessID.addresses[0].address},
              {invoiceData.businessID.addresses[0].state}{' '}
            </p>
            <p>{invoiceData.businessID.email}</p>
            <p>{invoiceData.businessID.phone}</p>
          </div>
        </div>
        <div className='vendor-info'>
          <div className='left'>
            <div className='two'>
              <h2>INVOICE To :</h2>
              <div className='tag'>
                <h2>{invoiceData.sellerName}</h2>
                <TbShieldCheck />
              </div>
              <p>
                {invoiceData.address},{invoiceData.state}{' '}
              </p>
              <p>{invoiceData.email}</p>
              <p>{invoiceData.phone}</p>
            </div>
          </div>
          <div className='right'>
            <h2>INVOICE DETAILS</h2>
            <p>Invoice date : {dateConverter(invoiceData.date)}</p>
            <p>Invoice due date : {getDateStatus(invoiceData.requiredEnd)}</p>
            <button>
              {invoiceData.status === 'awaiting_internal_approval'
                ? 'Awaiting Internal Approval'
                : invoiceData.status === 'approved'
                ? 'Approved Internally'
                : invoiceData.status === 'declined_internally'
                ? 'Declined Internally'
                : invoiceData.status === 'awaiting_customer_approval'
                ? businessData.businessID._id === invoiceData.businessID._id
                  ? 'Sent to Customer'
                  : 'Awaiting your approval.'
                : invoiceData.status === 'customer_declined'
                ? businessData.businessID._id === invoiceData.businessID._id
                  ? 'Declined by Customer'
                  : 'Declined'
                : invoiceData.status === 'approved_for_financing'
                ? 'Approved by Customer'
                : invoiceData.status === 'outstanding'
                ? 'Outstanding'
                : 'Fully Paid'}
            </button>
          </div>
        </div>
        <div className='vendor-table'>
          <table>
            <thead>
              <tr>
                <th className='name'>Product Name</th>
                <th> Description</th>
                <th className='prcice'>Unit Price</th>
                <th>Qty</th>
                <th className='prcice'> Amount</th>
              </tr>
            </thead>
            <tbody>
              {invoiceData.products.map((item: any) => (
                <tr key={item._id}>
                  <td className='name'>{item.product.name}</td>
                  <td>{item.product.description}</td>
                  <td className='prcice'>
                    {item.product.price.toLocaleString()}
                  </td>
                  <td>{item.quantity}</td>
                  <td className='prcice'>
                    {(item.product.price * item.quantity).toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className='mobile'>
            {invoiceData.products.map((item: any) => (
              <div className='card' key={item._id}>
                <div className='row'>
                  <div className='one'>
                    <h3>Product name</h3>
                  </div>
                  <div className='two'>
                    <p>{item.product.name}</p>
                  </div>
                </div>
                <div className='row'>
                  <div className='one'>
                    <h3>Product Currency</h3>
                  </div>
                  <div className='two'>
                    <p>{item.product.currency}</p>
                  </div>
                </div>
                <div className='row'>
                  <div className='one'>
                    <h3>Product Price</h3>
                  </div>
                  <div className='two'>
                    <p>{item.product.price.toLocaleString()}</p>
                  </div>
                </div>
                <div className='row'>
                  <div className='one'>
                    <h3>Qty</h3>
                  </div>
                  <div className='two'>
                    <p>{item.quantity}</p>
                  </div>
                </div>
                <div className='row'>
                  <div className='one'>
                    <h3>Amount </h3>
                  </div>
                  <div className='two'>
                    <p>
                      {(item.product.price * item.quantity).toLocaleString()}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className='totals'>
            <div className='curr'>
              <button> {invoiceData.currency}</button>
            </div>
            <div className='table-v'>
              <table>
                <tr>
                  <td>SUBTOTAL</td>
                  <td>
                    <b>
                      {invoiceData.currency}
                      {subtotal.toLocaleString()}
                    </b>
                  </td>
                </tr>
                <tr>
                  <td>Discount</td>
                  <td>
                    {invoiceData.currency}
                    {invoiceData.discount.type === 'percentage'
                      ? (
                          (invoiceData.discount.value / 100) *
                          subtotal
                        ).toLocaleString()
                      : invoiceData.discount.value.toLocaleString()}
                  </td>
                </tr>
                <tr>
                  <td>Tax</td>
                  <td>
                    {invoiceData.currency}
                    {invoiceData.tax.type === 'percentage'
                      ? (
                          (invoiceData.tax.value / 100) *
                          subtotal
                        ).toLocaleString()
                      : invoiceData.tax.value.toLocaleString()}
                  </td>
                </tr>
                {(invoiceData.status === 'approved_for_financing' ||
                  invoiceData.status === 'outstanding' ||
                  invoiceData.status === 'paid') && (
                  <>
                    <tr>
                      <td>Total Amount</td>
                      <td>
                        {invoiceData.currency}
                        {invoiceData.amount.toLocaleString()}
                      </td>
                    </tr>
                    <tr>
                      <td>Amount Paid</td>
                      <td>
                        {invoiceData.currency}
                        {(
                          invoiceData.amount - invoiceData.outstanding
                        ).toLocaleString()}
                      </td>
                    </tr>
                  </>
                )}
              </table>
            </div>
            <div className='tot'>
              {invoiceData.status === 'approved_for_financing' ||
              invoiceData.status === 'outstanding' ||
              invoiceData.status === 'paid' ? (
                <p>OUTSTANDING AMOUNT</p>
              ) : (
                <p>TOTAL AMOUNT</p>
              )}

              <h2>
                {invoiceData.currency}{' '}
                {invoiceData.outstanding > 1
                  ? invoiceData.outstanding.toLocaleString()
                  : invoiceData.amount.toLocaleString()}
              </h2>
            </div>
          </div>
          <div className='bankdet'>
            <h2>Bank details </h2>
            <p>
              Bank Name :<> {invoiceData.bankName}</>
            </p>
            <p>
              Account Name : <> {invoiceData.bankAccountName}</>
            </p>
            <p>
              Account Number : <> {invoiceData.bankAccountNumber}</>
            </p>
          </div>
        </div>
      </div>
      <div className='head'>
        <div className='left'></div>
        <div className='right'>
          {invoiceData.status === 'awaiting_internal_approval' ||
          (businessData.businessID._id === invoiceData.sellerID._id &&
            invoiceData.status === 'awaiting_customer_approval') ? (
            <>
              {checkUserApproval(invoiceData.approvers) ? (
                <>
                  <button
                    onClick={() => {
                      setAprrovalType('Approve')
                      setShowAprroval(true)
                    }}
                  >
                    Approve Invoice
                  </button>
                  <button
                    onClick={() => {
                      setAprrovalType('Decline')
                      setShowAprroval(true)
                    }}
                  >
                    Decline Invoice
                  </button>
                </>
              ) : (
                <h2>Awaiting other approvals </h2>
              )}
            </>
          ) : invoiceData.status === 'approved' ? (
            <>
              <button
                onClick={() => {
                  setsendType('send')
                  setshowSend(true)
                }}
              >
                Send Invoice
              </button>
            </>
          ) : invoiceData.status === 'awaiting_customer_approval' ? (
            <>
              <button
                onClick={() => {
                  setsendType('remind')
                  setshowSend(true)
                }}
              >
                Send Invoice reminder
              </button>
            </>
          ) : invoiceData.status === 'declined_internally' ? (
            <button
              onClick={() => {
                navigate(`/user/edit-invoice/${id}`)
              }}
            >
              Edit Invoice
            </button>
          ) : invoiceData.status === 'customer_declined' ? (
            <></>
          ) : businessData.businessID._id === invoiceData.sellerID._id &&
            (invoiceData.status === 'approved_for_financing' ||
              invoiceData.status === 'outstanding') ? (
            <>
              <button
                onClick={() => {
                  setPaymentMethod('offline_payment')
                  setShowPay(true)
                }}
              >
                Mark as Paid
              </button>
              <button
                onClick={() => {
                  setPaymentMethod('online_payment')
                  setShowPay(true)
                }}
              >
                Pay Online
              </button>
            </>
          ) : businessData.businessID._id === invoiceData.businessID._id &&
            (invoiceData.status === 'approved_for_financing' ||
              invoiceData.status === 'outstanding') ? (
            <>
              <button
                onClick={() => {
                  setsendType('remind')
                  setshowSend(true)
                }}
              >
                Send Invoice reminder
              </button>
            </>
          ) : (
            <>
              {' '}
              <button>PAID</button>
            </>
          )}
        </div>
      </div>
      {(invoiceData.status === 'approved_for_financing' ||
        invoiceData.status === 'outstanding' ||
        invoiceData.status === 'awaiting_customer_approval' ||
        invoiceData.status === 'paid') && (
        <div className='transaction-table'>
          <div className='table-top'>
            <h2>Payment history</h2>
          </div>
          <div className='tablex'>
            <div className='table'>
              <div className='headx'>
                <div className='row'>
                  <div className='td'>S/N</div>
                  <div className='td'> Currency </div>
                  <div className='td'>Amount </div>
                  <div className='td'>Payment Date</div>
                  <div className='td'>Verified Date</div>
                  <div className='td'>Payment type</div>
                  {businessData.businessID.name !== invoiceData.sellerName && (
                    <div className='td'>Action</div>
                  )}
                </div>
              </div>
              <div className='bodyx'>
                {paidList.map((item: any, i: number) => (
                  <div className='row' key={i + 1}>
                    <div className='td'>{i + 1}</div>
                    <div className='td'>{item.currency}</div>
                    <div className='td'>{item.amount.toLocaleString()}</div>
                    <div className='td'>
                      {new Date(item.createdAt).toDateString()}
                      <br />
                      {new Date(item.createdAt).toLocaleTimeString()}
                    </div>
                    <div className='td'>
                      {!item.verified ? (
                        'Not verified'
                      ) : (
                        <>
                          {new Date(item.updatedAt).toDateString()}
                          <br />
                          {new Date(item.updatedAt).toLocaleTimeString()}
                        </>
                      )}
                    </div>
                    <div className='td'>
                      {item.paymentType === 'offline_payment' ? (
                        <a
                          href={item.paymentProof}
                          target='_blank'
                          download={item.paymentProof}
                        >
                          {item.paymentType.replace(/_/g, ' ')}
                          <FaFile />
                        </a>
                      ) : (
                        item.paymentType.replace(/_/g, ' ')
                      )}
                    </div>
                    {businessData.businessID._id !==
                      invoiceData.sellerID._id && (
                      <div className='td'>
                        {businessData.businessID._id !==
                          invoiceData.sellerID._id &&
                          !item.verified && (
                            <button
                              onClick={() => {
                                setPayemntId(item._id)
                                setShowApprove(true)
                              }}
                            >
                              Verify
                            </button>
                          )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className='mobile'>
            <>
              {paidList.map((item: any, i: number) => (
                <div
                  className='nodyx'
                  key={1 + i}
                  onClick={() => navigate(`/user/vendor/${'Hey'}`)}
                >
                  <div className='row'>
                    <div className='left'>
                      <h2>S/N</h2>
                    </div>
                    <div className='right'>
                      <p>{i + 1}</p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='left'>
                      <h2>Currency</h2>
                    </div>
                    <div className='right'>
                      <p>{item.currency}</p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='left'>
                      <h2>Amount</h2>
                    </div>
                    <div className='right'>
                      <p>{item.amount.toLocaleString()}</p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='left'>
                      <h2>Created Date</h2>
                    </div>
                    <div className='right'>
                      <p>
                        {' '}
                        {new Date(item.createdAt).toDateString()}
                        <br />
                        {new Date(item.createdAt).toLocaleTimeString()}
                      </p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='left'>
                      <h2>Verified Date</h2>
                    </div>
                    <div className='right'>
                      <p>
                        {' '}
                        {!item.verified ? (
                          'Not verified'
                        ) : (
                          <>
                            {new Date(item.updatedAt).toDateString()}
                            <br />
                            {new Date(item.updatedAt).toLocaleTimeString()}
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='left'>
                      <h2>Payment type</h2>
                    </div>
                    <div className='right'>
                      <p>{item.paymentType.replace(/_/g, ' ')}</p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='left'></div>
                    <div className='right'>
                      {!item.verified && invoiceData.status === 'paid' ? (
                        <p>Invoice Paid</p>
                      ) : businessData.businessID.name !==
                          invoiceData.sellerName && !item.verified ? (
                        <button
                          onClick={() => {
                            setPayemntId(item._id)
                            setShowApprove(true)
                          }}
                        >
                          Verify
                        </button>
                      ) : (
                        <p> {`${item.verified}`}</p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </>
          </div>
        </div>
      )}
    </div>
  )
}

export default InvoiceDetails
