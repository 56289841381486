import { useState, useEffect } from 'react'
import { FaTimes } from 'react-icons/fa'
import Loader from '../../../../../Assests/loader.gif'
import axios from 'axios'
import { useGlobalContext } from '../../../../../Context/Context'
import Select from 'react-select'
import { components } from 'react-select'
import { axiosCalls } from '../../../../../Api/_axios'

function AddRoles({ setshowRoles, notify, showRoles }: any) {
  const authToken = sessionStorage.getItem('accessToken')
  const { businessData } = useGlobalContext()
  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState('')
  const [description, setDescription] = useState<any>([])
  const [PermissionLoading, setPermissionLoading] = useState(true)
  const [permissionData, setPermissionData] = useState([])

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setIsLoading(true)
    const roleArray: string[] = description.map((item: any) => item.value)
    const data: any = {
      name,
      permissions: roleArray,
    }
    let url = `role/${businessData.businessID._id}/create`
    let type = 'POST'
    if (showRoles !== true) {
      data.id = showRoles._id
      url = `role/${businessData.businessID._id}/update`
      type = 'PUT'
    }
    try {
      const result = await axiosCalls(url, type, data, authToken)
      if (result.success) {
        notify('success', result.message)
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      } else {
        notify('error', result.er.errors.join(','))
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (showRoles !== true) {
      setName(showRoles.name)
      const outputArray = showRoles.permissions.map((item: any) => ({
        value: item,
        label: item
          .replace(/_/g, ' ')
          .replace(/\b\w/g, (char: any) => char.toUpperCase()),
      }))
      setDescription(outputArray)
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axiosCalls(
          `auth/permissions`,
          'GET',
          null,
          authToken
        )
        setPermissionLoading(false)
        if (result.data) {
          setPermissionData(result.data)
        } else {
        }
      } catch (error: any) {
        notify('error', error.message)
      }
    }
    fetchData()
  }, [])

  const Option = (props: any) => {
    return (
      <div>
        <components.Option {...props}>
          <div className='selet-box'>
            <input
              type='checkbox'
              checked={props.isSelected}
              onChange={() => null}
            />{' '}
            <label>{props.label}</label>
          </div>
        </components.Option>
      </div>
    )
  }

  return (
    <div className='modal-body'>
      <div className='modal'>
        <div className='close'>
          <FaTimes onClick={() => setshowRoles(null)} />
        </div>
        <div className='tag'>
          <p>{showRoles === true ? 'Add Roles' : 'Edit Roles'} </p>
        </div>
        <div className='form'>
          <form onSubmit={handleSubmit}>
            <div className='card'>
              <p>Role name</p>
              <input
                type='text'
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className='card'>
              <p>Role Permissions</p>
              <Select
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                options={permissionData.map((item: any) => ({
                  value: item.permission,
                  label: item.permission
                    .replace(/_/g, ' ')
                    .replace(/\b\w/g, (char: any) => char.toUpperCase()),
                }))}
                components={{ Option }}
                isMulti
                value={description}
                onChange={(value) => setDescription(value)}
                isLoading={PermissionLoading}
              />
            </div>
            {isLoading ? (
              <div className='buttons'>
                <img src={Loader} alt='Loader' />
              </div>
            ) : (
              <div className='buttons'>
                <button type='submit'>
                  {showRoles === true ? 'Create Role' : 'Edit Role'}
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddRoles
