import React from 'react'
import './Loading.scss'
import Loader from '../../../../Assests/loader2.gif'

function Loading() {
  return (
    <div className='main-loading'>
      <img src={Loader} alt='' />
    </div>
  )
}

export default Loading
