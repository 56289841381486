import './SidebarLayout.scss'
import Logo from '../../Assests/3.png'
import { useGlobalContext } from '../../Context/Context'

const SidebarLayout = ({ children }: any) => {
  const { sideBar }: any = useGlobalContext()
  return (
    <div className={sideBar ? 'sidebarLayout' : 'sidebarLayout active'}>
      <div className='top'>
        <img src={Logo} alt='' />
      </div>
      <div className='bodys'>{children}</div>
    </div>
  )
}

export default SidebarLayout
