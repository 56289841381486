import React, { useState, useEffect } from 'react'
import './CreateInvoice.scss'
import TopBar from '../../../../Components/TopBar/TopBar'
import { FaChevronRight, FaPlus, FaCheck, FaTimes } from 'react-icons/fa'
import Select from 'react-select'
import AddVendor from '../Vendors/Components/AddVendor'
import AddProduct from '../Products/Component/AddProduct'
import axios from 'axios'
import { useGlobalContext } from '../../../../Context/Context'
import Loader from '../../../../Assests/loader.gif'
import { useParams } from 'react-router-dom'
import Loading from '../../UserComp/Loading/Loading'
import { RiDeleteBin6Line } from 'react-icons/ri'

function EditInvoice({ notify }: any) {
  const { businessData } = useGlobalContext()
  const authToken = sessionStorage.getItem('accessToken')
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [productList, setProductList] = useState<any>([])
  const [addVendor, setAddVendor] = useState(false)
  const [addProduct, setAddProduct] = useState(false)
  const [productsLoading, setProductsLoading] = useState(true)

  //form details
  const [address, setAddress] = useState('')
  const [state, setState] = useState('')
  const [country, setCountry] = useState('')
  const [invoiceDate, setInvoiceDate] = useState('')
  const [dueDate, setDueDate] = useState('')
  const [invoiceCurrency, setInvoiceCurrency] = useState('')
  const [discountType, setDiscountType] = useState('')
  const [discount, setDiscount] = useState(0)
  const [tax, setTax] = useState(0)
  const [taxType, setTaxType] = useState('')
  const [shortNote, setShortNote] = useState('')
  const [selectedProduct, setSelectedProduct] = useState<any>(null)
  const [quantity, setQuantity] = useState('')
  const [selectedProductList, setselectedProductList] = useState<any>([])
  const [finaleAmount, setFinaleAmount] = useState<any>(0)
  const [loading, setLoading] = useState(true)
  const [invoiceData, setInvoiceData] = useState<any>({})

  //Fetching Vendors
  const fetchProductList = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/product/${businessData.businessID._id}/fetch?limit=1000`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      if (res.status === 200) {
        const transformedOptions = res.data.data.docs.map((option: any) => ({
          label: option.name,
          value: option._id,
          ...option,
        }))
        setProductList(transformedOptions)
        setProductsLoading(false)
      }
    } catch (error) {}
  }
  useEffect(() => {
    fetchProductList()
  }, [])
  const fetchInvoiceDetail = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/invoice/${businessData.businessID._id}/${id}/fetch`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      if (res.status === 200) {
        setLoading(false)
        setInvoiceData(res.data.data.invoice)
        setInvoiceCurrency(res.data.data.invoice.currency)
        setAddress(res.data.data.invoice.address)
        setState(res.data.data.invoice.state)
        setCountry(res.data.data.invoice.country)
        setInvoiceDate(res.data.data.invoice.date.slice(0, 10))
        setDueDate(res.data.data.invoice.requiredEnd.slice(0, 10))
        setDiscountType(res.data.data.invoice.discount.type)
        setDiscount(res.data.data.invoice.discount.value)
        setTaxType(res.data.data.invoice.tax.type)
        setTax(res.data.data.invoice.tax.value)
        setShortNote(res.data.data.invoice.note)
        setselectedProductList(res.data.data.invoice.products)
      }
    } catch (error) {}
  }
  useEffect(() => {
    fetchInvoiceDetail()
  }, [])

  const handleAddProduct = (value: any) => {
    if (value) {
      const existingProduct = selectedProductList.find(
        (product: any) => product.product._id === value._id
      )
      if (existingProduct) {
        existingProduct.quantity++
        setselectedProductList((prevList: any) => [...prevList])
      } else {
        const newProduct = {
          product: value,
          quantity: 1,
        }
        setselectedProductList((prevList: any) => [...prevList, newProduct])
      }
    }
  }
  const editProductQuantity = (productID: string, newQuantity: number) => {
    setselectedProductList((prevProducts: any) => {
      return prevProducts.map((item: any) => {
        if (item.product._id === productID) {
          return { ...item, quantity: newQuantity }
        }
        return item
      })
    })
  }
  const handleRemoveProduct = (productToRemove: any) => {
    setselectedProductList((prevList: any) =>
      prevList.filter(
        (product: any) => product.product._id !== productToRemove._id
      )
    )
  }
  const calculateAmount = (price: number, quantity: number) => {
    return price * quantity
  }
  const getTotalAmount = () => {
    let total = 0
    selectedProductList.forEach((product: any) => {
      total += calculateAmount(product.product.price, product.quantity)
    })
    return total
  }
  useEffect(() => {
    let disam = 0
    let taxam = 0
    if (discountType === 'percentage') {
      disam = (discount / 100) * getTotalAmount()
    } else {
      disam = discount
    }
    if (taxType === 'percentage') {
      taxam = (tax / 100) * getTotalAmount()
    } else {
      taxam = tax
    }
    const amountandDiscount = getTotalAmount() - disam
    setFinaleAmount(amountandDiscount + taxam)
  }, [getTotalAmount])

  const handleSubmit = () => {
    const transformedOptions = selectedProductList.map((option: any) => ({
      product: option.product._id,
      quantity: option.quantity,
    }))
    const url = `${process.env.REACT_APP_API_URL}/invoice/${businessData.businessID._id}/update?id=${id}`

    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
    if (address.length < 5) {
      notify('error', 'Please provide a valid address.')
    } else if (country.length < 5) {
      notify('error', 'Please provide a valid country.')
    } else if (state.length < 5) {
      notify('error', 'Please provide a valid state.')
    } else if (invoiceDate.length < 5) {
      notify('error', 'Please provide a valid invoice date.')
    } else if (dueDate.length < 5) {
      notify('error', 'Please provide a valid due date.')
    } else if (transformedOptions.length < 1) {
      notify('error', 'Please provide at least 1 products.')
    } else if (invoiceCurrency.length < 2) {
      notify('error', 'Please provide a valid invoice currency.')
    } else if (discountType.length < 5) {
      notify('error', 'Please provide a valid discount type.')
    } else {
      setIsLoading(true)
      const data = {
        address: address,
        country: country,
        state: state,
        date: invoiceDate,
        requiredEnd: dueDate,
        products: transformedOptions,
        note: shortNote,
        bankName: businessData.businessID.bankInfo[0].bankName,
        bankAccountName: businessData.businessID.bankInfo[0].accountName,
        bankAccountNumber: businessData.businessID.bankInfo[0].accountNumber,
        tax: {
          value: tax,
          type: taxType,
        },
        discount: {
          value: discount,
          type: discountType,
        },
      }
      axios
        .put(url, data, config)
        .then((response) => {
          setIsLoading(false)
          notify('success', response.data.message)
          setTimeout(() => {
            window.location.replace('/user/invoices')
          }, 3000)
        })
        .catch((error) => {
          setIsLoading(false)
          notify('error', error.response.data.errors.join(','))
          // Handle the error as needed
        })
    }
  }
  const handleWheel = (event: any) => {
    event.target.blur()
  }
  const handleDiscountChange = (e: any) => {
    const value = e.target.value
    // Check if the user agent is Firefox or Safari
    const isFirefoxOrSafari = /Firefox|Safari/.test(navigator.userAgent)
    // If it's Firefox or Safari, handle the input differently
    if (isFirefoxOrSafari) {
      // Check if the value is empty or just a decimal point
      if (value === '' || value === '.') {
        setDiscount(value)
      } else {
        // Convert the value to a number and update the state
        setDiscount(parseFloat(value))
      }
    } else {
      // For other browsers, handle the input normally
      setDiscount(parseFloat(value))
    }
  }
  const handleTaxChange = (e: any) => {
    const value = e.target.value
    // Check if the user agent is Firefox or Safari
    const isFirefoxOrSafari = /Firefox|Safari/.test(navigator.userAgent)
    // If it's Firefox or Safari, handle the input differently
    if (isFirefoxOrSafari) {
      // Check if the value is empty or just a decimal point
      if (value === '' || value === '.') {
        setTax(value)
      } else {
        // Convert the value to a number and update the state
        setTax(parseFloat(value))
      }
    } else {
      // For other browsers, handle the input normally
      setTax(parseFloat(value))
    }
  }
  return loading ? (
    <Loading />
  ) : (
    <div className='createInvoice'>
      {addVendor && <AddVendor setAddVendor={setAddVendor} notify={notify} />}
      {addProduct && (
        <AddProduct notify={notify} setAddProduct={setAddProduct} />
      )}
      <TopBar />
      <div className='head-wrap'>
        <div className='head'>
          <div className='left'>
            <p>Paytton</p>
            <FaChevronRight />
            <h4>Create Invoice</h4>
          </div>

          {isLoading ? (
            <div className='loaders'>
              <img src={Loader} alt='' />
            </div>
          ) : (
            <div className='right'>
              <button onClick={() => handleSubmit()}>Send for approval</button>
            </div>
          )}
        </div>
      </div>
      <div className='create'>
        <div className='wrap'>
          <div className='wrap-head'>
            <div className='wrap-left'>
              <div className='card'>
                <p>Vendor</p>
                <input type='text' value={invoiceData.sellerName} disabled />
              </div>
            </div>
            <div className='wrap-right'>
              <div className='card'>
                <p>Invoice Number</p>
                <input
                  type='text'
                  value={invoiceData.number}
                  disabled
                />
              </div>
              <div className='card'>
                <p>Invoice Date</p>
                <input
                  type='date'
                  value={invoiceDate}
                  onChange={(e) => setInvoiceDate(e.target.value)}
                />
              </div>
              <div className='card'>
                <p>Due Date</p>
                <input
                  type='date'
                  value={dueDate}
                  onChange={(e) => setDueDate(e.target.value)}
                />
              </div>
              <div className='cards'>
                <p>Discount</p>
                <div className='flex'>
                  <select
                    value={discountType}
                    onChange={(e) => setDiscountType(e.target.value)}
                  >
                    <option value=''>-Select-</option>
                    <option value='percentage'>Percentage</option>
                    <option value='value'>Value</option>
                  </select>
                  <input
                    type='number'
                    step='0.01'
                    value={discount}
                    onWheel={handleWheel}
                    min={0}
                    onChange={handleDiscountChange}
                  />
                </div>
              </div>
              <div className='cards'>
                <p>Tax</p>
                <div className='flex'>
                  <select
                    value={taxType}
                    onChange={(e) => setTaxType(e.target.value)}
                  >
                    <option value=''>-Select-</option>
                    <option value='percentage'>Percentage</option>
                    <option value='value'>Value</option>
                  </select>
                  <input
                    type='number'
                    step='0.01'
                    value={tax}
                    onChange={handleTaxChange}
                    onWheel={handleWheel}
                    min={0}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='products'>
            <table>
              <thead>
                <tr>
                  <th className='name'>Product</th>
                  <th>Description</th>
                  <th className='qty'>Unit price</th>
                  <th className='qty'>Quantity</th>
                  <th className='qty'>Amount</th>
                </tr>
              </thead>
              <tbody>
                {selectedProductList.map((item: any, i: number) => (
                  <tr key={i + 1}>
                    <td className='name'>{item.product.name}</td>
                    <td>{item.product.description}</td>
                    <td className='qty'>
                      {item.product.price.toLocaleString()}
                    </td>
                    <td className='qty'>
                      <input
                        type='number'
                        value={item.quantity}
                        onWheel={handleWheel}
                        min={0}
                        onChange={(e) =>
                          editProductQuantity(
                            item.product._id,
                            parseInt(e.target.value)
                          )
                        }
                      />
                    </td>
                    <td className='qty'>
                      {(item.product.price * item.quantity).toLocaleString()}
                    </td>
                    <td className='hide'>
                      <RiDeleteBin6Line
                        onClick={() => handleRemoveProduct(item.product)}
                        className='decline'
                      />
                    </td>
                  </tr>
                ))}
                <tr>
                  <td className='name'>
                    <Select
                      className='basic-single'
                      classNamePrefix='select'
                      isLoading={productsLoading}
                      isClearable
                      isSearchable={true}
                      name='color'
                      options={productList}
                      noOptionsMessage={() =>
                        `Can't find the product? Add a product below.`
                      }
                      onChange={(value: any) => {
                        handleAddProduct(value)
                      }}
                      value={selectedProduct}
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td className='qty'></td>
                  <td></td>
                  <td className='hide'></td>
                </tr>
                <tr>
                  <td className='buttonx name'>
                    <button onClick={() => setAddProduct(true)}>
                      Create new product
                    </button>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='hide'></td>
                </tr>
              </tbody>
            </table>
            <div className='mobile'>
              {selectedProductList.map((item: any, i: number) => (
                <div className='card'>
                  <div className='row'>
                    <div className='one'>
                      <h3>Product</h3>
                    </div>
                    <div className='two'>
                      <p>{item.product.name}</p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='one'>
                      <h3>Description</h3>
                    </div>
                    <div className='two'>
                      <p>{item.product.description}</p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='one'>
                      <h3>Unit Price</h3>
                    </div>
                    <div className='two'>
                      <p>{item.product.price.toLocaleString()}</p>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='one'>
                      <h3>Quantity</h3>
                    </div>
                    <div className='two'>
                      <p>
                        <input
                          type='number'
                          value={item.quantity}
                          onWheel={handleWheel}
                          min={0}
                          onChange={(e) =>
                            editProductQuantity(
                              item.product._id,
                              parseInt(e.target.value)
                            )
                          }
                        />
                      </p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='one'>
                      <h3>Amount </h3>
                    </div>
                    <div className='two'>
                      <p>
                        {(item.product.price * item.quantity).toLocaleString()}
                      </p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='one'></div>
                    <div className='two'>
                      <RiDeleteBin6Line
                        onClick={() => handleRemoveProduct(item.product)}
                        className='decline'
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className='floex'>
              <div className='card'>
                <p>Select Product</p>
                <Select
                  className='basic-single'
                  classNamePrefix='select'
                  isLoading={productsLoading}
                  isClearable
                  isSearchable={true}
                  name='color'
                  options={productList}
                  noOptionsMessage={() =>
                    `Can't find the product? Add a product below.`
                  }
                  onChange={(value: any) => {
                    handleAddProduct(value)
                  }}
                  value={selectedProduct}
                />
                <div className='butt' onClick={() => setAddProduct(true)}>
                  <FaPlus className='add' />
                  <p>Create New Product</p>
                </div>
              </div>
            </div>
            <div className='totals'>
              <div className='curr'>
                <button>USD</button>
              </div>
              <div className='table-v'>
                <table className='mobileX'>
                  <tbody>
                    <tr>
                      <td className='lead'>SUBTOTAL</td>
                      <td>
                        <b>
                          {invoiceCurrency} {getTotalAmount().toLocaleString()}
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <td className='lead'>Discount</td>
                      <td>
                        {invoiceCurrency}
                        {discountType === 'percentage'
                          ? (
                              (discount / 100) *
                              getTotalAmount()
                            ).toLocaleString()
                          : discount.toLocaleString()}
                      </td>
                    </tr>
                    <tr>
                      <td className='lead'>Tax</td>
                      <td>
                        {invoiceCurrency}
                        {taxType === 'percentage'
                          ? ((tax / 100) * getTotalAmount()).toLocaleString()
                          : tax.toLocaleString()}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='tot'>
                <p>TOTAL AMOUNT</p>
                <h2>
                  {invoiceCurrency}
                  {finaleAmount.toLocaleString()}
                </h2>
              </div>
            </div>
            <div className='bankDet'>
              <h2>Bank details </h2>
              <p>
                Bank Name :<> {businessData.businessID.bankInfo[0].bankName}</>
              </p>
              <p>
                Account Name :{' '}
                <> {businessData.businessID.bankInfo[0].accountName}</>
              </p>
              <p>
                Account Number :{' '}
                <> {businessData.businessID.bankInfo[0].accountNumber}</>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='head-wrap'>
        <div className='head'>
          <div className='left'>
            {' '}
            <div className='card'>
              <p>Short Note</p>
              <input
                type='text'
                value={shortNote}
                onChange={(e) => setShortNote(e.target.value)}
              />
            </div>
          </div>
          {isLoading ? (
            <div className='loaders'>
              <img src={Loader} alt='' />
            </div>
          ) : (
            <div className='right'>
              <button onClick={() => handleSubmit()}>Send for approval</button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default EditInvoice
