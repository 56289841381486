import React, { useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { BsUpload } from 'react-icons/bs'
import Loader from '../../../../../Assests/loader.gif'
import axios from 'axios'
import { useGlobalContext } from '../../../../../Context/Context'

function InviteVendor({ setInviteVendor, notify }: any) {
  const authToken = sessionStorage.getItem('accessToken')
  const { businessData } = useGlobalContext()
  const [vendorName, setVendorName] = useState('')
  const [contactName, setContactName] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [contactPhone, setContactPhone] = useState('')
  const [goodsSupplied, setGoodsSupplied] = useState('')
  const [termsOfTrade, setTermsOfTrade] = useState('')
  const [selectedFile, setSelectedFile] = useState<any>(null)
  const [requestLoading, setRequestLoading] = useState(false)

  const handleSubmit = (e: any) => {
    e.preventDefault()

    const formData = new FormData()
    formData.append('vendorName', vendorName)
    formData.append('contactName', contactName)
    formData.append('contactEmail', contactEmail.toLocaleLowerCase())
    formData.append('contactPhone', contactPhone)
    formData.append('goodsSupplied', goodsSupplied)
    formData.append('termsOfTrade', termsOfTrade)
    formData.append('type', 'buyer_supplier')
    formData.append('contract', selectedFile)

    const formDataValues: { [key: string]: FormDataEntryValue } = {}

    Array.from(formData.entries()).forEach(([key, value]) => {
      formDataValues[key] = value
    })

    setRequestLoading(true)

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/business/${businessData.businessID._id}/connection/invite`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((response) => {
        notify('success', response.data.message)
        setRequestLoading(false)
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      })
      .catch((error) => {
        setRequestLoading(false)
      })
  }
  const handleFileChange = (e: any) => {
    setSelectedFile(e.target.files[0])
  }

  return (
    <div className='modal-body'>
      <div className='modal'>
        <div className='close'>
          <FaTimes onClick={() => setInviteVendor(false)} />
        </div>
        <div className='tag'>
          <p>Invite Vendor</p>
        </div>
        <div className='form'>
          <form onSubmit={handleSubmit}>
            <div className='card'>
              <p>Vendor Name</p>
              <input
                type='text'
                value={vendorName}
                onChange={(e) => setVendorName(e.target.value)}
                required
              />
            </div>
            <div className='card'>
              <p>Contact Name</p>
              <input
                type='text'
                value={contactName}
                onChange={(e) => setContactName(e.target.value)}
                required
              />
            </div>
            <div className='card'>
              <p>Contact Email</p>
              <input
                type='email'
                value={contactEmail}
                onChange={(e) => setContactEmail(e.target.value)}
                required
              />
            </div>
            <div className='card'>
              <p>Contact Phone</p>
              <input
                type='tel'
                value={contactPhone}
                onChange={(e) => setContactPhone(e.target.value)}
                required
              />
            </div>
            <div className='card'>
              <p>Goods Supplied</p>
              <input
                type='text'
                value={goodsSupplied}
                onChange={(e) => setGoodsSupplied(e.target.value)}
                required
              />
            </div>
            <div className='card'>
              <p>Terms of Trade</p>
              <select
                value={termsOfTrade}
                onChange={(e) => setTermsOfTrade(e.target.value)}
                required
              >
                <option value=''>-Select-</option>
                <option value='30 days'>30 days</option>
                <option value='60 days'>60 days</option>
                <option value='90 days'>90 days</option>
              </select>
            </div>
            <div className='cardX'>
              <div className='lef'>
                <h4>Contract Document</h4>
              </div>
              <div className='rig'>
                <input
                  type='file'
                  name='contract'
                  id='biz-reg'
                  accept='.pdf, .jpg, .jpeg, .png'
                  onChange={handleFileChange}
                />
                <label htmlFor='biz-reg'>
                  <div className='butz'>
                    <BsUpload />
                    <p>Upload</p>
                  </div>
                </label>
                {selectedFile && <p className='sel'>{selectedFile.name}</p>}
              </div>
            </div>
            {/* Submit button */}
            {requestLoading ? (
              <div className='buttons'>
                <img src={Loader} alt='Loader' />
              </div>
            ) : (
              <div className='buttons'>
                <button type='submit'>Done</button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default InviteVendor
