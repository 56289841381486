import React, { useState } from 'react'
import { GrClose } from 'react-icons/gr'
import Loader from '../../../../../Assests/loader.gif'
import axios from 'axios'
import { useGlobalContext } from '../../../../../Context/Context'
import Button from '../../../../../Components/Elements/Button/Button'

function AddProduct({ setAddProduct, notify, page, fetchProductList }: any) {
  const authToken = sessionStorage.getItem('accessToken')
  const { businessData } = useGlobalContext()
  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [price, setPrice] = useState('')
  const [currency, setCurrency] = useState('')

  const handleSubmit = (e: any) => {
    e.preventDefault()
    setIsLoading(true)
    const data = {
      name,
      description,
      price,
      currency,
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/product/${businessData.businessID._id}/create`,
        data,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((response) => {
        notify('success', response.data.message)
        setIsLoading(false)

        setTimeout(() => {
          if (page === 'invoice') {
            fetchProductList()
            setAddProduct(false)
          } else {
            window.location.reload()
          }
        }, 2000)
      })
      .catch((error) => {
        notify('error', error.message)
        setIsLoading(false)
      })
  }
  const handleWheel = (event: any) => {
    event.target.blur()
  }
  return (
    <div className='modal-body'>
      <div className='modal'>
        <div className='close'>
          <GrClose onClick={() => setAddProduct(false)} />
        </div>
        <div className='tag'>
          <h2>Add Product</h2>
          <p>Create a product</p>
        </div>
        <div className='form'>
          <form onSubmit={handleSubmit}>
            <div className='card dif'>
              <p>Product name</p>
              <input
                type='text'
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder='product name'
              />
            </div>
            <div className='card'>
              <p>Product Currency</p>
              <select
                name=''
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
              >
                <option value=''>Select</option>
                <option value='NGN'>NGN</option>
                <option value='USD'>USD</option>
              </select>
            </div>
            <div className='card'>
              <p>Product price</p>
              <input
                type='number'
                step='0.01'
                onWheel={handleWheel}
                min={0}
                required
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                placeholder='Amount '
              />
            </div>
            <div className='card dif'>
              <p>Product description</p>
              <textarea
                name=''
                id=''
                cols={30}
                rows={10}
                required
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                placeholder='product description '
              ></textarea>
            </div>

            {isLoading ? (
              <div className='buttons'>
                <img src={Loader} alt='Loader' />
              </div>
            ) : (
              <Button content='Add product' expanded />
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddProduct
