import React, { useState } from 'react'
import './Auth.scss'
import Loader from '../../Assests/loader.gif'
import Logo from '../../Assests/2.png'
import Image from '../../Assests/Group 3465459.png'
import { ReactComponent as Enter } from '../../Assests/Enter.svg'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import Navbar from '../Landing/components/Navbar'

type FormData = {
  email: string
}
interface Props {
  notify: (type: string, message: string) => void
}
function ForgetPass({ notify }: Props) {
  const [showPassword, setShowPassword] = useState(false)
  const navigate = useNavigate()
  const [formLoading, setFormLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>()
  const onSubmit = async (data: FormData) => {
    setFormLoading(true)
    const payLoad = {
      email: data.email.toLocaleLowerCase(),
    }
    try {
      const baseUrl = process.env.REACT_APP_API_URL // Get the base URL from the environment variable
      const response = await axios.post(
        `${baseUrl}/auth/password/reset/${payLoad.email}`
      )
      notify('success', response.data.message)
      setTimeout(() => {
        navigate(
          `/email-confirmation?search=${encodeURIComponent('forget-password')}`
        )
      }, 1000)
      setFormLoading(false)
    } catch (error: any) {
      setFormLoading(false)
      notify('error', error.response.data.message)
    }
  }

  return (
    <div className='auth'>
      <Navbar label={'auth'} />
      <div className='register'>
        <div className='right'>
          <img src={Image} alt='' />
        </div>
        <div className='left'>
          <div className='left-body'>
            <h2>Forgot Password</h2>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='card'>
              <p>Email</p>
              <input
                type='email'
                id=''
                {...register('email', { required: true })}
              />
              {errors.email && <span>This field is required</span>}
            </div>
            <div className='card'>
              {formLoading ? (
                <div className='loader'>
                  <img src={Loader} alt='' />
                </div>
              ) : (
                <button type='submit'>
                  <p>Reset</p>
                  <Enter />
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ForgetPass
