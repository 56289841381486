import React from 'react'
import { RxDashboard } from 'react-icons/rx'
import { HiOutlineUserGroup, HiOutlineClipboardList } from 'react-icons/hi'
import { TbWallet, TbFileInvoice } from 'react-icons/tb'
import { FaList } from 'react-icons/fa'
import { MdBusiness, MdStarBorder } from 'react-icons/md'
import { BiLogOut } from 'react-icons/bi'
import { BsHandbag, BsQuestionCircle } from 'react-icons/bs'
import { SlSettings } from 'react-icons/sl'
import { useNavigate } from 'react-router-dom'
import CheckPermissions from '../../../Components/Unauthorized/CheckPermissions'

function SideBar({ page, notify }: any) {
  const navigate = useNavigate()
  return (
    <div>
      {/* <div
        className={page === 'home' ? 'card active' : 'card'}
        onClick={() => navigate('/user/home')}
      >
        <div className='left'>
          <RxDashboard />
        </div>
        <div className='right'>
          <p>Home</p>
        </div>
      </div> */}
      <CheckPermissions
        requiredPermissions={['invite_vendors', 'view_vendors']}
      >
        <div
          className={
            page === 'vendors' || page === 'vendor' ? 'card active' : 'card'
          }
          onClick={() => navigate('/user/vendors')}
        >
          <div className='left'>
            <HiOutlineUserGroup />
          </div>
          <div className='right'>
            <p>Vendors</p>
          </div>
        </div>
      </CheckPermissions>
      {/* <div
        className={page === 'transactions' ? 'card active' : 'card'}
        onClick={() => navigate('/user/transactions')}
      >
        <div className='left'>
          <BsHandbag />
        </div>
        <div className='right'>
          <p>Transactions</p>
        </div>
      </div> */}
      <CheckPermissions
        requiredPermissions={['create_invoice', 'view_invoice']}
      >
        <div
          className={
            page === 'invoices' ||
            page === 'create-invoice' ||
            page === 'edit-invoice' ||
            page === 'invoice-details'
              ? 'card active'
              : 'card'
          }
          onClick={() => navigate('/user/invoices')}
        >
          <div className='left'>
            <TbFileInvoice />
          </div>
          <div className='right'>
            <p>Invoices</p>
          </div>
        </div>
        <div
          className={page === 'products' ? 'card active' : 'card'}
          onClick={() => navigate('/user/products')}
        >
          <div className='left'>
            <HiOutlineClipboardList />
          </div>
          <div className='right'>
            <p>Products</p>
          </div>
        </div>
      </CheckPermissions>

      {/* <div
        className={page === 'requests' ? 'card active' : 'card'}
        onClick={() => navigate('/user/requests')}
      >
        <div className='left'>
          <TbWallet />
        </div>
        <div className='right'>
          <p>Requests</p>
        </div>
      </div> */}
      {/* <div
        className={page === 'feedbacks' ? 'card active' : 'card'}
        onClick={() => navigate('/user/feedbacks')}
      >
        <div className='left'>
          <MdStarBorder />
        </div>
        <div className='right'>
          <p>Feedbacks</p>
        </div>
      </div> */}
      {/* <div
        className={page === 'help' ? 'card active' : 'card'}
        onClick={() => navigate('/user/help')}
      >
        <div className='left'>
          <BsQuestionCircle />
        </div>
        <div className='right'>
          <p>Help</p>
        </div>
      </div> */}
      <div
        className={page === 'settings' ? 'card active' : 'card'}
        onClick={() => navigate('/user/settings/profile')}
      >
        <div className='left'>
          <SlSettings />
        </div>
        <div className='right'>
          <p>Settings</p>
        </div>
      </div>
      <div
        className={page === 'buyers' ? 'card active' : 'card'}
        onClick={() => {
          notify('info', 'Logged Out')
          setTimeout(() => {
            sessionStorage.clear()
            navigate('/login')
          }, 1000)
        }}
      >
        <div className='left'>
          <BiLogOut />
        </div>
        <div className='right'>
          <p>Logout</p>
        </div>
      </div>
    </div>
  )
}

export default SideBar
