import React from 'react'
import './Admin.scss'
import { useParams } from 'react-router-dom'
import Dashboard from './AdminPages/Dashboard/Dashboard'
import DashboardLayout from '../../Layout/DashboardLayout/DashboardLayout'
import SidebarLayout from '../../Layout/SidebarLayout/SidebarLayout'
import { MainLayout } from '../../Layout/MainLayout/MainLayout'
import SideBar from './AdminComp/SideBar'
import BizReq from './AdminPages/BizReq/BizReq'
import BusinessPage from './AdminPages/BusinessPage/BusinessPage'
import BizUpdate from './AdminPages/Biz Update/BizUpdate'
import UpdatePage from './AdminPages/UpdatePage/UpdatePage'

function Admin({ notify }: any) {
  const { page } = useParams()

  const adminPage = () => {
    switch (page) {
      case 'home':
        return <Dashboard />
      case 'businesses':
        return <BizReq />
      case 'business-update':
        return <BizUpdate />
      case 'business':
        return <BusinessPage notify={notify} />
      case 'update-page':
        return <UpdatePage notify={notify} />
      case 'transactions':
        return <h1>Transactions</h1>
      case 'finance-request':
        return <h1>Finance Request</h1>
      default:
        return <h1>No Page Found</h1>
    }
  }
  return (
    <DashboardLayout>
      <SidebarLayout>
        <SideBar page={page} notify={notify} />
      </SidebarLayout>
      <MainLayout>{adminPage()}</MainLayout>
    </DashboardLayout>
  )
}

export default Admin
