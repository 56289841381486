import React from 'react'
import { FaRegFile } from 'react-icons/fa'
function Preview({
  addressFields,
  formState,
  utilityBillDocName,
  businessDocName,
  identificationDocName,
  licensesDocName,
  directoss,
  reps,
}: any) {
  return (
    <div className='form-body'>
      <div className='tag'>
        <p>Preview details</p>
      </div>
      <div className='cardY'>
        <div className='lowx'>
          <div className='ld'>
            <p>Company Name</p>
          </div>
          <div className='rd'>
            <h3>{formState.name}</h3>
          </div>
        </div>
        <div className='lowx'>
          <div className='ld'>
            <p>Company Email</p>
          </div>
          <div className='rd'>
            <h3>{formState.email}</h3>
          </div>
        </div>
        <div className='lowx'>
          <div className='ld'>
            <p>Telephone</p>
          </div>
          <div className='rd'>
            <h3>{formState.phone}</h3>
          </div>
        </div>
        <div className='lowx'>
          <div className='ld'>
            <p>Industry Type</p>
          </div>
          <div className='rd'>
            <h3>{formState.industryType}</h3>
          </div>
        </div>
        <div className='lowx'>
          <div className='ld'>
            <p>Company size</p>
          </div>
          <div className='rd '>
            <h3>{formState.companySize}</h3>
          </div>
        </div>
      </div>
      {addressFields.map((item: any, index: number) => (
        <div className='cardY' key={index + 1}>
          <div className='lowx'>
            <div className='ld'>
              <p>Address</p>
            </div>
            <div className='rd'>
              <h3>{item.address}</h3>
            </div>
          </div>
          <div className='lowx'>
            <div className='ld'>
              <p>Country</p>
            </div>
            <div className='rd'>
              <h3 className='country'>{item.country}</h3>
            </div>
          </div>
          <div className='lowx'>
            <div className='ld'>
              <p>State</p>
            </div>
            <div className='rd'>
              <h3>{item.state}</h3>
            </div>
          </div>
          <div className='lowx'>
            <div className='ld'>
              <p>Postal Code</p>
            </div>
            <div className='rd'>
              <h3>{item.postalCode}</h3>
            </div>
          </div>
          <div className='lowx'>
            <div className='ld'>
              <p>Address Type</p>
            </div>
            <div className='rd'>
              <h3>
                {item.addressType === 'registeredAddress'
                  ? 'Registered Address'
                  : ' Operating Address'}
              </h3>
            </div>
          </div>
        </div>
      ))}
      <div className='cardU'>
        <div className='lowx'>
          <div className='ld'>
            <FaRegFile />
          </div>
          <div className='rt'>
            <h3>{businessDocName}</h3>
          </div>
        </div>
        <div className='lowx'>
          <div className='ld'>
            <FaRegFile />
          </div>
          <div className='rt'>
            <h3>{utilityBillDocName}</h3>
          </div>
        </div>
        <div className='lowx'>
          <div className='ld'>
            <FaRegFile />
          </div>
          <div className='rt'>
            <h3>{identificationDocName}</h3>
          </div>
        </div>
        <div className='lowx'>
          <div className='ld'>
            <FaRegFile />
          </div>
          <div className='rt'>
            <h3>{licensesDocName}</h3>
          </div>
        </div>
      </div>
      {directoss.map((item: any, index: number) => (
        <div className='cardY' key={index + 1}>
          <div className='lowx'>
            <div className='ld'>
              <p>Director's Fullname</p>
            </div>
            <div className='rd'>
              <h3>{item.fullName}</h3>
            </div>
          </div>
          <div className='lowx'>
            <div className='ld'>
              <p>Director's NIN</p>
            </div>
            <div className='rd'>
              <h3>{item.NIN}</h3>
            </div>
          </div>
        </div>
      ))}
      {reps.map((item: any, index: number) => (
        <div className='cardY' key={index + 1}>
          <div className='lowx'>
            <div className='ld'>
              <p>Rep's FirstName</p>
            </div>
            <div className='rd'>
              <h3>{item.firstName}</h3>
            </div>
          </div>
          <div className='lowx'>
            <div className='ld'>
              <p>Rep's LastName</p>
            </div>
            <div className='rd'>
              <h3>{item.lastName}</h3>
            </div>
          </div>
          <div className='lowx'>
            <div className='ld'>
              <p>Rep's Email</p>
            </div>
            <div className='rd'>
              <h3>{item.email}</h3>
            </div>
          </div>
          <div className='lowx'>
            <div className='ld'>
              <p>Rep's Position</p>
            </div>
            <div className='rd'>
              <h3>{item.position}</h3>
            </div>
          </div>
        </div>
      ))}
      <div className='cardY'>
        <div className='lowx'>
          <div className='ld'>
            <p>Bank Name </p>
          </div>
          <div className='rd'>
            <h3>{formState.bankInfo.bankName}</h3>
          </div>
        </div>
        <div className='lowx'>
          <div className='ld'>
            <p>Account Name</p>
          </div>
          <div className='rd'>
            <h3>{formState.bankInfo.accountName} </h3>
          </div>
        </div>
        <div className='lowx'>
          <div className='ld'>
            <p>Account Number</p>
          </div>
          <div className='rd'>
            <h3>{formState.bankInfo.accountNumber}</h3>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Preview
