import { useState, useRef, useEffect } from 'react'
import { useGlobalContext } from '../../../../Context/Context'
import TopBar from '../../../../Components/TopBar/TopBar'
import { FaChevronRight } from 'react-icons/fa'
import './Settings.scss'
import SetiBar from './SetiComp/SetiBar'
import Department from './SetiComp/Department'
import General from './SetiComp/General'
import AddMembers from './SetiComp/AddMembers'
import AddRoles from './SetiComp/AddRoles'
import { useParams } from 'react-router-dom'
import Approval from './SetiComp/Approval'
import Kyb from './SetiComp/Kyb'
import Profile from './SetiComp/Profile'
import DeactivateUser from './SetiComp/DeactivateUser'
import Banner from '../../../../Assests/settingsframe.png'
import Button from '../../../../Components/Elements/Button/Button'
import ImageWithFallback from '../../../../Components/ImageDisplay/ImageDisplay'
import Loader from '../../../../Assests/loader.gif'

function Settings({ notify }: any) {
  const token = sessionStorage.getItem('accessToken')
  const { id } = useParams()
  const { businessData } = useGlobalContext()
  const targetRef = useRef<HTMLDivElement>(null)
  const [showSidebar, setShowSidebar] = useState(false)
  const [showAddMembers, setShowAddMembers] = useState<any>(null)
  const [showRoles, setshowRoles] = useState<any>(null)
  const [deactivateUser, setDeactivateUser] = useState(null)
  const [profilePicture, setProfilePicture] = useState<any>({
    string: '',
    file: null,
  })
  const [imgTru, setImgTru] = useState(false)
  const [imageLoading, setImageLoading] = useState(false)

  const SettingPage = () => {
    switch (id) {
      case 'general':
        return (
          <General
            notify={notify}
            setShowSidebar={setShowSidebar}
            showSidebar={showSidebar}
          />
        )
      case 'kyb':
        return (
          <Kyb
            setShowSidebar={setShowSidebar}
            showSidebar={showSidebar}
            notify={notify}
            businessData={businessData}
          />
        )
      case 'credit':
        return <h1>credit</h1>
      case 'approval':
        return (
          <Approval
            setShowSidebar={setShowSidebar}
            showSidebar={showSidebar}
            notify={notify}
            businessData={businessData}
          />
        )
      case 'notification':
        return <h1>notification</h1>
      case 'department':
        return (
          <Department
            setShowSidebar={setShowSidebar}
            showSidebar={showSidebar}
            notify={notify}
            businessData={businessData}
            setShowAddMembers={setShowAddMembers}
            setshowRoles={setshowRoles}
            setDeactivateUser={setDeactivateUser}
          />
        )
      case 'profile':
        return (
          <Profile
            setShowSidebar={setShowSidebar}
            showSidebar={showSidebar}
            notify={notify}
            businessData={businessData}
          />
        )
    }
  }
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        targetRef.current &&
        !targetRef.current.contains(event.target as Node)
      ) {
        setShowSidebar(false)
      }
    }
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])
  const addDP = () => {
    setImageLoading(true)
    const pictureData: any = {
      image: profilePicture.file,
    }
    const formData = new FormData()

    Object.keys(pictureData).forEach((key) => {
      formData.append(key, pictureData[key])
    })
    fetch(
      `${process.env.REACT_APP_API_URL}/business/${businessData.businessID._id}/update-logo`,
      {
        method: 'put',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
        redirect: 'follow',
      }
    )
      .then((res) => {
        if (res.status === 200) {
          notify(
            'success',
            'Logo update request submitted successfully and is under review.'
          )
          setImageLoading(false)

          res.json().then((data) => {
            window.location.reload()
          })
        } else {
          res.json().then((data) => {
            setImageLoading(false)
            notify('error', data.errors.join(','))
          })
        }
      })
      .catch((error) => {
        notify('error', 'An error occured please try again')
        setImageLoading(false)
      })
  }
  const onSelectProductImage = (e: any) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    if (file.size > 1000000) {
      notify('error', 'Image must be below 1mb')
    } else {
      reader.onloadend = () => {
        setImgTru(true)
        setProfilePicture({
          string: reader.result,
          file,
        })
      }
    }
  }
  function handleEditLogoClick() {
    // Trigger the click event on the file input
    const fileInput: any = document.getElementById('dp')
    fileInput.click()
  }
  return (
    <div className='settings'>
      {showAddMembers && (
        <AddMembers
          setShowAddMembers={setShowAddMembers}
          showAddMembers={showAddMembers}
          notify={notify}
        />
      )}
      {showRoles && (
        <AddRoles
          setshowRoles={setshowRoles}
          notify={notify}
          showRoles={showRoles}
        />
      )}
      {deactivateUser && (
        <DeactivateUser
          deactivateUser={deactivateUser}
          setDeactivateUser={setDeactivateUser}
          notify={notify}
        />
      )}
      <TopBar />
      <div className='head'>
        <div className='left'>
          <p>Paytton</p>
          <FaChevronRight />
          <h4>Settings</h4>
        </div>
      </div>
      <div className='settings-banner'>
        <div className='banner'>
          <img src={Banner} alt='' />
        </div>
        <div className='avatar'>
          <div className='left'>
            <div className='image'>
              <ImageWithFallback
                src={
                  imgTru
                    ? profilePicture.string
                    : businessData.businessID.profileImageURL
                }
                alt='Image Description'
              />
            </div>
            <input
              type='file'
              name=''
              id='dp'
              accept='image/*'
              onChange={onSelectProductImage}
            />
          </div>
          <div className='right'>
            {imgTru ? (
              <div className='save'>
                {imageLoading ? (
                  <div>
                    <img src={Loader} alt='' style={{ width: '50px' }} />
                  </div>
                ) : (
                  <div className='buttons'>
                    <Button
                      content='cancel'
                      danger
                      onClick={() => setImgTru(false)}
                    />
                    <Button content='Save' onClick={addDP} />
                  </div>
                )}
              </div>
            ) : (
              <Button content='Edit Logo' onClick={handleEditLogoClick} />
            )}
          </div>
        </div>
        <div className='label'>
          <h2>{businessData.businessID.name}</h2>
          <p>
            Joined {new Date(businessData.businessID.createdAt).toDateString()}
          </p>
        </div>
      </div>

      <div className='settings-body'>
        <SetiBar
          page={id}
          setShowSidebar={setShowSidebar}
          showSidebar={showSidebar}
          targetRef={targetRef}
        />

        <div className='seti-right'>
          <div className='settings-cover'>{SettingPage()}</div>
        </div>
      </div>
    </div>
  )
}

export default Settings
