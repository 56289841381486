import { useNavigate } from 'react-router-dom'
import Image from '../../../Assests/ManPic.png'
import Fade from 'react-reveal/Fade'

function Pricing({ setShowEarlyAccess }: any) {
  const navigate = useNavigate()
  return (
    <div className='pricing' id='pricing'>
      <div className='cover'>
        <Fade top duration={2000}>
          <div className='headd'>
            <h2>Flexible Pricing</h2>
            <p>Start small, scale as your need demands</p>
          </div>
        </Fade>
        <div className='flex'>
          <Fade left duration={2000}>
            <div className='left'>
              <h1>One tool for your source-to-pay operations</h1>
              <p>Free for teams to try.</p>
            </div>
          </Fade>
          <Fade right duration={2000}>
            <div className='right'>
              <img src={Image} alt='' />
            </div>
          </Fade>
        </div>
        <div className='button'>
          <button onClick={() => setShowEarlyAccess(true)}>
            Get early access
          </button>
        </div>
      </div>
    </div>
  )
}

export default Pricing
