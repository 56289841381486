import { useNavigate } from 'react-router-dom'
import Logo from '../../../Assests/5.png'
import Fade from 'react-reveal/Fade'

function Footer() {
  const navigate = useNavigate()
  return (
    <div className='footer'>
      <div className='cover'>
        <Fade top duration={2000}>
          <div className='left'>
            <img src={Logo} alt='' />
            <p>Supercharging your source-to-pay process</p>
          </div>
        </Fade>
        <div className='right'>
          <Fade left duration={2000}>
            <div className='card'>
              <h2>Features</h2>
              <ul>
                <a href='#vendors'>
                  <li>Vendor Management</li>
                </a>
                <a href='#invoicing'>
                  <li>Invoicing</li>
                </a>
                <a href='#payments'>
                  <li>Payments</li>
                </a>
                <a href='#vendors'>
                  <li>Approval workflows</li>
                </a>
              </ul>
            </div>
          </Fade>
          <Fade bottom duration={2000}>
            <div className='card ind'>
              <h2>Industries</h2>
              <ul>
                <li>FMCG</li>
                <li>Hospitality</li>
                <li>Manufacturing</li>
                <li>Services</li>
                <li>Trade</li>
              </ul>
            </div>
          </Fade>
          <Fade right duration={2000}>
            <div className='card'>
              <h2>Company</h2>
              <ul>
                {/* <li onClick={() => navigate('/early-access')}>Get Started</li> */}
                <li>Terms of Service</li>
                <li>Privacy policy</li>
                {/* <li onClick={() => navigate('/early-access')}>contact us</li>
                <a href='#payments'>
                  <li>About Us</li>
                </a> */}
              </ul>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default Footer
