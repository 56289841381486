const IndustryList = [
  'Information and Communications Technology',
  'Banking',
  'Healthcare',
  'Utility',
  'Consumer Goods',
  'Oil and Gas',
  'Entertainment',
  'Agriculture',
  'Construction',
  'Hospitality and Tourism',
]
export default IndustryList
