import React, { useState, useEffect, useRef } from 'react'
import './Vendor.scss'
import TopBar from '../../../../Components/TopBar/TopBar'
import Loader from '../../../../Assests/loader2.gif'
import {
  FaChevronLeft,
  FaFile,
  FaFileAlt,
  FaTimes,
  FaRegFileAlt,
  FaStar,
  FaCaretDown,
} from 'react-icons/fa'
import { TbShieldCheck } from 'react-icons/tb'
import { useNavigate, useParams } from 'react-router-dom'
import { useGlobalContext } from '../../../../Context/Context'
import axios from 'axios'
import Loading from '../../UserComp/Loading/Loading'
import EditVendor from './Component/EditVendor'
import CheckPermissions from '../../../../Components/Unauthorized/CheckPermissions'
import ImageWithFallback from '../../../../Components/ImageDisplay/ImageDisplay'

function Vendor({ notify }: any) {
  const authToken = sessionStorage.getItem('accessToken')
  const baseUrl = process.env.REACT_APP_API_URL
  const navigate = useNavigate()
  const [blackList, setBlackList] = useState(false)
  const [deleteVendor, setDeleteVendor] = useState(false)
  const [requestLoading, setRequestLoading] = useState(false)
  const [showDropdown, setshowDropdown] = useState<any>('false')
  const [vendorData, setVendorData] = useState<any>({})
  const { businessData } = useGlobalContext()
  const [loading, setLoading] = useState(true)
  const [editVendorx, setEditVendorx] = useState(false)
  const [blacklisted, setBlacklisted] = useState<any>(null)
  const [reason, setReason] = useState('')
  const { id } = useParams()
  const tabRef = useRef<HTMLDivElement>(null)

  const fetchVendorsList = async () => {
    try {
      const res = await axios.get(
        `${baseUrl}/business/${businessData.businessID._id}/connection/${id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      if (res.status === 200) {
        const data = res.data.data
        setLoading(false)
        setVendorData(data)
      }
    } catch (error) {}
  }
  useEffect(() => {
    fetchVendorsList()
  }, [])
  async function deleteVendorL() {
    setRequestLoading(true)
    const url = `${baseUrl}/business/${businessData.businessID._id}/connection/delete/${vendorData._id}`

    try {
      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      setRequestLoading(false)
      notify('success', 'Vendor deleted successfully:')
      setTimeout(() => {
        window.location.replace('/user/vendors')
      }, 1000)
    } catch (error: any) {
      notify('error', error.response.data.errors.join(','))
      setRequestLoading(false)
    }
  }
  const blacklistVendor = async () => {
    setRequestLoading(true)
    const url = `${process.env.REACT_APP_API_URL}/business/${businessData.businessID._id}/connection/blacklist/${vendorData._id}`
    try {
      const response = await axios.put(
        url,
        {
          blacklisted: blacklisted,
          blacklistedReason: reason,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      setRequestLoading(false)
      if (blacklisted) {
        notify('success', 'Vendor blacklisted successfully')
      } else {
        notify('success', 'Vendor removed from blacklist successfully')
      }
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    } catch (error: any) {
      notify('error', error.response.data.errors.join(','))
      setRequestLoading(false)
    }
  }
  const resendVendor = async () => {
    const url = `${process.env.REACT_APP_API_URL}/business/${businessData.businessID._id}/connection/resend-invite/${vendorData._id}`
    const data = null
    try {
      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      notify('success', 'Invite Reminde sent successfully')
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    } catch (error) {
      notify('error', error)
    }
  }
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (tabRef.current && !tabRef.current.contains(event.target as Node)) {
        setshowDropdown('false')
      }
    }

    if (!showDropdown) {
      document.addEventListener('mousedown', handleOutsideClick)
    } else {
      document.removeEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [showDropdown])
  return loading ? (
    <Loading />
  ) : (
    <div className='vendor'>
      {editVendorx && (
        <EditVendor
          vendorData={vendorData}
          setEditVendor={setEditVendorx}
          notify={notify}
        />
      )}
      {blackList && (
        <div className='modal-body'>
          <div className='modal'>
            <div className='close'>
              <FaTimes onClick={() => setBlackList(false)} />
            </div>
            <h1>
              Are you sure you want to{' '}
              {vendorData.blacklisted
                ? 'Remove vendor from Blacklist?'
                : 'Blacklist VENDOR?'}
            </h1>
            {!vendorData.blacklisted && (
              <div className='texta'>
                <textarea
                  name=''
                  id=''
                  cols={30}
                  rows={10}
                  onChange={(e) => setReason(e.target.value)}
                ></textarea>
              </div>
            )}
            {requestLoading ? (
              <div className='buttons'>
                <img src={Loader} />
              </div>
            ) : (
              <div className='buttons'>
                <button onClick={() => setBlackList(false)}>Cancel</button>
                <button onClick={() => blacklistVendor()}>
                  {vendorData.blacklisted ? 'Remove' : 'Blacklist'}
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      {deleteVendor && (
        <div className='modal-body'>
          <div className='modal'>
            <div className='close'>
              <FaTimes onClick={() => setDeleteVendor(false)} />
            </div>
            <h1>Are you sure you want to delete VENDOR?</h1>
            {requestLoading ? (
              <div className='buttons'>
                <img src={Loader} />
              </div>
            ) : (
              <div className='buttons'>
                <button onClick={() => setDeleteVendor(false)}>Cancel</button>
                <button
                  onClick={() => {
                    deleteVendorL()
                  }}
                  style={{ backgroundColor: 'darkred' }}
                >
                  delete
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      <TopBar />
      <div className='back' onClick={() => navigate(-1)}>
        <FaChevronLeft />
        <p>Back</p>
      </div>
      <div className='vendor-body'>
        <div className='head'>
          <div className='left'>
            <div className='one'>
              <ImageWithFallback
                src={vendorData.vendorID.profileImageURL}
                alt='Image Description'
              />
              {/* <img alt='' /> */}
            </div>
            <div className='two'>
              <div className='tag'>
                <h2>{vendorData.vendorName}</h2>
                <TbShieldCheck />
              </div>
              <p>
                {vendorData.vendorID !== null ? (
                  <>
                    {' '}
                    {vendorData.vendorID.addresses[0].address},{' '}
                    {vendorData.vendorID.addresses[0].state}{' '}
                    {vendorData.vendorID.addresses[0].country}
                  </>
                ) : (
                  'Vendor is yet to to register'
                )}
              </p>
              <p>
                {vendorData.vendorID !== null ? (
                  <>{vendorData.vendorID.email}</>
                ) : (
                  'Vendor is yet to to register'
                )}
              </p>
              <p>
                {vendorData.vendorID !== null ? (
                  <> {vendorData.vendorID.phone}</>
                ) : (
                  'Vendor is yet to to register'
                )}
              </p>
              {/* <ul>
                <li>LINKD</li>
                <li>FACEBOOK</li>
                <li>TWITTER</li>
              </ul> */}
            </div>
          </div>
          <div className='right'>
            {/* <p>Joined payyton in 2022</p> */}
            <p>
              RC number :{' '}
              {vendorData.vendorID !== null ? (
                <> {vendorData.vendorID.rcNumber}</>
              ) : (
                'Vendor is yet to to register'
              )}{' '}
            </p>
            <p>
              Industry type :{' '}
              {vendorData.vendorID !== null ? (
                <> {vendorData.vendorID.industryType}</>
              ) : (
                'Vendor is yet to to register'
              )}{' '}
            </p>
            <p>
              Company size :{' '}
              {vendorData.vendorID !== null ? (
                <> {vendorData.vendorID.companySize}</>
              ) : (
                'Vendor is yet to to register'
              )}{' '}
            </p>
            <p>
              Terms of Trade :{' '}
              {vendorData.vendorID !== null ? (
                <> {vendorData.termsOfTrade}</>
              ) : (
                'Vendor is yet to to register'
              )}{' '}
            </p>
            <div
              className={
                vendorData.blacklisted
                  ? 'active true'
                  : vendorData.active
                  ? 'active'
                  : 'active true'
              }
            >
              <p>Status:</p>
              <button>
                {vendorData.blacklisted
                  ? 'Inactive'
                  : vendorData.active
                  ? 'Active'
                  : 'Not registered'}
              </button>
            </div>
            {!vendorData.active && (
              <button className='resend' onClick={resendVendor}>
                Resend Invite
              </button>
            )}
          </div>
        </div>
        {vendorData.blacklisted && (
          <div className='blacklist'>
            <h1>Vendor has been Blacklisted</h1>
            <p>Reason: {vendorData.blacklistedReason}</p>
          </div>
        )}
        <div className='rep-det'>
          <div className='left'>
            <h2>Bank details </h2>
            <p>
              Bank Name :{' '}
              {vendorData.vendorID !== null ? (
                <> {vendorData.vendorID.bankInfo[0].bankName}</>
              ) : (
                'Vendor is yet to to register'
              )}{' '}
            </p>
            <p>
              Account Name :{' '}
              {vendorData.vendorID !== null ? (
                <> {vendorData.vendorID.bankInfo[0].accountName}</>
              ) : (
                'Vendor is yet to to register'
              )}{' '}
            </p>
            <p>
              Account Number :{' '}
              {vendorData.vendorID !== null ? (
                <> {vendorData.vendorID.bankInfo[0].accountNumber}</>
              ) : (
                'Vendor is yet to to register'
              )}{' '}
            </p>
          </div>
          <div className='right'>
            <h2>Rep’s details </h2>
            <p>Full Name : {vendorData.contactName} </p>
            <p>Phone : {vendorData.contactPhone}</p>
            <p>Email : {vendorData.contactEmail}</p>
            <p>Position : Admin</p>
          </div>
        </div>
        <div className='stats'>
          <div className='left'>
            <div className='trans'>
              <h2>#10 Billion </h2>
              <p>Worth of transaction</p>
            </div>
            <div className='rate'>
              <div className='star'>
                <h2>{vendorData.rating ? vendorData.rating : 0}</h2>
                <FaStar />
              </div>
              <p>0 reviews </p>
            </div>
          </div>
          <div className='right'>
            <div className='dropdown' ref={tabRef}>
              <div
                className='drop-head'
                onClick={() => setshowDropdown(!showDropdown)}
              >
                <p>Manage Vendor</p>
                <FaCaretDown />
              </div>
              <ul className={!showDropdown ? 'active' : ''}>
                <li
                  onClick={() => {
                    setEditVendorx(true)
                  }}
                >
                  Edit Vendor
                </li>
                {vendorData.vendorID !== null && (
                  <a href={`mailto:${vendorData.vendorID.email}`}>
                    <li>Email Vendor</li>
                  </a>
                )}
                <CheckPermissions requiredPermissions={['blacklist_vendor']}>
                  <li
                    onClick={() => {
                      setBlackList(true)
                      setshowDropdown(!showDropdown)
                      if (vendorData.blacklisted) {
                        setBlacklisted(false)
                      } else {
                        setBlacklisted(true)
                      }
                    }}
                  >
                    {vendorData.blacklisted
                      ? 'Remove Blacklist'
                      : 'Blacklist vendor'}
                  </li>
                </CheckPermissions>

                {/* <CheckPermissions requiredPermissions={['delete_vendor']}>
                  <li
                    onClick={() => {
                      setDeleteVendor(true)
                      setshowDropdown(!showDropdown)
                    }}
                  >
                    Delete Vendor
                  </li>
                </CheckPermissions> */}
              </ul>
            </div>
          </div>
        </div>
        {vendorData.contractDocs.map((item: any, index: number) => (
          <div className='contract' key={item}>
            <p>Contract Doc: </p>
            <FaRegFileAlt />
            <a href={`${item}`} target='_blank' rel='noreferrer'>
              <h2>Contract File ({index + 1})</h2>
            </a>
          </div>
        ))}
        {/* <div className='transaction-table'>
          <div className='table-top'>
            <h2>Transaction history</h2>
          </div>
          <div className='tablex'>
            <div className='table'>
              <div className='head'>
                <div className='row'>
                  <div className='td'>S/N</div>
                  <div className='td'> Invoice </div>
                  <div className='td'> Total invoice </div>
                  <div className='td'>Terms of trade</div>
                  <div className='td'>Total transcation amount</div>
                  <div className='td'>Outstanding invoice amount</div>
                  <div className='td'>Paid invoice value</div>
                </div>
              </div>
              <div className='bodyx'>
                {[...Array(5)].map((item: any, i: number) => (
                  <div
                    className='row'
                    key={i + 1}
                    onClick={() => navigate(`/user/vendor/${'Hey'}`)}
                  >
                    <div className='td'>1</div>
                    <div className='td'>
                      <a href='hry'>
                        <FaRegFileAlt /> <span>Invoice#01918</span>
                      </a>
                    </div>
                    <div className='td'> Lagos, Nigeria</div>
                    <div className='td'> Indomie, rice, beans,milk...</div>
                    <div className='td'>Net 30 days</div>
                    <div className='td'>Net 30 days</div>
                    <div className='td'>Net 30 days</div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className='mobile'>
            <>
              {[...Array(5)].map((item: any, i: number) => (
                <div
                  className='nodyx'
                  key={1 + i}
                  onClick={() => navigate(`/user/vendor/${'Hey'}`)}
                >
                  <div className='row'>
                    <div className='left'>
                      <h2>S/N</h2>
                    </div>
                    <div className='right'>
                      <p>1</p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='left'>
                      <h2>Invoice</h2>
                    </div>
                    <div className='right'>
                      <p>Lagos, Nigeria</p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='left'>
                      <h2>Total invoice</h2>
                    </div>
                    <div className='right'>
                      <p>Lagos, Nigeria</p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='left'>
                      <h2>Terms of trade</h2>
                    </div>
                    <div className='right'>
                      <p>Indomie, rice, beans,milk...</p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='left'>
                      <h2>Total transcation amount</h2>
                    </div>
                    <div className='right'>
                      <p>Net 30 days</p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='left'>
                      <h2>Outstanding invoice amount</h2>
                    </div>
                    <div className='right'>
                      <p>Net 30 days</p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='left'>
                      <h2>Paid invoice value</h2>
                    </div>
                    <div className='right'>
                      <p>Net 30 days</p>
                    </div>
                  </div>
                </div>
              ))}
            </>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default Vendor
