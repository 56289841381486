import React from 'react'
import { useNavigate } from 'react-router-dom'
import CheckPermissions from '../../../../../Components/Unauthorized/CheckPermissions'

function SetiBar({
  setPage,
  page,
  setShowSidebar,
  showSidebar,
  targetRef,
}: any) {
  const navigate = useNavigate()
  return (
    <div
      className={showSidebar ? 'seti-left active' : 'seti-left'}
      ref={targetRef}
    >
      <ul>
        <li
          className={page === 'profile' ? 'active' : ''}
          onClick={() => {
            navigate('/user/settings/profile')
            setShowSidebar(false)
          }}
        >
          <h2>Profile</h2>
        </li>
        <CheckPermissions requiredPermissions={['update_business']}>
          <li
            className={page === 'general' ? 'active' : ''}
            onClick={() => {
              navigate('/user/settings/general')
              setShowSidebar(false)
            }}
          >
            <h2>General</h2>
          </li>
          <li
            className={page === 'kyb' ? 'active' : ''}
            onClick={() => {
              navigate('/user/settings/kyb')
              setShowSidebar(false)
            }}
          >
            <h2>KYB</h2>
          </li>
          <li
            className={page === 'approval' ? 'active' : ''}
            onClick={() => {
              navigate('/user/settings/approval')
              setShowSidebar(false)
            }}
          >
            <h2>Approval set-up</h2>
          </li>
        </CheckPermissions>
        <CheckPermissions
          requiredPermissions={[
            'view_business_member',
            'update_business_member',
            'create_business_role',
            'update_business_role',
            'create_business_department',
            'update_business_department',
          ]}
        >
          <li
            className={page === 'department' ? 'active' : ''}
            onClick={() => {
              navigate('/user/settings/department')
              setShowSidebar(false)
            }}
          >
            <h2>Department & collaborators</h2>
          </li>
        </CheckPermissions>
        {/* <li
          className={page === 'credit' ? 'active' : ''}
          onClick={() => {
            navigate('/user/settings/credit')
            setShowSidebar(false)
          }}
        >
          <h2>Credit worthy setup</h2>
        </li>
        <li
          className={page === 'notification' ? 'active' : ''}
          onClick={() => {
            navigate('/user/settings/notification')
            setShowSidebar(false)
          }}
        >
          <h2>Notification</h2>
        </li> */}
      </ul>
    </div>
  )
}

export default SetiBar
