import Image from '../../../Assests/Light.png'
import Feat1 from '../../../Assests/Vendor Mgt.png'
import Feat2 from '../../../Assests/Invoicing.png'
import Feat3 from '../../../Assests/Payment.png'
import Fade from 'react-reveal/Fade'

function Features() {
  return (
    <div className='features' id='features'>
      <div className='cover'>
        <Fade bottom duration={2000}>
          <div className='head-tag'>
            <h1>Built with love for procurement and finance teams</h1>
            <p>....and those who care about them</p>
          </div>
          <div className='image-info'>
            <img src={Image} alt='' />
          </div>
        </Fade>
        <Fade bottom duration={2000}>
          <div className='head-tag'>
            <h1>Features that mirror your procurement workflow</h1>
            <p>....so you can have more fun doing what you love</p>
          </div>
        </Fade>
        <div className='feats-cover'>
          <div className='card reverse' id='vendors'>
            <Fade left duration={2000}>
              <div className='right'>
                <img src={Feat1} alt='' />
              </div>
            </Fade>
            <Fade right duration={2000}>
              <div className='left'>
                <h2>Vendor Management</h2>
                <ul>
                  <li>
                    Automated vendor onboarding: Save time and ensure thorough
                    vendor vetting with Paytton's automated onboarding process.
                  </li>
                  <li>
                    Vendor performance tracking: Analyze vendor relationships
                    and performance to identify top performers and streamline
                    underperforming partnerships.
                  </li>
                  <li>
                    Automated vendor payments and approvals: Efficiently process
                    payments and approvals with Paytton's automation, promoting
                    timely and accurate transactions.
                  </li>
                </ul>
                <a href='#invoicing'>
                  <button>Next {'>>'}</button>
                </a>
              </div>
            </Fade>
          </div>
          <div className='card' id='invoicing'>
            <Fade left duration={2000}>
              <div className='left'>
                <h2>Invoicing</h2>
                <ul>
                  <li>
                    Seamless invoicing: Receive and send invoices effortlessly,
                    boosting cash flow and invoice accuracy.
                  </li>
                  <li>
                    Invoice status and payment tracking: Stay informed on
                    invoice statuses and payments, ensuring timely collections.
                  </li>
                  <li>
                    Automated invoice approvals: Prevent errors and
                    discrepancies by efficiently processing and tracking invoice
                    approvals from relevant stakeholders.
                  </li>
                </ul>
                <a href='#payments'>
                  <button>Next {'>>'}</button>
                </a>
              </div>
            </Fade>
            <Fade right duration={2000}>
              <div className='right'>
                <img src={Feat2} alt='' />
              </div>
            </Fade>
          </div>
          <div className='card reverse' id='payments'>
            <Fade left duration={2000}>
              <div className='right'>
                <img src={Feat3} alt='' />
              </div>
            </Fade>
            <Fade right duration={2000}>
              <div className='left'>
                <h2>Payments</h2>
                <ul>
                  <li>
                    Timely and accurate payments: Strengthen vendor
                    relationships by paying accurately and on time, avoiding
                    late payment penalties.
                  </li>
                  <li>
                    Enhanced cash flow management: Track your cash flow and
                    manage your budget efficiently, making informed financial
                    decisions.
                  </li>
                  <li>
                    Late payment alerts: Receive prompt alerts for late
                    payments, enabling proactive actions for collection.
                  </li>
                </ul>
                <a href='#benefits'>
                  <button>Find out more</button>
                </a>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Features
