import React, { useState } from 'react'
import { FaTimes, FaTimesCircle } from 'react-icons/fa'
import { BsUpload } from 'react-icons/bs'
import { GrClose } from 'react-icons/gr'
import Loader from '../../../../../Assests/loader.gif'
import axios from 'axios'
import { useGlobalContext } from '../../../../../Context/Context'
import CreatableSelect from 'react-select/creatable'
import Button from '../../../../../Components/Elements/Button/Button'

function AddVendor({ setAddVendor, notify }: any) {
  const authToken = sessionStorage.getItem('accessToken')
  const { businessData } = useGlobalContext()
  const [type, setType] = useState('')
  const [contactName, setContactName] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [contactPhone, setContactPhone] = useState('')
  const [goodsSupplied, setGoodsSupplied] = useState('')
  const [termsOfTrade, setTermsOfTrade] = useState('')
  const [newTermsOfTrade, setNewTermsOfTrade] = useState('')
  const [selectedFile, setSelectedFile] = useState<any>([])
  const [requestLoading, setRequestLoading] = useState(false)
  const [searchList, setSearchList] = useState<any>([])
  const [selectedVendor, setSelectedVendor] = useState<any>({})
  const [searchNotFound, setSearchNotFound] = useState(true)
  const [vendorName, setVendorName] = useState('')

  const handleSubmit = (e: any) => {
    e.preventDefault()
    let terms = termsOfTrade
    if (termsOfTrade === 'others') {
      terms = newTermsOfTrade
    }
    const formData = new FormData()
    if (selectedVendor === null) {
      formData.append('vendorName', vendorName)
    } else if (Object.keys(selectedVendor).length === 0) {
      formData.append('vendorName', vendorName)
    } else {
      formData.append('vendorID', selectedVendor.value)
    }
    formData.append('contactName', contactName)
    formData.append('contactEmail', contactEmail.toLocaleLowerCase())
    formData.append('contactPhone', contactPhone)
    formData.append('goodsSupplied', goodsSupplied)
    formData.append('termsOfTrade', terms)
    formData.append('type', type)

    selectedFile.forEach((file: any) => {
      formData.append('contract', file)
    })

    const formDataValues: { [key: string]: FormDataEntryValue } = {}

    Array.from(formData.entries()).forEach(([key, value]) => {
      formDataValues[key] = value
    })

    setRequestLoading(true)

    if (selectedVendor === null) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/business/${businessData.businessID._id}/connection/invite`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((response) => {
          notify('success', 'Invite sent to Vendor successfully')
          setRequestLoading(false)
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        })
        .catch((error) => {
          setRequestLoading(false)
        })
    } else if (Object.keys(selectedVendor).length === 0) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/business/${businessData.businessID._id}/connection/invite`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((response) => {
          notify('success', 'Invite sent to Vendor successfully')
          setRequestLoading(false)
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        })
        .catch((error) => {
          setRequestLoading(false)
        })
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/business/${businessData.businessID._id}/connection/add`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((response) => {
          setRequestLoading(false)
          notify('success', 'Vendor added successfully')
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        })
        .catch((error) => {
          setRequestLoading(false)
        })
    }
  }
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files) {
      const newFiles: File[] = Array.from(files)
      setSelectedFile((prevSelectedFiles: any) => [
        ...prevSelectedFiles,
        ...newFiles,
      ])
    }
  }
  const handleFileDelete = (fileName: string) => {
    setSelectedFile((prevSelectedFiles: any) =>
      prevSelectedFiles.filter((file: any) => file.name !== fileName)
    )
  }
  const [isLoading, setIsLoading] = useState(false)

  function handleSearch(e: string) {
    const url = `${process.env.REACT_APP_API_URL}/business/search`
    const data = {
      name: e,
    }
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
    if (e.length >= 3) {
      setIsLoading(true)
      axios
        .post(url, data, config)
        .then((response) => {
          setIsLoading(false)
          if (response.data.data.length > 0) {
            setSearchNotFound(false)
          } else {
            setSearchNotFound(true)
          }
          const transformedOptions = response.data.data.map((option: any) => ({
            label: option.name,
            value: option._id,
            ...option,
          }))
          setSearchList(transformedOptions)
        })
        .catch((error) => {
          setIsLoading(false)
          // Handle the error as needed
        })
    }
  }

  const prefillVendor = (e: any) => {
    setSelectedVendor(e)
    if (e.repsDetails.length >= 1) {
      setContactName(
        `${e.repsDetails[0].firstName} ${e.repsDetails[0].lastName}`
      )
      setContactEmail(e.repsDetails[0].email)
    } else {
      setContactName('')
      setContactEmail('')
    }
  }
  const formatCreateLabel = (inputValue: string) => {
    return `Invite: ${inputValue}`
  }

  return (
    <div className='modal-body'>
      <div className='modal'>
        <div className='close'>
          <GrClose onClick={() => setAddVendor(false)} />
        </div>
        <div className='tag'>
          <h2>Add Vendor</h2>
          <p>
            Search for a vendor to add them, or invite them if they are not
            registered yet.
          </p>
        </div>
        <div className='form'>
          <form onSubmit={handleSubmit}>
            <div className='card'>
              <p>Vendor Name</p>
              <CreatableSelect
                isClearable
                isLoading={isLoading}
                isSearchable={true}
                options={searchList}
                required
                onChange={(value: any, action: any) => {
                  if (action.action === 'create-option') {
                    setVendorName(value.value)
                    setSelectedVendor(null)
                  } else if (action.action === 'select-option') {
                    prefillVendor(value)
                  }
                }}
                onInputChange={(e) => handleSearch(e)}
                formatCreateLabel={formatCreateLabel}
                placeholder='Search here'
              />
            </div>
            <div className='card'>
              <p>Vendor Type</p>
              <select
                value={type}
                onChange={(e) => setType(e.target.value)}
                required
              >
                <option value=''> Select here </option>
                <option value='Buyer'>Buyer</option>
                <option value='Supplier'>Supplier</option>
              </select>
            </div>
            <div className='card'>
              <p>Vendor Email</p>
              <input
                type='email'
                value={contactEmail}
                onChange={(e) => setContactEmail(e.target.value)}
                required
                placeholder='Vendor email'
              />
            </div>
            <div className='card'>
              <p>
                Good{' '}
                {type === 'Buyer'
                  ? 'Bought'
                  : type === 'Supplier'
                  ? 'Supplied'
                  : 'Type'}{' '}
              </p>
              <input
                type='text'
                value={goodsSupplied}
                onChange={(e) => setGoodsSupplied(e.target.value)}
                required
                placeholder='Good type'
              />
            </div>
            <div className='card'>
              <p>Contact Name</p>
              <input
                type='text'
                value={contactName}
                onChange={(e) => setContactName(e.target.value)}
                required
                placeholder='contact person name'
              />
            </div>

            <div className='card'>
              <p>Contact Phone</p>
              <input
                type='tel'
                value={contactPhone}
                onChange={(e) => setContactPhone(e.target.value)}
                placeholder='contact person number'
              />
            </div>
            <div className='cardX'>
              <div className='lef'>
                <h4>Contract Document</h4>
              </div>
              <div className='rig'>
                <input
                  type='file'
                  name='contract'
                  id='biz-reg'
                  accept='.pdf, .jpg, .jpeg, .png'
                  onChange={handleFileChange}
                  multiple
                />
                <label htmlFor='biz-reg'>
                  <div className='butz'>
                    <BsUpload />
                    <p>Upload here</p>
                  </div>
                </label>
              </div>
              {selectedFile && (
                <>
                  {selectedFile.map((file: any, index: number) => (
                    <div className='flow' key={index + 1}>
                      <p className='sel' key={file.name}>
                        {file.name.length > 32
                          ? `${file.name.slice(0, 29)}...`
                          : file.name}
                      </p>
                      <FaTimesCircle
                        onClick={() => handleFileDelete(file.name)}
                      />
                    </div>
                  ))}
                </>
              )}
            </div>
            <div className='card'>
              <p>Terms of Trade</p>
              <select
                value={termsOfTrade}
                onChange={(e) => setTermsOfTrade(e.target.value)}
                required
              >
                <option value=''>-Select-</option>
                <option value='30 days'>30 days</option>
                <option value='60 days'>60 days</option>
                <option value='90 days'>90 days</option>
                <option value='others'>others</option>
              </select>
            </div>
            {termsOfTrade === 'others' && (
              <div className='card'>
                <p>Type terms of trade</p>
                <input
                  type='text'
                  onChange={(e) => setNewTermsOfTrade(e.target.value)}
                  placeholder='input custom terms of trade'
                />
              </div>
            )}

            {/* Submit button */}
            {requestLoading ? (
              <div className='buttons'>
                <img src={Loader} alt='Loader' />
              </div>
            ) : (
              <Button content='Add vendor' expanded />
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddVendor
