import React from 'react'
import './Auth.scss'
import { ReactComponent as Mail } from '../../Assests/mail.svg'
import { useLocation, useNavigate } from 'react-router-dom'

function Email() {
  const navigate = useNavigate()
  const location: any = useLocation()
  const params = new URLSearchParams(location.search)
  const myString = params.get('search')

  return (
    <div className='email-page'>
      <div className='cover'>
        <div className='dv'>
          <div className='up'>
            <div className='image'>
              <Mail />
            </div>
            <div className='texts'>
              <h2>
                {myString === 'create-account'
                  ? 'Confirmation email sent.'
                  : 'Password rest link sent.'}
              </h2>
              <p>
                {myString === 'create-account'
                  ? 'Check your mail a confirmation mail has been sent to verify and activate your User Account.'
                  : 'Check your mail a password rest link has been sent to reset the password of your User Account.'}
              </p>
            </div>
          </div>
          {/* <div className='buttons'>
            <button
              onClick={() => {
                // window.location.href = 'mailto:'
                navigate('/final')
              }}
            >
              <p>Go to mail</p>
            </button>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Email
