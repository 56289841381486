import React from 'react'
import IdleTimer from '../../Components/Timer/Timer'
import { useParams } from 'react-router-dom'
import DashboardLayout from '../../Layout/DashboardLayout/DashboardLayout'
import SidebarLayout from '../../Layout/SidebarLayout/SidebarLayout'
import { MainLayout } from '../../Layout/MainLayout/MainLayout'
import SideBar from './UserComp/SideBar'
import Vendors from './UserPages/Vendors/Vendors'
import Vendor from './UserPages/Vendor/Vendor'
import { useGlobalContext } from '../../Context/Context'
import Loading from './UserComp/Loading/Loading'
import TopBar from '../../Components/TopBar/TopBar'
import Invoices from './UserPages/Invoices/Invoices'
import Products from './UserPages/Products/Products'
import InvoiceDetails from './UserPages/InvoiceDetails/InvoiceDetails'
import CreateInvoice from './UserPages/CreateInvoice/CreateInvoice'
import EditInvoice from './UserPages/CreateInvoice/EditInvoice'
import Settings from './UserPages/Settings/Settings'
function Dashboard({ notify }: any) {
  const { page } = useParams()
  const { businessLoading } = useGlobalContext()
  const userPage = () => {
    switch (page) {
      case 'home':
        return (
          <div className='home'>
            <TopBar />
            <h1>Home</h1>
          </div>
        )
      //Vendor
      case 'vendors':
        return <Vendors notify={notify} />
      case 'vendor':
        return <Vendor notify={notify} />
      //Invoices
      case 'invoices':
        return <Invoices notify={notify} />
      case 'create-invoice':
        return <CreateInvoice notify={notify} />
      case 'edit-invoice':
        return <EditInvoice notify={notify} />
      case 'invoice-details':
        return <InvoiceDetails notify={notify} />
      //products
      case 'products':
        return <Products notify={notify} />
      //Settings
      case 'settings':
        return <Settings notify={notify} />
      ///
      case 'transactions':
        return <h1>transactions</h1>
      case 'requests':
        return <h1>requests</h1>
      case 'feedbacks':
        return <h1>feedbacks</h1>
      case 'help':
        return <h1>help</h1>

      case 'transactions':
        return <h1>transactions</h1>

      default:
        return <h1>No Page Found</h1>
    }
  }
  return businessLoading ? (
    <Loading />
  ) : (
    <DashboardLayout>
      <SidebarLayout>
        <SideBar page={page} notify={notify} />
      </SidebarLayout>
      <MainLayout>{userPage()}</MainLayout>
    </DashboardLayout>
  )
}

export default Dashboard
