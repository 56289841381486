import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import Logo from '../../Assests/2.png'
import Loader from '../../Assests/loader2.gif'
import { BsPatchCheckFill } from 'react-icons/bs'
import { ImCancelCircle } from 'react-icons/im'

interface Props {
  notify: (type: string, message: string) => void
}
function Verificatuion({ notify }: Props) {
  const { email, token } = useParams()
  const [failed, setFailed] = useState('')
  const [loading, setLoading] = useState('loading')
  const navigate = useNavigate()
  const onSubmit = async () => {
    try {
      const baseUrl = process.env.REACT_APP_API_URL // Get the base URL from the environment variable
      const response = await axios.get(
        `${baseUrl}/auth/activation/${email}/${token}`
      )
      setLoading('success')
      setTimeout(() => {
        navigate('/login')
      }, 1000)
    } catch (error: any) {
      setLoading('fail')
      setFailed(error.response.data.errors.join(','))
      setTimeout(() => {
        navigate('/login')
      }, 4000)
    }
  }
  useEffect(() => {
    onSubmit()
  }, [])

  return (
    <div className='verification'>
      <div className='cover'>
        <div className='top'>
          <img src={Logo} alt='' />
        </div>
        <div className='rest'>
          {loading === 'loading' && (
            <div className='center'>
              <img src={Loader} alt='' />
              <h1>Activating..</h1>
            </div>
          )}
          {loading === 'success' && (
            <div className='center'>
              <BsPatchCheckFill className='suc' />
              <h1 className='suc'>Account Activated, Redirecting..</h1>
            </div>
          )}
          {loading === 'fail' && (
            <div className='center'>
              <ImCancelCircle className='fail' />
              <h1 className='suc'>{failed}, Redirecting..</h1>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Verificatuion
