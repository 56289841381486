import Image from '../../../Assests/customer.png'
import Fade from 'react-reveal/Fade'
function Calltoaction() {
  return (
    <div className='calltoaction'>
      <Fade bottom duration={2000}>
        <div className='cover'>
          <div className='left'>
            <img src={Image} alt='' />
          </div>
          <div className='right'>
            <h2>Are you ready to accelerate your business</h2>
            <a href='mailto:support@paytton.com'>
              <button>Contact us now</button>
            </a>
          </div>
        </div>
      </Fade>
    </div>
  )
}

export default Calltoaction
