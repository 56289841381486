import React, { useState, useRef, useEffect } from 'react'
import './Vendors.scss'
import TopBar from '../../../../Components/TopBar/TopBar'
import { FaChevronRight, FaSearch, FaCaretDown } from 'react-icons/fa'
import { CgOptions } from 'react-icons/cg'
import { useNavigate } from 'react-router-dom'
import Loader from '../../../../Assests/loader.gif'
import AddVendor from './Components/AddVendor'
import InviteVendor from './Components/InviteVendor'
import axios from 'axios'
import { useGlobalContext } from '../../../../Context/Context'
import Unauthorized from '../../../../Components/Unauthorized/Unauthorized'
import CheckPermissions from '../../../../Components/Unauthorized/CheckPermissions'
import Pagination from '../../../../Components/Pagination/Pagination'
import Button from '../../../../Components/Elements/Button/Button'

function Vendors({ notify }: any) {
  const authToken = sessionStorage.getItem('accessToken')
  const baseUrl = process.env.REACT_APP_API_URL
  const { businessData } = useGlobalContext()
  const navigate = useNavigate()
  const [requestLoading, setRequestLoading] = useState(false)
  const [inviteVendor, setInviteVendor] = useState(false)
  const [addVendor, setAddVendor] = useState(false)
  const [vendorList, setVendorList] = useState<any>({})
  const [vendorsLoading, setVendorsLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [filteredData, setFilteredData] = useState<any>([])
  const [allowed, setAllowed] = useState(false)
  const [defaultPagination, setDefaultPagination] = useState(true)
  const [selectedFilterType, setSelectedFilterType] = useState('')
  const fetchVendorsList = async (page: number) => {
    try {
      const res = await axios.get(
        `${baseUrl}/business/${businessData.businessID._id}/connection/fetch?limit=10&page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      if (res.status === 200) {
        setVendorList(res.data.data)
        setAllowed(true)
        setVendorsLoading(false)
      }
    } catch (err: any) {
      if (err.response.status === 401) {
        setVendorsLoading(false)
        setAllowed(false)
      }
    }
  }
  const fetchVendorsByType = async (page: number, type: string) => {
    try {
      const res = await axios.get(
        `${baseUrl}/business/${businessData.businessID._id}/connection/fetch?type=${type}&limit=10&page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      if (res.status === 200) {
        setVendorList(res.data.data)
        setAllowed(true)
        setVendorsLoading(false)
      }
    } catch (err: any) {
      if (err.response.status === 401) {
        setVendorsLoading(false)
        setAllowed(false)
      }
    }
  }
  const [showDropdown, setshowDropdown] = useState<any>('false')
  const tabRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    fetchVendorsList(1)
  }, [])
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (tabRef.current && !tabRef.current.contains(event.target as Node)) {
        setshowDropdown('false')
      }
    }

    if (!showDropdown) {
      document.addEventListener('mousedown', handleOutsideClick)
    } else {
      document.removeEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [showDropdown])
  const selectFilter = (e: string) => {
    setshowDropdown('false')
    setVendorsLoading(true)
    setDefaultPagination(false)
    setSelectedFilterType(e)
    fetchVendorsByType(1, e)
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value
    setSearchTerm(searchTerm)
    const filteredData = vendorList.docs.filter(
      (item: any) =>
        item.vendorName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.goodsSupplied.toLowerCase().includes(searchTerm.toLowerCase())
    )
    setFilteredData(filteredData)
  }
  const [currentPage, setCurrentPage] = useState(1)
  const paginationClick = (num: any) => {
    setVendorsLoading(true)
    if (defaultPagination) {
      fetchVendorsList(num)
    } else {
      fetchVendorsByType(num, selectedFilterType)
    }
  }

  return (
    <div className='vendors'>
      {inviteVendor && (
        <InviteVendor
          setInviteVendor={setInviteVendor}
          requestLoading={requestLoading}
          notify={notify}
        />
      )}
      {addVendor && (
        <AddVendor
          setAddVendor={setAddVendor}
          requestLoading={requestLoading}
          notify={notify}
        />
      )}
      <TopBar />
      <div className='head'>
        <div className='left'>
          <p>Paytton</p>
          <FaChevronRight />
          <h4>Vendors</h4>
        </div>
        <div className='right'>
          <CheckPermissions requiredPermissions={['invite_vendors']}>
            <Button
              content=' Add new vendor'
              onClick={() => {
                if (businessData.businessID.status === 'active') {
                  setAddVendor(true)
                } else {
                  notify('info', 'Business is yet to be verified')
                }
              }}
            ></Button>
          </CheckPermissions>
        </div>
      </div>
      <CheckPermissions
        requiredPermissions={['invite_vendors', 'view_vendors']}
      >
        <div className='vendor-head'>
          <div className='left'>
            <h2>Vendor List </h2>
          </div>
          <div className='right'>
            <div className='search'>
              <FaSearch />
              <input
                type='text'
                onChange={(e) => handleSearch(e)}
                placeholder='search name, Id, or product'
              />
            </div>
            <div className='dropdown'>
              <div
                className='drop-head'
                onClick={() => setshowDropdown(!showDropdown)}
              >
                {/* <p>
                  {selectedFilterType.length > 1
                    ? selectedFilterType
                    : 'Filter by type'}
                </p> */}
                <CgOptions />
              </div>
            </div>
            <div ref={tabRef}>
              <ul className={!showDropdown ? 'active' : ''}>
                <li onClick={() => selectFilter('buyer')}>Buyer</li>
                <li onClick={() => selectFilter('supplier')}>Supplier</li>
              </ul>
            </div>
          </div>
        </div>
      </CheckPermissions>

      {vendorsLoading ? (
        <div className='loader'>
          <img src={Loader} alt='' />
        </div>
      ) : (
        <>
          {allowed ? (
            <>
              <div className='tablex'>
                <table>
                  <thead>
                    <tr>
                      <th>S/N</th>
                      <th>Vendors</th>
                      <th>Type</th>
                      <th>Location</th>
                      <th>Goods supplied</th>
                      <th>Terms of trade</th>
                      <th>Ratings</th>
                      <th>Date added</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.length >= 1 ? (
                      <>
                        {filteredData.map((item: any, i: number) => (
                          <tr
                            onClick={() =>
                              navigate(`/user/vendor/${item.vendorID._id}`)
                            }
                            key={1 + i}
                          >
                            <td>{1 + i}</td>
                            <td>
                              {' '}
                              <p>
                                {item.vendorName.length > 13
                                  ? `${item.vendorName.slice(0, 13)}...`
                                  : item.vendorName}
                              </p>
                            </td>
                            <td>{item.type}</td>
                            <td>
                              {item.active ? (
                                <>
                                  {item.vendorID.addresses.length > 0 && (
                                    <>
                                      {item.vendorID.addresses[0].state},{' '}
                                      {item.vendorID.addresses[0].country}
                                    </>
                                  )}
                                </>
                              ) : (
                                'Vendor is yet to to register'
                              )}
                            </td>
                            <td>{item.goodsSupplied}</td>
                            <td>{item.termsOfTrade}</td>
                            <td>{item.rating ? item.rating : 0}</td>
                            <td> {new Date(item.updatedAt).toDateString()}</td>
                            <td>
                              {item.blacklisted
                                ? 'Blacklisted'
                                : item.active
                                ? 'Active'
                                : 'Not registered'}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <>
                        {vendorList.docs.map((item: any, i: number) => (
                          <tr
                            onClick={() =>
                              navigate(`/user/vendor/${item.vendorID._id}`)
                            }
                            key={1 + i}
                          >
                            <td>{1 + i}</td>
                            <td>
                              <p>
                                {item.vendorName.length > 13
                                  ? `${item.vendorName.slice(0, 13)}...`
                                  : item.vendorName}
                              </p>
                            </td>
                            <td>{item.type}</td>
                            <td>
                              {item.active ? (
                                <>
                                  {item.vendorID.addresses.length > 0 && (
                                    <>
                                      {item.vendorID.addresses[0].state},{' '}
                                      {item.vendorID.addresses[0].country}
                                    </>
                                  )}
                                </>
                              ) : (
                                'Vendor is yet to to register'
                              )}
                            </td>
                            <td>{item.goodsSupplied}</td>
                            <td>{item.termsOfTrade}</td>
                            <td>{item.rating ? item.rating : 0}</td>
                            <td> {new Date(item.updatedAt).toDateString()}</td>
                            <td>
                              {item.blacklisted
                                ? 'Blacklisted'
                                : item.active
                                ? 'Active'
                                : 'Not registered'}
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div className='mobile'>
                {filteredData.length >= 1 ? (
                  <>
                    {filteredData.map((item: any, i: number) => (
                      <div
                        className='nodyx'
                        key={1 + i}
                        onClick={() =>
                          navigate(`/user/vendor/${item.vendorID._id}`)
                        }
                      >
                        <div className='row'>
                          <div className='left'>
                            <h2>S/N</h2>
                          </div>
                          <div className='right'>
                            <p>{1 + i}</p>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='left'>
                            <h2>Vendors</h2>
                          </div>
                          <div className='right'>
                            <div className='ctd'>
                              <p>
                                {' '}
                                {item.vendorName.length > 13
                                  ? `${item.vendorName.slice(0, 13)}...`
                                  : item.vendorName}{' '}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='left'>
                            <h2>Type</h2>
                          </div>
                          <div className='right'>
                            <p>{item.type}</p>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='left'>
                            <h2>Location</h2>
                          </div>
                          <div className='right'>
                            <p>
                              {item.active ? (
                                <>
                                  {item.vendorID.addresses.length > 0 && (
                                    <>
                                      {item.vendorID.addresses[0].state},{' '}
                                      {item.vendorID.addresses[0].country}
                                    </>
                                  )}
                                </>
                              ) : (
                                'Vendor is yet to to register'
                              )}
                            </p>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='left'>
                            <h2>Goods supplied</h2>
                          </div>
                          <div className='right'>
                            <p>{item.goodsSupplied}</p>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='left'>
                            <h2>Terms of trade</h2>
                          </div>
                          <div className='right'>
                            <p>{item.termsOfTrade}</p>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='left'>
                            <h2>Date added</h2>
                          </div>
                          <div className='right'>
                            <p> {new Date(item.updatedAt).toDateString()}</p>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='left'>
                            <h2>Status</h2>
                          </div>
                          <div className='right'>
                            <p>
                              {' '}
                              {item.blacklisted
                                ? 'Blacklisted'
                                : item.active
                                ? 'Active'
                                : 'Not registered'}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {vendorList.docs.map((item: any, i: number) => (
                      <div
                        className='nodyx'
                        key={1 + i}
                        onClick={() =>
                          navigate(`/user/vendor/${item.vendorID._id}`)
                        }
                      >
                        <div className='row'>
                          <div className='left'>
                            <h2>S/N</h2>
                          </div>
                          <div className='right'>
                            <p>{1 + i}</p>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='left'>
                            <h2>Vendors</h2>
                          </div>
                          <div className='right'>
                            <div className='ctd'>
                              <p>
                                {' '}
                                {item.vendorName.length > 13
                                  ? `${item.vendorName.slice(0, 13)}...`
                                  : item.vendorName}{' '}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='left'>
                            <h2>Type</h2>
                          </div>
                          <div className='right'>
                            <p>{item.type}</p>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='left'>
                            <h2>Location</h2>
                          </div>
                          <div className='right'>
                            <p>
                              {item.active ? (
                                <>
                                  {item.vendorID.addresses.length > 0 && (
                                    <>
                                      {item.vendorID.addresses[0].state},{' '}
                                      {item.vendorID.addresses[0].country}
                                    </>
                                  )}
                                </>
                              ) : (
                                'Vendor is yet to to register'
                              )}
                            </p>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='left'>
                            <h2>Goods supplied</h2>
                          </div>
                          <div className='right'>
                            <p>{item.goodsSupplied}</p>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='left'>
                            <h2>Terms of trade</h2>
                          </div>
                          <div className='right'>
                            <p>{item.termsOfTrade}</p>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='left'>
                            <h2>Date added</h2>
                          </div>
                          <div className='right'>
                            <p> {new Date(item.updatedAt).toDateString()}</p>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='left'>
                            <h2>Status</h2>
                          </div>
                          <div className='right'>
                            <p>
                              {' '}
                              {item.blacklisted
                                ? 'Blacklisted'
                                : item.active
                                ? 'Active'
                                : 'Not registered'}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
                <></>
              </div>
              <Pagination
                currentPage={currentPage}
                totalCount={vendorList.totalPages}
                pageSize={10}
                onPageChange={(page: any) => {
                  setCurrentPage(page)
                  paginationClick(page)
                }}
              />
            </>
          ) : (
            <Unauthorized />
          )}
        </>
      )}
    </div>
  )
}

export default Vendors
