import React from 'react'
import './DashboardLayout.scss'
import IdleTimer from '../../Components/Timer/Timer'

const DashboardLayout = ({ children }: any) => {
  return (
    <div className='dashboardLayout'>
      {/* <IdleTimer /> */}
      <>{children}</>
    </div>
  )
}

export default DashboardLayout
