import React from 'react'
import IndustryList from '../../../Data/Industries'

function ContactComp({ setFormState, formState, emptyFields, disabled }: any) {
  return (
    <div className='form-body seti'>
      <div className='tag'>
        <p>Contact Info</p>
      </div>
      <div className='card'>
        <p>Company Name</p>
        <input
          type='text'
          disabled={disabled}
          name=''
          id=''
          className={
            emptyFields.includes('Name') && formState.name === ''
              ? 'active'
              : ''
          }
          onChange={(e) => {
            const inputValue = e.target.value
            // Use a regular expression to check if the input contains only numbers and the plus sign (+)
            if (/^[a-zA-Z]*$/.test(inputValue)) {
              // If the input is valid, update the phone field in the form state
              setFormState((prevState: any) => ({
                ...prevState,
                name: inputValue,
              }))
            }
          }}
          value={formState.name}
        />
      </div>
      <div className='card'>
        <p>Company Email</p>
        <input
          type='email'
          name=''
          id=''
          className={
            emptyFields.includes('Email') && formState.email === ''
              ? 'active'
              : ''
          }
          disabled={disabled}
          onChange={(e) =>
            setFormState((prevState: any) => ({
              ...prevState,
              email: e.target.value,
            }))
          }
          value={formState.email}
        />
      </div>
      <div className='card'>
        <p>Telephone</p>
        <input
          type='tel'
          disabled={disabled}
          name=''
          id=''
          className={
            emptyFields.includes('Phone') && formState.phone === ''
              ? 'active'
              : ''
          }
          onChange={(e) => {
            const inputValue = e.target.value
            // Use a regular expression to check if the input contains only numbers and the plus sign (+)
            if (/^[0-9+]*$/.test(inputValue)) {
              // If the input is valid, update the phone field in the form state
              setFormState((prevState: any) => ({
                ...prevState,
                phone: inputValue,
              }))
            }
          }}
          value={formState.phone}
        />
      </div>
      <div className='card'>
        <p>Rc Number</p>
        <input
          type='text'
          disabled={disabled}
          name=''
          id=''
          className={
            emptyFields.includes('RC Number') && formState.rcNumber === ''
              ? 'active'
              : ''
          }
          onChange={(e) =>
            setFormState((prevState: any) => ({
              ...prevState,
              rcNumber: e.target.value,
            }))
          }
          value={formState.rcNumber}
        />
      </div>
      <div className='card'>
        <p>Industry Type</p>
        <select
          name=''
          disabled={disabled}
          id=''
          className={
            emptyFields.includes('Industry Type') &&
            formState.industryType === ''
              ? 'active'
              : ''
          }
          onChange={(e) =>
            setFormState((prevState: any) => ({
              ...prevState,
              industryType: e.target.value,
            }))
          }
          value={formState.industryType}
        >
          <option value=''>-Select-</option>
          {IndustryList.map((item) => (
            <option value={item} key={item}>
              {item}
            </option>
          ))}
        </select>
      </div>
      <div className='card'>
        <p>Company Size</p>
        <select
          name=''
          disabled={disabled}
          id=''
          className={
            emptyFields.includes('Company Size') && formState.companySize === ''
              ? 'active'
              : ''
          }
          onChange={(e) =>
            setFormState((prevState: any) => ({
              ...prevState,
              companySize: e.target.value,
            }))
          }
          value={formState.companySize}
        >
          <option value=''>-Select-</option>
          <option value={'1-100'}>1-100</option>
          <option value={'101 - 200'}>101 - 200</option>
          <option value={'201 - 500'}>201 - 500</option>
          <option value={' 501 - 1000'}> 501 - 1000</option>
          <option value={'> 1000'}>{'>'} 1000</option>
        </select>
      </div>
    </div>
  )
}

export default ContactComp
