import { useState } from 'react'
import './Landing.scss'
import Hero from './components/Hero'
import About from './components/About'
import Features from './components/Features'
import Benefits from './components/Benefits'
import Pricing from './components/Pricing'
import Faqs from './components/Faqs'
import Calltoaction from './components/Calltoaction'
import Footer from './components/Footer'
import EarlyAccess from './components/EarlyAccess'

function Landing({ notify }: any) {
  const [showEarlyAccess, setShowEarlyAccess] = useState(false)
  return (
    <div className='landing'>
      {showEarlyAccess && (
        <EarlyAccess notify={notify} setShowEarlyAccess={setShowEarlyAccess} />
      )}
      <Hero setShowEarlyAccess={setShowEarlyAccess} />
      <About />
      <Features />
      <Benefits setShowEarlyAccess={setShowEarlyAccess} />
      <Pricing setShowEarlyAccess={setShowEarlyAccess} />
      <Faqs />
      <Calltoaction />
      <Footer />
    </div>
  )
}

export default Landing
